import Sheet, { CellContent, CellContentItem } from 'sheet-happens'
import { useState } from 'react'
const SheetCustomInput = (props) => {
  const { data, setData, columnData, headerStyle } = props
  const {
    onCellWidthChange,
    onCellHeightChange,
    cellWidth,
    cellHeight,
  } = useWidthHeigthControl()

  const onSelectionChanged = (x1, y1, x2, y2) => {}
  const onRightClick = () => {}

  const cellStyle = (x, y) => {
    return {}
  }
  const editData = (x, y) => {
    return data?.[y]?.[x]
  }
  const displayData = (x, y) => {
    return data?.[y]?.[x]
  }
  const sourceData = (x, y) => {
    return data?.[y]?.[x]
  }

  const onChange = (changes) => {
    const newData = [...data]
    for (const change of changes) {
      if (!newData[change.y]) {
        newData[change.y] = []
      }
      newData[change.y][change.x] = change.value
    }
    setData(newData)
  }

  const isReadOnly = (x, y) => {
    return false
  }
  return (
    <Sheet
      columnHeaderStyle={headerStyle}
      onSelectionChanged={onSelectionChanged}
      onRightClick={onRightClick}
      columnHeaders={
        columnData ? columnData.map((col) => col.label) : ['A', 'B', 'C']
      }
      cellStyle={cellStyle}
      editData={editData}
      displayData={displayData}
      sourceData={sourceData}
      cellWidth={cellWidth}
      cellHeight={cellHeight}
      onChange={onChange}
      readOnly={isReadOnly}
      onCellWidthChange={onCellWidthChange}
      onCellHeightChange={onCellHeightChange}
      // inputComponent={CustomInput}
      // sheetStyle={{ freezeRows: 2 }}
    />
  )
}

function CustomInput(x, y, inputProps, commitEditingCell, asd) {
  const clickHandler = (e) => {
    const { value } = e.target
    inputProps.onChange(value)
  }

  return (
    <input
      {...inputProps}
      onChange={clickHandler}
      onFocus={(event) => event.target.select()}
    />
  )
  return (
    <div style={{ ...inputProps.style, backgroundColor: '#fff' }}>
      <div style={{ border: '1px solid #ddd', boxShadow: '3px 5px 5px #ddd' }}>
        {(x === 1
          ? ['First', 'Second', 'Third', 'Fourth', 'Fifth']
          : ['First', 'Second', 'Third', 'Fourth', 'Fifth']
        ).map((opt) => {
          return (
            <CustomOption
              key={opt}
              opt={opt}
              value={inputProps.value}
              clickHandler={() => clickHandler(opt)}
            />
          )
        })}
      </div>
    </div>
  )
}

function CustomOption({ opt, value, clickHandler }) {
  const [hover, setHover] = useState(false)

  return (
    <div
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      style={{
        backgroundColor: value === opt || hover ? '#f6f6f6' : '#fff',
        padding: '5px 10px',
        cursor: 'pointer',
      }}
      value={opt}
      onClick={() => clickHandler(opt)}
    >
      {opt}
    </div>
  )
}

function useWidthHeigthControl(initialWidths = [], initialHeights = []) {
  const [cellWidth, setCellWidth] = useState(initialWidths)
  const [cellHeight, setCellHeight] = useState(initialHeights)

  const onCellWidthChange = (indices, newWidth) => {
    const cw = [...cellWidth]
    for (const idx of indices) {
      if (idx > cw.length) {
        for (let i = cw.length; i <= idx; i++) {
          cw.push(200)
        }
      }
      cw[idx] = newWidth
    }
    setCellWidth(cw)
  }

  const onCellHeightChange = (indices, newHeight) => {
    const ch = [...cellHeight]
    for (const idx of indices) {
      if (idx > ch.length) {
        for (let i = ch.length; i <= idx; i++) {
          ch.push(50)
        }
      }
      ch[idx] = newHeight
    }
    setCellHeight(ch)
  }

  return { onCellWidthChange, onCellHeightChange, cellWidth, cellHeight }
}

export default SheetCustomInput
