import { useState } from "react";
import Select from "react-select";
import { ChartContainer } from "../../components/Chart";

const Management = () => {
  const [year, setYear] = useState("2023");
  const [chartType, setChartType] = useState("Clientes");
  const graficas = [
    { value: "Clientes", label: "Clientes" },
    { value: "Liquidadores", label: "Liquidadores" },
    { value: "Gestores", label: "Gestores" },
    { value: "Comisiones", label: "Comisiones" },
    { value: "Problemas Del Despacho", label: "Problemas Del Despacho" },
    { value: "Observaciones", label: "Observaciones" },
    { value: "Canales", label: "Canales" },
    { value: "Aduanas De Despachos", label: "Aduanas De Despachos" },
    { value: "Examen Previo", label: "Examen Previo" },
    { value: "Multas", label: "Multas" },
    { value: "Reintegros", label: "Reintegros" },
    { value: "Multa Fiscalización", label: "Multa Fiscalización" },
    { value: "Reintegro Fiscalización", label: "Reintegro Fiscalización" },
    {
      value: "Gestoria Lista De Observacionesla",
      label: "Gestoria Lista De Observaciones",
    },
    {
      value: "Contabilidad Lista De Observaciones",
      label: "Contabilidad Lista De Observaciones",
    },
  ];
  const gestiones = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ];

  const handleChartTypeChange = (selectedOption) => {
    setChartType(selectedOption.value);
  };

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption.value);
  };
  return (
    <div>
      <div className="flex gap-4 items-center mb-2">
        <h3>Gráfica de gerencia: </h3>
        <Select
          options={graficas}
          value={graficas.find((option) => option.value === chartType)}
          onChange={handleChartTypeChange}
        />        
        <h3>Gestión: {year}</h3>
        <Select 
        options={gestiones} 
        value={gestiones.find(option=>option.value===year)}
        onChange={handleYearChange}
        />        
      </div>
      {
        {
          Clientes: <></>,
          Liquidadores: (
            <ChartContainer
              title="Cantidad de tramites por liquidador"
              urlApi={`/Procedure/field/designacionDeLiquidador/year/${year}/chart`}
              filters={[
                {
                  name: "status",
                  default: "Todos los trámites",
                  options: [
                    {
                      label: "Concluidos",
                      name: "finished",
                    },
                    {
                      label: "Pendientes",
                      name: "pending",
                    },
                  ],
                },
                {
                  name: "companyId",
                  url: "/company/getallcompanies",
                  default: "Todas las compañías",
                  valueOption: "id",
                  labelOption: "razonSocial",
                },
                {
                  type: "rangeDate",
                },
              ]}
            />
          ),
          Gestores: <></>,
          Comisiones: <></>,
          "Problemas Del Despacho": <></>,
          Observaciones: <></>,
          Canales: <></>,
          "Aduanas De Despachos": <></>,
          "Examen Previo": <></>,
          Multas: <></>,
          Reintegros: <></>,
          "Multa Fiscalización": <></>,
          "Reintegro Fiscalización": <></>,
          "Gestoria Lista De Observaciones": <></>,
          "Contabilidad Lista De Observaciones": <></>,
        }[chartType]
      }
    </div>
  );
};

export default Management;
