import { Request } from '../../hooks/Request'
import { Circle } from '../../components/Animation'
import View from '../../components/GeneratePdf/View'
import Text from '../../components/GeneratePdf/Text'
import { Page, Document, PDFViewer } from '@react-pdf/renderer'

const RenderProforma = (props) => {
  const { procedure } = props
  const { data, loading } = Request({
    urlApi: `/procedure/${procedure.id || procedure.procedureId}/proform`,
  })

  if (!loading) {
    return <Circle />
  }
  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: '20px' }}>
        <View>
          <View flexDirection="column" padding="0 0 0 80px" width="75%">
            <Text bold fontSize="16px" textAlign="center">
              BRUSECO S.R.L.
            </Text>
            <Text fontSize="10px" textAlign="center">
              AGENCIA DESPACHANTE DE ADUANA
            </Text>
            <Text fontSize="10px" textAlign="center">
              Cochabamba - Bolivia
            </Text>
            <Text
              bold
              fontSize="12px"
              style={{ textDecoration: 'underline' }}
              textAlign="center"
            >
              PLANILLA DE GASTOS
            </Text>
          </View>
          <View flexDirection="column" width="25%">
            <Text fontSize="16px" textAlign="center">
              TRAMITE:
            </Text>
          </View>
        </View>
        <View alignItems="center">
          <View width="25%" flexDirection="column" alignItems="flex-end">
            <Text bold fontSize="10px">
              Nombre del Importador:
            </Text>
            <Text bold fontSize="10px">
              NIT o CI:
            </Text>
            <Text bold fontSize="10px">
              DUI:
            </Text>
            <Text bold fontSize="10px">
              Descripción:
            </Text>
            <Text bold fontSize="10px">
              Cod Bruseco:
            </Text>
          </View>
          <View width="75%" flexDirection="column">
            <Text fontSize="10px">--</Text>
            <Text fontSize="10px">--</Text>
            <Text fontSize="10px">--</Text>
            <Text fontSize="10px">--</Text>
            <View>
              <Text minWidth="230px">--</Text>
              <Text bold fontSize="10px">
                Aduana:
              </Text>
              <Text>--</Text>
            </View>
          </View>
        </View>

        <View>
          <View
            border={{ top: true, right: true, left: true }}
            flexDirection="column"
          >
            <View alignItems="center" border={{ bottom: true }}>
              <View width="75%" flexDirection="column" alignItems="flex-end">
                <Text
                  bold
                  fontSize="12px"
                  style={{ textDecoration: 'underline' }}
                >
                  GASTOS EFECTUADOS
                </Text>
              </View>
              <View width="25%" flexDirection="column"></View>
            </View>
            <View border={{ bottom: true }}>
              <View
                width="25%"
                flexDirection="column"
                alignItems="flex-end"
                border={{ right: true }}
              >
                <View width="100%" flexDirection="column" alignItems="center">
                  <Text
                    bold
                    textAlign="center"
                    style={{ textDecoration: 'underline' }}
                  >
                    DESCRIPCIÓN
                  </Text>
                </View>
                <Text>Gravamen Arancelario</Text>
                <Text>Impuesto al Valor Agregado</Text>
                <Text>Impuesto al Consumo Especifico</Text>
                <Text>Almacenaje</Text>
                <Text>Camara de comercio</Text>
                <Text>Carpeta de documentos</Text>
                <Text>Certificado de Aire Acondicionado</Text>
                <Text>Certificado de Conversión a GNV</Text>
                <Text>Certificación de Emisión de Gases</Text>
                <Text>Certificado de Ibnorca</Text>
                <Text>Formulario 187</Text>
                <Text>Fletes</Text>
                <Text>Gastos de Despacho</Text>
                <Text>Levante de Abandono</Text>
                <Text>Gastos Puerto</Text>
                <Text>Impuesto Global Sidunea</Text>
                <Text>Inspección Técnica</Text>
                <Text>Memoriales</Text>
                <Text>Otros gastos</Text>
                <Text>Seguro</Text>
                <Text>Senasag</Text>
                <Text>Frontera S.A.</Text>
                <Text>Otros</Text>
                <Text>Otros</Text>
              </View>
              <View
                width="25%"
                flexDirection="column"
                alignItems="center"
                border={{ right: true }}
              >
                <Text
                  bold
                  textAlign="center"
                  style={{ textDecoration: 'underline' }}
                >
                  FACTURA
                </Text>
              </View>
              <View
                width="25%"
                flexDirection="column"
                alignItems="center"
                border={{ right: true }}
              >
                <Text
                  bold
                  textAlign="center"
                  style={{ textDecoration: 'underline' }}
                >
                  MONEDA
                </Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
                <Text>Bs.</Text>
              </View>
              <View width="25%" flexDirection="column" alignItems="center">
                <Text
                  bold
                  textAlign="center"
                  style={{ textDecoration: 'underline' }}
                >
                  IMPORTE
                </Text>
              </View>
            </View>
          </View>
          <View
            borderContent
            flexDirection="column"
            alignItems="center"
            style={{
              marginLeft: '10px',
              backgroundColor: '#1a1a1a',
              gap: '0px',
            }}
          >
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              S
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              I
            </Text>
            <Text
              bold
              fontSize="10px"
              color="white"
              padding="2px 3px"
              style={{ marginBottom: '10px' }}
            >
              N
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              D
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              E
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              R
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              E
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              C
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              H
            </Text>
            <Text
              bold
              fontSize="10px"
              color="white"
              padding="2px 3px"
              style={{ marginBottom: '10px' }}
            >
              O
            </Text>
            <Text
              bold
              fontSize="10px"
              padding="2px 3px"
              color="white"
              style={{ marginBottom: '10px' }}
            >
              A
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              C
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              R
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              E
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              D
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              I
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              T
            </Text>
            <Text
              bold
              fontSize="10px"
              padding="2px 3px"
              color="white"
              style={{ marginBottom: '10px' }}
            >
              O
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              F
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              I
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              S
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              C
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              A
            </Text>
            <Text bold fontSize="10px" color="white" padding="2px 3px">
              L
            </Text>
          </View>
        </View>

        <Text bold>SON:</Text>
        <Text textAlign="center" bold>
          Cochabamba,
        </Text>
        <Text textAlign="center" bold>
          Oficina, calle 25 de Mayo N° 375 - 1° Piso Telefax 4662067 - telf:
          4662068
        </Text>
      </Page>
    </Document>
  )

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  )
}

export default RenderProforma
