import './Loading.css'
const Loading = () => {
  return (
    <>
      <div className="w-full h-[200px] flex items-center justify-center">
        <div className="text-center w-[200px] flex items-center">
          <svg className=" animate-spin h-[30px] mr-3" viewBox="0 0 24 24">
            <i className="text-[#FF7000] font-bold fa-solid fa-circle-notch "></i>
          </svg>
          <span className="text-[#B6B6B6] py-[10px] text-[18px] font-bold">
            {' '}
            Cargando ....
          </span>
        </div>
      </div>
    </>
  )
}

export default Loading
