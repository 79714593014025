// import './RenderPdf.css'
import * as dayjs from "dayjs";
import { Request } from "../../hooks/Request";
import logotipo from "../../assets/LogotipoGA.png";
import { Circle } from "../../components/Animation";
// import { requestAuth } from '../../components/services/RequestService'
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
// import GeneratePdf from '../../components/GeneratePdf/GeneratePdf'
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import qrAgda from "../../assets/qrAgda.png"

const PdfTemplate = (props) => {
  const { procedure, obs, lot } = props;


  const MyDoc = (
    <Document>
      {/* <Page size="A4" style={{ padding: "20px" }}> */}
      <Page size="A4" style={{ paddingVertical: "20px", paddingHorizontal: "40px" }}>
        
        <View style={{paddingBottom: "20px", paddingTop: "40px"}}>
          <View
            // width="45%"
            
            justifyContent="center"
            alignItems="center">
            <Image
              src="https://res.cloudinary.com/joeldes/image/upload/v1703637339/Agda/logo_nw1jah.png"
              // style={{ height: "50px", padding: "5px 0" }}
              style={{ height: "50px" }}
            />
          </View>
          <View
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Text textAlign="center" fontSize="13px" bold >
              HOJA DE CONTROL CARPETA {"\n"} HCC-002
            </Text>
          </View>

        </View>

        <View width="100%"
          style={{ marginTop: "5px" }}
          border={{ right: true, bottom: true, left: true, top: true }}>
          <Text
            bold minWidth="30%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            AGDA S.A.
          </Text>
          <Text
            bold minWidth="40%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            HOJA DE CONTROL CARPETA
          </Text >
          <Text
            bold minWidth="30%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            N° {obs.numberValue}
          </Text >
        </View>
        <View width="100%"
          // style={{ marginTop: "5px" }}
          border={{ right: true, bottom: true, left: true }}>
          <Text
            bold 
            minWidth="30%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            PARA:
          </Text>
          <Text   style={{  textTransform: "uppercase" }} fontSize="11px">{lot.nameUser} {lot.fatherLastNameUser} {lot.motherLastNameUser}</Text>
        </View>
        <View width="100%"
          // style={{ marginTop: "5px" }}
          border={{ right: true, bottom: true, left: true }}>
          <Text
            bold 
            minWidth="30%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            DE:
          </Text>
          <Text 
            // style={{ textDecoration: "underline" }}
            fontSize="11px">
            ENZO RIOS BURGOA
          </Text>
        </View >
        {/* ---------------------------------bloque de columnas---------- */}


        <View width="100%"
          border={{ right: true, bottom: true, left: true }}>
          <Text
            bold minWidth="30%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            N° DE CARPETA
          </Text>
          <Text
            bold minWidth="40%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            ADUANA DE DESPACHO
          </Text >
          <Text
            bold minWidth="30%"
            style={{ backgroundColor: "#e0e0e0" }}
            // border={{ right: true }}
            fontSize="11px">
            GESTION
          </Text >
        </View>

        <View width="100%"
          border={{ right: true, bottom: true, left: true }}>
          <Text
            minWidth="30%"
            style={{ textDecoration: "underline" }}
            border={{ right: true }}
            fontSize="11px">
            {procedure.nroInterno  || " "}
          </Text>
          <Text
            minWidth="40%"
            style={{ textDecoration: "underline" }}
            border={{ right: true }}
            fontSize="11px">
            {procedure.aduanaDespacho || " "}
          </Text >
          <Text
            minWidth="30%"
            style={{ textDecoration: "underline" }}
            // border={{ right: true }}
            fontSize="11px">
            {procedure.procGestion  || " "}
          </Text >
        </View>

        <View width="100%"
          border={{ right: true, bottom: true, left: true }}>
          <Text
            bold minWidth="30%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            Fecha
          </Text>
          <Text
            bold minWidth="40%"
            style={{ backgroundColor: "#e0e0e0" }}
            border={{ right: true }}
            fontSize="11px">
            ENTREGADO
          </Text >
          <Text
            bold minWidth="30%"
            style={{ backgroundColor: "#e0e0e0" }}
            // border={{ right: true }}
            fontSize="11px">
            RECIBIDO
          </Text >
        </View>

        <View width="100%"
          border={{ right: true, bottom: true, left: true }}>
          <Text
            minWidth="30%"
            border={{ right: true }}
            // style={{ textDecoration: "underline" }}
            fontSize="11px">
              { dayjs(obs.creationDate).format('DD/MM/YYYY HH:mm')  || " "}
          </Text>
          <Text
            minWidth="40%"
            border={{ right: true }}
            fontSize="11px">
              {" "}
          </Text >
          <Text
            minWidth="30%"
            // border={{ right: true }}
            fontSize="11px">
              {" "}
          </Text >
        </View>

        {/* ----------------------bloque de columnas y filas---------------- */}

        <View
          border={{ right: true, bottom: true, left: true }} 
          width="100%"
          >
          <View width="100%">
            <Text fontSize="11px">OBSERVACIONES</Text>
          </View>

          <View border={{ left: true }}   style={{ paddingBottom: "40px" }}>
            <View flexDirection="column" >
              <View >
                <View width="100%" border={{ bottom: true }} style={{ paddingBottom: "40px" }}>
                  <Text fontSize="11px">SALIDA</Text>
                </View>
              </View>
              <Text fontSize="11px">REINGRESO</Text>
            </View>
          </View>
        </View>



        <View
          style={{ marginTop: "25px" }}
          width="100%"
          flexDirection="column"
          borderContent
          justifyContent="center"
          alignItems="center" >
          <Text bold style={{ marginTop: "25px", textDecoration: "underline" }} fontSize="20px">
            {/* N° DE CARPETA:  */}
            OBSERVADO
          </Text>
        
          <Text 
            style={{ marginTop: "25px", marginLeft: "25px", marginRight: "25px" }} 
            fontSize="20px"   
            textAlign="center" 
          >
            {obs.description}
          </Text>

          <View
            width="50%"
            style={{ marginTop: "25px", marginBottom: "25px" }}
            justifyContent="center"
            alignItems="center">
            <Image src={qrAgda} style={{ width: '110px' }} />
          </View>
        </View>

      </Page>
    </Document>
  );

  return (
    <div>
      {/* <BlobProvider document={MyDoc}>
        {({ blob }) => {
          return <div onClick={() => uploadFile(blob)}>Subir documento</div>
        }}
      </BlobProvider> */}
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  )
}

export default PdfTemplate