import Recurso_3logo from "../../../assets/logo45-5.png";
import FormDefault from "../../../components/Forms/FormDefault";
import * as Yup from "yup";
import { request } from "../../../components/services/RequestService";
import { useAuth } from "../../../contexts/AuthContext";
import { validateArray } from "../../../utils/validate";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { H1, H3 } from "../../../components/Text";
import { RequestNoAuth } from "../../../hooks/RequestNoAuth";
import React from "react";
import styled from "styled-components";
import FormLogin from "../../../containers/auth/Formlogin";
import Recover from "../recover/Recover";
import PasswordRecovery from "../password-recovery/PasswordRecovery";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const params = useParams();

  const { data, loading } = RequestNoAuth({
    urlApi: "/institutionParams/generalData",
  });

  function handleToJson(list) {
    if (validateArray(list)) {
      let auxJson = {};
      list.forEach((element) => {
        auxJson = { ...auxJson, [element.code]: element.value };
      });
      return auxJson;
    }
    return {};
  }

  if (!loading) {
    return null;
  } else if (location.pathname === "/auth/login") {
    return (
      <FormLogin
        title="Inicio de sesión"
        fields={[
          {
            label: "Nombre de usuario",
            name: "UsernameOrEmail",
            typeInput: "input",
            type: "text",
            placeholder: "Introduzca su nombre de usuario",
            className: `text-[15px] mt-[5px]`,
          },
          {
            label: "Contraseña",
            name: "password",
            typeInput: "input",
            type: "password",
            placeholder: "Introduzca su nombre de usuario",
            className: `text-[15px] mt-[5px]`,
          },
        ]}
        validationSchema={{
          UsernameOrEmail: Yup.string().required("Este campo es obligatorio"),
          password: Yup.string().required("Este campo es obligatorio"),
        }}
        onSubmit={async (values) => {
          await request("post", "/auth/login", values)
            .then(function (response) {
              localStorage.setItem("token_seguridad", response.data.token);
              localStorage.setItem("id", response.data.user.id);
              setUser({
                user: response.data.user,
                rols: response.data.rols,
                company: response.data.companys,
                institutionParam: response.data.institutionParam,
              });
              const rol = response.data.rols[0];
              navigate(`/rol/${rol.id}/menu`);
              toast.success("Inicio exitoso");
            })
            .catch((err) => {
              console.log();
              if (err.response.status === 402) {
                toast.error("La licencia ha expirado.");
              } else {
                toast.error(
                  "Tu nombre de usuario o contraseña son incorrectas."
                );
              }
            });
        }}
        buttonName="INGRESAR"
        data={handleToJson(data)}
      />
    );
  } else if (location.pathname === "/auth/recover") {
    return <Recover />;
  } else if (location.pathname.includes("/auth/password-recovery")) {
    return <PasswordRecovery />;
  }
};
export default Login;
