import { toast } from "react-toastify";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";

const EditUser = (props) => {
  const { userInfo, onReload } = props;
  
  return (
    <FormDefault
      tittle="Editar datos del usuario"
      fields={[
        {
          label: "Nombre",
          name: "Name",
          placeholder: "Introduzca su nombre",
          type: "text",
          typeInput: "input",
        },
        {
          label: "Apellido paterno",
          name: "FatherLastName",
          placeholder: "Introduzca su apellido paterno",
          type: "text",
          typeInput: "input",
        },
        {
          label: "Apellido materno",
          name: "MotherLastName",
          placeholder: "Introduzca su apellido materno",
          type: "text",
          typeInput: "input",
        },
        {
          label: "Nombre de usuario",
          name: "Username",
          placeholder: "Introduzca su nombre de usuario",
          type: "text",
          typeInput: "input",
        },
        {
          label: "Numero de celular",
          name: "CellphoneNumber",
          placeholder: "Introduzca su numero de celular",
          type: "number",
          typeInput: "input",
        },
        {
          label: "Carnet de identidad",
          name: "Ci",
          placeholder: "Introduzca su numero de carnet de identidad",
          type: "text",
          typeInput: "input",
        },
        {
          label: "Ciudad de residencia",
          name: "City",
          type: "select",
          typeInput: "select",
          options: [
            {
              label: "Santa cruz",
              value: "Santa cruz",
            },
            {
              label: "La paz",
              value: "La paz",
            },
            {
              label: "Cochabamba",
              value: "Cochabamba",
            },
            {
              label: "Sucre",
              value: "Sucre",
            },
            {
              label: "Oruro",
              value: "Oruro",
            },
            {
              label: "Tarija",
              value: "Tarija",
            },
            {
              label: "Beni",
              value: "Beni",
            },
            {
              label: "Pando",
              value: "Pando",
            },
            {
              label: "Potosi",
              value: "Potosi",
            },
          ],
        },
        {
          label: "Email",
          name: "Email",
          placeholder: "correo@gmail.com",
          type: "email",
          typeInput: "input",
        },
        {
          label: "Contraseña",
          name: "Password",
          placeholder: "Introduzca su contraseña",
          type: "password",
          typeInput: "input",
        },
        {
          label: "Numero de telefono",
          name: "PhoneNumber",
          placeholder: "Introduzca su numero de telefono",
          type: "number",
          typeInput: "input",
        },
        {
          label: "Seleccione los roles",
          name: "Rol",
          placeholder: "Introduzca su nombre de usuario",
          type: "checkbox",
          typeInput: "checkboxes",
          urlApi: "/rol",
          value: "id",
        },
      ]}
      initialValues={{
        CellphoneNumber: userInfo?.userToReturn?.cellphoneNumber || "",
        Ci: userInfo?.userToReturn?.ci || "",
        City: userInfo?.userToReturn?.city || "Santa cruz",
        Email: userInfo?.userToReturn?.email || "",
        FatherLastName: userInfo?.userToReturn?.fatherLastName || "",
        MotherLastName: userInfo?.userToReturn?.motherLastName || "",
        Name: userInfo?.userToReturn?.name || "",
        PhoneNumber: userInfo?.userToReturn?.phoneNumber || "",
        Status: userInfo?.userToReturn?.status || "",
        Username: userInfo?.userToReturn?.username || "",
        Rol: userInfo?.rolsUserToReturn.map((rol) => "" + rol.id) || [],
      }}
      onSubmit={async (values, resetForm) => {
        await requestAuth(
          "put",
          `users/${userInfo.userToReturn.id}/UpdateUser`,
          {
            ...values,
            Rol: values.Rol.map((item) => ({
              Id: item,
            })),
          }
        )
          .then(() => {
            onReload();
            toast.success("Envió exitoso");            
            // call();
            //reload();
            // setactivateFormEdit(false);
            // getuser();
            // resetForm();
          })
          .catch(() => {
            toast.error("Se produjo un error");
          });
      }}
      buttonName="Enviar"
    />
  );
};

export default EditUser;
