import FormDefault from "../../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { requestAuth } from "../../../components/services/RequestService";

const CreateCompany = (props) => {
  const { onClose, reload } = props;

  return (
    <div>
      <FormDefault
        tittle="Crear nueva compañía"
        fields={[
          {
            label: "Id de compañia",
            name: "num",
            typeInput: "input",
          },
          {
            label: "Sigla",
            name: "sigla",
            typeInput: "input",
          },
          {
            label: "Número de Identificación Tributaria (NIT)",
            name: "nit",
            typeInput: "input",
          },

          {
            label: "Razón social",
            name: "razonSocial",
            typeInput: "input",
          },
          {
            label: "Correo electrónico",
            name: "email",
            typeInput: "input",
          },
          {
            label: "Tipo cliente",
            name: "tipoCliente",
            typeInput: "input",
          },
          {
            label: "Pais",
            name: "pais",
            typeInput: "input",
          },
          {
            label: "Departamento",
            name: "departamento",
            typeInput: "input",
          },
          {
            label: "Ciudad",
            name: "ciudad",
            typeInput: "input",
          },
          {
            label: "Domicilio legal",
            name: "domicilioLegal",
            typeInput: "input",
          },
          {
            label: "Clasificación Cliente",
            name: "clasificacionCliente",
            typeInput: "input",
          },
          {
            label: "Actividad",
            name: "actividad",
            typeInput: "input",
          },
          {
            label: "PagaTributos",
            name: "pagaTributos",
            typeInput: "input",
          },
          {
            label: "Descripción Negocio",
            name: "descripcionNegocio",
            typeInput: "input",
          },
        ]}
        initialValues={{
          nit: "",
          razonSocial: "",
        }}
        validationSchema={Yup.object().shape({
          nit: Yup.string().required("Este campo es obligatorio"),
          razonSocial: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          await requestAuth("post", "company/Register", values)
            .then(() => {
              reload();
              onClose();
              toast.success("Envio exitoso");
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : "Se ha producido un error al enviar"
              );
            });
        }}
        buttonName="Enviar"
      />
    </div>
  );
};

export default CreateCompany;
