import React, { useState } from "react";
import { toast } from "react-toastify";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import SubModal from "../../components/Modal/SubModal";
import * as Yup from "yup";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import Button from "../../components/Buttons/Button";
import Delete from "../../containers/ModalsMenus/Delete";
const Menus = () => {
  const [infoMenus, setInfoMenus] = useState();
  const [pasos, setPasos] = useState();
  const [remov, setRemov] = useState(false);
  const [createMenuForm, setCreateForm] = useState(false);
  const [reload, setreload] = useState(false);
  const [btnStep, setBtnStep] = useState(false);

  function editStep(values) {
    setPasos(values);
    setInfoMenus(values);
    setBtnStep(true);
  }
  // function deleteStep(values) {
  //   setPasos(values)
  //   setRemov(true)
  // }
  // function getMenus(data) {
  //   setInfoMenus(data)
  // }

  exitEsc(setCreateForm || setBtnStep || setRemov);
  return (
    <>
      <div style={{ height: "calc(100% - 40px)" }}>
        <CallServiceTable
          reload={reload}
          component={() => {
            return (
              <Button
                className="text-lg py-2"
                onClick={() => setCreateForm(true)}
              >
                Crear menu <i className="fa-solid fa-plus"></i>
              </Button>
            );
          }}
          urlApi="/menu"
          header={[
            {
              name: "label",
              label: "Etiqueta",
              filter: false,
            },
            {
              name: "name",
              label: "Nombre clave",
              filter: false,
            },
            {
              name: "action",
              type: "action",
              label: "Editar",
              hidden: true,
              actions: [
                {
                  label: "Editar",
                  icon: "fas fa-edit",
                  action: editStep,
                  color: "text-[#1d4ed8]",
                },
              ],
            },
          ]}
        />
      </div>

      <SubModal setactivateForm={setCreateForm} activateForm={createMenuForm}>
        <div className="bg-slate-200 px-[30px]">
          <FormDefault
            tittle="Crear nuevo menú"
            fields={[
              {
                label: "Etiqueta del menu",
                name: "Label",
                placeholder: "Introduzca la etiqueta del menu",
                type: "text",
                typeInput: "input",
              },
              {
                label: "Nombre clave del menu",
                name: "Name",
                placeholder: "Introduzca el nombre del menú",
                type: "text",
                typeInput: "input",
              },
            ]}
            initialValues={{
              Label: "",
              Name: "",
            }}
            validationSchema={Yup.object().shape({
              Label: Yup.string().required("Este campo es obligatorio"),
              Name: Yup.string().required("Este campo es obligatorio"),
            })}
            onSubmit={async (values, resetForm) => {
              await requestAuth("post", "/menu", values)
                .then(() => {
                  toast.success("Envio exitoso");
                  setreload(!reload);
                  setCreateForm(false);
                  resetForm();
                })
                .catch((response) => {
                  toast.error(
                    response.response.data
                      ? response.response.data
                      : "Se produjo un error"
                  );
                });
            }}
            buttonName="Enviar"
          />
        </div>
      </SubModal>
      <SubModal setactivateForm={setBtnStep} activateForm={btnStep}>
        <FormDefault
          tittle="Editar nombre del menú"
          fields={[
            {
              label: "Etiqueta del menu",
              name: "Label",
              placeholder: "Introduzca la etiqueta del menu",
              type: "text",
              typeInput: "input",
            },
            {
              label: "Nombre clave del menu",
              name: "Name",
              placeholder: "Introduzca el nombre del menú",
              type: "text",
              typeInput: "input",
            },
          ]}
          initialValues={{
            Name: infoMenus?.name || "",
            Label: infoMenus?.label || "",
            Id: infoMenus?.id || "",
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required("Este campo es obligatorio"),
            Label: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={async (values, resetForm) => {
            await requestAuth("put", `/menu`, {
              ...values,
              Id: infoMenus.id,
            })
              .then(() => {
                setreload(!reload);
                setBtnStep(false);
                toast.success("Envio exitoso");
                resetForm();
              })
              .catch((response) => {
                toast.error(
                  response.response.data
                    ? response.response.data
                    : "Se produjo un error al enviar"
                );
              });
          }}
          buttonName="Enviar"
        />
      </SubModal>
      <Delete
        remov={remov}
        setRemov={setRemov}
        activateForm={remov}
        setreload={setreload}
        reload={reload}
        infoMenus={infoMenus}
      />
    </>
  );
};
const exitEsc = (setClose) => {
  document.addEventListener("keydown", (event) => {
    if (event.which === 27 && event.keyCode === 27) {
      setClose(false);
    }
  });
};
export default Menus;
