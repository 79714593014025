import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CallServiceTable from "../../../components/Tables/CallServiceTable";
import { requestAuth } from "../../../components/services/RequestService";
import { H3 } from "../../../components/Text";

const AddClient = (props) => {
  const { companyID, onReload, clients } = props;

  const envDataClient = (values) => {
    requestAuth("post", `company/${companyID}/userId/${values?.id}`)
      .then(() => {
        // call();
        onReload();
        console.log("values", values);
        toast.success("Usuario Añadido a la Compañia");
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };
  return (
    <div>
      {clients?.length == 0 ? (
        ''
      ) : (
        <div>
          <H3>Lista de clientes de la compañia {companyID.razonSocial}</H3>
          <ul className="flex flex-col w-full justify-between py-[20px]">
            {clients?.map((client, i) => {
              return (
                <li
                  key={i}
                  // onClick={() => setInfoClient(client.id)}
                  className="font-semibold my-[10px] p-[10px] flex flex-col sm:flex-row bg-slate-300 items-center justify-between"
                >
                  - {client.name} {client.fatherLastName}{' '}
                  {client.motherLastName}
                  <button
                    onClick={() => {
                      requestAuth(
                        'put',
                        `company/${companyID}/userId/${client.id}`,
                      )
                        .then(() => {
                          onReload()
                          toast.success('Cliente eliminado')                          
                        })
                        .catch(() => {
                          toast.error('se produjo un error al enviar')
                        })
                    }}
                    className="md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[15px] sm:text-[18px] 2xl:text-[20px] leading-6 bg-[#FF2402] text-center px-[10px] py-[10px] lg:py-[15px]"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-trash-can"
                      className="text-white text-"
                    />
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
      <H3>Lista de usuarios con el rol de cliente</H3>
      <CallServiceTable
        onReload={onReload}
        // onClick={getClient}
        urlApi="/rol/Users"
        addFilters={[
          {
            name: "firstName",
            label: "Nombres",
            filter: true,
          },
        ]}
        header={[
          {
            name: "name",
            label: "Nombres",
            filter: false,
          },
          {
            name: "fatherLastName",
            label: "Apellido Paterno",
            filter: true,
          },
          {
            name: "motherLastName",
            label: "Apellido Materno",
            filter: true,
          },
          {
            name: "email",
            label: "Correo",
            filter: true,
          },
          {
            name: "add",
            type: "action",
            label: "Añadir",
            sticky: true,
            actions: [
              {
                label: "Añadir",
                icon: "fas fa-circle-plus",
                action: envDataClient,
                color: "text-[#1d4ed8]",
              },
            ],
          },
        ]}
        addUrl={`RolName=client&`}        
      />
    </div>
  );
};

export default AddClient;
