import React from "react";
import { Request } from "../../hooks/Request";
import useSize from "../../hooks/useSize";
import Table from "./Table";
import { Button } from "../Buttons";
import TableComplete from "./TableComplete";
import Pagination from "./Pagination";
import { useState, useEffect } from "react";
import Search from "./SearchTable";
import { validateArray } from "../../utils/validate";
import { viewLinkExcel } from "../../utils/constGenerate";
import { requestAuth } from "../services/RequestService";
import { toast } from "react-toastify";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../Modal";
import { H3 } from "../Text";

const CallServiceTable = (props) => {
  const {
    urlApi,
    header,
    addUrl,
    onClick = () => {},
    filters,
    addFilters = [],
    reload,
    component: Component,
    responsive,
    downloadUrl = "",
    download = false,
  } = props;

  const { openDialog, dialogClose } = useDialog();
  const [target, currentSize] = useSize();
  const [pageNumber, setPageNumber] = useState(1);
  const [selected, setSelected] = useState(handleInitialSelected());
  function handleInitialSelected() {
    const list = [...addFilters, ...header].filter((item) => item.filter);
    if (validateArray(list)) {
      return list[0].name;
    }
    return "";
  }
  const [search, setSearch] = useState("");

  const [tableComplete, setTableComplete] = useState(true);

  const [params, setParams] = useState(() => {
    let paramsInitial = {};
    if (filters && validateArray(filters)) {
      filters.forEach((element) => {
        if (validateArray(element.options)) {
          const optionsInitial = element.options.filter((item) => item.initial);
          if (optionsInitial && validateArray(optionsInitial)) {
            paramsInitial = {
              ...paramsInitial,
              [element.name]: optionsInitial[0].name,
            };
          }
        }
      });
    }
    return paramsInitial;
  });

  const { data, loading, pagination, call, error, buttons } = Request({
    urlApi: urlApi
      ? `${urlApi}?PageNumber=${pageNumber}${addUrl ? `&${addUrl}` : ""}${
          selected !== "" && search !== ""
            ? `&ForQuery=${selected}&TypeQuery=${search}`
            : ""
        }${handleFilters(params)}`
      : null,
  });

  function handleFilters(filters) {
    let aux = "";
    if (filters) {
      for (let key in filters) {
        if (filters[key]) {
          aux = aux + `&${key}=${filters[key]}`;
        }
      }
    }
    return aux;
  }

  async function handleDownload() {
    await requestAuth(
      "post",
      urlApi
        ? `${downloadUrl ? downloadUrl : urlApi}?PageNumber=${pageNumber}${
            addUrl ? `&${addUrl}` : ""
          }${
            selected !== "" && search !== ""
              ? `&ForQuery=${selected}&TypeQuery=${search}`
              : ""
          }${handleFilters(params)}`
        : null
    )
      .then((resp) => {
        viewLinkExcel(resp.data, openDialog);
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  function handleDownloadDialog() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres descargar la información en excel?</H3>
        }
        onClose={dialogClose}
        method="post"
        url={
          urlApi
            ? `${downloadUrl ? downloadUrl : urlApi}?PageNumber=${pageNumber}${
                addUrl ? `&${addUrl}` : ""
              }${
                selected !== "" && search !== ""
                  ? `&ForQuery=${selected}&TypeQuery=${search}`
                  : ""
              }${handleFilters(params)}`
            : null
        }
        texBtn="Generar"
        handleSuccess={(data) => viewLinkExcel(data.url, openDialog)}
      />
    );
  }

  useEffect(() => {
    if (loading) {
      call();
    }
  }, [urlApi, pageNumber]);

  useEffect(() => {
    if (loading) {
      setPageNumber(1);
      call();
    }
  }, [reload, params]);

  // useEffect(() => {
  //   if (loading) {
  //     setPageNumber(1);
  //     call();
  //   }
  // }, [reload, params, search]);

  return (
    <div className="flex flex-col h-full">
      {error ? (
        <>no se pudo cargar los datos</>
      ) : (
        <>
          <div className="flex mb-2 justify-between portrait:flex-col-reverse gap-2 portrait:items-end">
            <Search
              setSelected={setSelected}
              setSearch={setSearch}
              search={search}
              header={header}
              filters={filters}
              params={params}
              setParams={setParams}
              reload={call}
              addFilters={addFilters}
              setPageNumber={setPageNumber}
            />
            <div className="flex items-center gap-2">
              {Component && <Component {...props} reload={call} />}
              {download && (
                <Button
                  onClick={() => handleDownloadDialog()}
                  width="w-auto"
                  content="Descargar información en Excel"
                  className="py-3 text-lg"
                >
                  <i className="fas fa-file-download"></i>
                </Button>
              )}
              {/* <Button
                onClick={() => setTableComplete(!tableComplete)}
                width="w-auto"
                className="py-3 text-lg"
              >
                <i className="fas fa-exchange-alt"></i>
              </Button> */}
            </div>
          </div>
          {/* <br /> */}
          <div
            ref={target}
            style={{ maxHeight: pagination ? "calc(100% - 120px)" : "98%" }}
          >
            {currentSize && (currentSize.height || currentSize.height === 0) ? (
              loading ? (
                <>
                  {tableComplete ? (
                    <TableComplete
                      header={header}
                      data={data}
                      call={call}
                      height={currentSize.height ? currentSize.height : null}
                      onClick={onClick}
                      buttons={buttons}
                    />
                  ) : (
                    <Table
                      call={call}
                      onClick={onClick}
                      data={data}
                      header={header}
                      responsive={responsive}
                    />
                  )}
                </>
              ) : null
            ) : null}
          </div>
          {pagination && (
            <Pagination
              pag={pagination ? JSON.parse(pagination) : null}
              setPageNumber={setPageNumber}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CallServiceTable;
