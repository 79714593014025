import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ModalFullScreen = ({
  children,
  setactivateForm,
  activateForm,
  exitesc,
  onClose,
}) => {
  function handleClose() {
    if (onClose) {
      onClose()
    } else {
      setactivateForm(!activateForm)
    }
  }
  // exitEsc(handleClose || exitesc)
  return (
    <div
      className={
        activateForm
          ? 'z-40 h-screen w-full bg-black/50 fixed top-0 right-0 duration-300 left-0 flex justify-center items-center'
          : 'z-40 h-screen bg-black/50 fixed -right-[100%] bottom-0 duration-300 left-full flex justify-center items-center'
      }
    >
      <div className="h-[100%] w-[100%] absolute" onClick={handleClose}></div>
      <div className="min-w-[300px] max-h-screen overflow-y-auto bg-slate-200 relative bg-inherit px-[20px] py-0 rounded-lg">
        <button
          className="font-bold text-[20px] ml-[90%] mt-[5px]"
          onClick={handleClose}
          type="button"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-xmark"
            className="text-4xl lg:text-5xl"
          />
        </button>
        {activateForm ? children : null}
      </div>
    </div>
  )
}

// const exitEsc = (handleClose) => {
//   document.addEventListener('keydown', (event) => {
//     if (event.which === 27 && event.keyCode === 27) {
//       handleClose()
//     }
//   })
// }

export default ModalFullScreen
