import { validateArray } from "../../utils/validate";
import { H3 } from "../../components/Text";
// import { useEffect, useState } from 'react'
export const TableManagement = ({
  header,
  data,
  TextEmptyList = "Lista vacía",
}) => {
  return (
    // <div className="relative overflow-x-auto h-96">
    <div className="relative overflow-x-auto max-h-96 overflow-y-auto p-1 bg-white border border-gray-400 rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs uppercase bg-gray-50 sticky top-0">
          <tr>
            {header?.map((head, index) => (
              <th
                scope="col"
                className="px-6 py-3 bg-[#fd9c64] text-white"
                key={index}
              >
                {head?.label}
              </th>
            ))}
          </tr>
        </thead>
        {validateArray(data) ? (
          <tbody>
            {data?.map((item, index) => (
              <tr className="bg-white border-b " key={index}>
                {header?.map((head, idy) => (
                  <th
                    key={idy}
                    scope="row"
                    className={`px-6 py-4 text-gray-700 whitespace-pre-wrap overflow-auto ${
                      head?.isNumber ? "text-right" : ""
                    } ${head?.aditionalClass || "font-semibold"}`}
                  >
                    {item[head?.name]}
                  </th>
                ))}
              </tr>
            ))}
            <tr className="bg-white border-b ">
              {header?.map((head, idy) => (
                <th
                  key={idy}
                  scope="row"
                  className={`px-6 py-4 text-gray-700 whitespace-pre-wrap overflow-auto ${
                    head?.isNumber ? "text-right" : ""
                  } ${head?.aditionalClass || "font-semibold"}`}
                >
                  {head.makeTotal
                    ? data.reduce(
                        (acc, curr) => acc + parseFloat(curr[head.name] || 0),
                        0
                      )
                    : ""}
                  {head.makeTotalPercentage ? "100 %" : ""}
                  {!head.makeTotal && !head.makeTotalPercentage ? "Total" : ""}
                </th>
              ))}
            </tr>
          </tbody>
        ) : null}
      </table>
      {!validateArray(data) && (
        <div className="px-6 py-3 bg-[#ffeadd3e] text-gray-700">
          <H3>{TextEmptyList}</H3>
        </div>
      )}
    </div>
  );
};

export const SimpleTableManagement = ({
  header,
  data,
  TextEmptyList = "Lista vacía",
}) => {
  return (
    // <div className="relative overflow-x-auto h-96">
    <div className="relative overflow-x-auto max-h-96 mb-5 overflow-y-auto p-1 bg-white border border-gray-400 rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs uppercase bg-gray-50 sticky top-0">
          <tr>
            {header?.map((head, index) => (
              <th
                scope="col"
                className="px-6 py-3 bg-[#fd9c64] text-white"
                key={index}
              >
                {head?.label}
              </th>
            ))}
          </tr>
        </thead>
        {validateArray(data) ? (
          <tbody>
            {data?.map((item, index) => (
              <tr className="bg-white border-b " key={index}>
                {header?.map((head, idy) =>
                  head.type !== "action" ? (
                    <th
                      key={idy}
                      scope="row"
                      className={`px-6 py-4 text-gray-700 whitespace-pre-wrap overflow-auto ${
                        head?.isNumber ? "text-right" : ""
                      } ${head?.aditionalClass || "font-semibold"}`}
                    >
                      {item[head?.name]}
                    </th>
                  ) : (
                    <th
                      key={idy}
                      scope="row"
                      className={`px-6 py-4 text-gray-700 whitespace-pre-wrap overflow-auto ${
                        head?.isNumber ? "text-right" : ""
                      } ${head?.aditionalClass || "font-semibold"}`}
                    >
                      {head.actions?.map((ix, ind) => (
                        // <button type='button' key={ind}><i className={`${ix.icon} text-red-500`} onClick={() => ix.action(item)} /></button>
                        <button
                          type="button"
                          onClick={() => ix.action(item)}
                          key={ind}
                        >
                          <i className={`${ix.icon} text-red-500 text-lg`} />
                        </button>
                      ))}
                    </th>
                  )
                )}
              </tr>
            ))}
            {/* <tr className="bg-white border-b ">
              {header?.map((head, idy) => (
                <th
                  key={idy}
                  scope="row"
                  className={`px-6 py-4 text-gray-700 whitespace-pre-wrap overflow-auto ${head?.isNumber ? 'text-right' : ''} ${head?.aditionalClass || 'font-semibold'}`}
                >
                  {
                    head.makeTotal ? data.reduce((acc, curr) => acc + parseFloat(curr[head.name] || 0), 0) : ''
                  }
                  {
                    head.makeTotalPercentage ? '100 %' : ''
                  }
                  {
                    !head.makeTotal && !head.makeTotalPercentage ? 'Total' : ''
                  }
                </th>
              ))}
            </tr> */}
          </tbody>
        ) : null}
      </table>
      {!validateArray(data) && (
        <div className="px-6 py-3 bg-[#ffeadd3e] text-gray-700">
          <H3>{TextEmptyList}</H3>
        </div>
      )}
    </div>
  );
};
