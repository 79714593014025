import * as Yup from "yup";
import { toast } from "react-toastify";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";

const NewProcedure = (props) => {
  const { reload, onClose } = props;
  return (
    <div>
      <FormDefault
        tittle="Agregar nuevo trámite"
        fields={[
          {
            label: "Compañia",
            name: "CompanyId",
            typeInput: "select",
            urlApi: "/company/getallcompanies",
            value: "id",
            labelOption: "razonSocial",
          },
          {
            label: "Tipo de procedimiento",
            name: "ProcedureTypeId",
            typeInput: "select",
            urlApi: "/procedureType",
            value: "id",
            labelOption: "name",
          },
          // {
          //   label: 'Tipo de tramite',
          //   name: 'Category',
          //   typeInput: 'select',
          //   options: [
          //     {
          //       label: 'Normal',
          //       value: 'normal',
          //     },
          //     {
          //       label: 'Multiple',
          //       value: 'multiple',
          //     },
          //   ],
          // },
        ]}
        initialValues={{
          CompanyId: "",
          ProcedureTypeId: "",
          ProcedureTypeId: "",
        }}
        validationSchema={Yup.object().shape({
          CompanyId: Yup.string().required("Este campo es obligatorio"),
          ProcedureTypeId: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          await requestAuth("post", `/procedure`, values)
            .then(() => {
              reload();
              onClose();
              toast.success("Trámite agregado correctamente!!!");
            })
            .catch(() => {
              toast.error("Se ha producido un error al crear el Proceso");
            });
        }}
        buttonName="Enviar"
      />
    </div>
  );
};

export default NewProcedure;
