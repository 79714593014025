import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import * as dayjs from "dayjs";
import { validateArray } from "../../utils/validate";

const GenerateRoadmap = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/agda/hojaDeRuta/${procedure.id || procedure.procedureId}`,
  });
  const { data: dataDispatch, loading: loadingDispatch } = Request({
    urlApi: `/Agda/dispatchDocument/numRef/${procedure.internCode}`,
  });

  function handleListObservation() {
    const listObs = [
      {
        label: "Observaciones (Recepcion y revision)",
        name: "observaciones",
      },
      {
        label: "Observaciones de los documentos",
        name: "observacionesDeLosDocumentos",
      },
      {
        label: "Observaciones de la DAM",
        name: "observacionesDeLaDam",
      },
      {
        label: "Respuesta a las observaciones de la DAM (paso 6)",
        name: "respuestaALasObservaciones",
      },
      {
        label: "Observaciones de parte de recepción (paso 8)",
        name: "observacionesDeParteDeRecepcion",
      },
      {
        label: "Descripcion de la evaluacion (Paso 10)",
        name: "descripcionDeLaEvaluacion",
      },
      {
        label: "Observaciones de proforma (Paso 11)",
        name: "observacionesDeProforma",
      },
      {
        label: "Observaciones de la DIM (Paso 15)",
        name: "observacionesDeLaDim",
      },
      {
        label: "Respuesta a las observaciones (DIM) (Paso 16)",
        name: "respuestaALasObservacionesDim",
      },
      {
        label: "Informe de evaluación (Paso 20)",
        name: "informeDeEvaluacion",
      },
      {
        label: "Detalles de la entrega (Paso 27)",
        name: "detallesDeLaEntrega",
      },
      {
        label: "Observaciones de la verificación (Paso 28)",
        name: "observacionesDeLaVerificacion",
      },
    ];
    let listResult = [];

    listObs.forEach((element) => {
      if (data[element.name] && data[element.name] !== "-") {
        listResult.push({
          observation: element.label,
          description: data[element.name],
        });
      }
    });

    return listResult;
  }

  if (!loading || !loadingDispatch) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent style={{ marginBottom: "5px" }}>
          <View
            width="20%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src="https://res.cloudinary.com/joeldes/image/upload/v1703637339/Agda/logo_nw1jah.png"
              style={{ height: "50px", padding: "5px 0" }}
            />
          </View>
          <View
            flexDirection="column"
            width="65%"
            border={{ right: true }}
            justifyContent="center"
            alignItems="center"
            padding="0 10px"
          >
            <Text textAlign="center" fontSize="10px" padding="2px 3px" bold>
              AGENCIA DESPACHANTE DE ADUANA
            </Text>
            <Text textAlign="center" fontSize="10px" padding="2px 3px" bold>
              HOJA DE RUTA
            </Text>
          </View>
          <View width="15%" justifyContent="center">
            <Image
              src="https://res.cloudinary.com/joeldes/image/upload/v1704774719/Agda/logoOEA_nceuhb.png"
              style={{ height: "50px", padding: "5px 0" }}
            />
          </View>
        </View>
        <View>
          <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              FECHA Y HORA
            </Text>
            <Text border={{ top: true }}>
              {dayjs().format("DD/MM/YYYY HH:mm")}
            </Text>
          </View>
          <View
            width="50%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 2px" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              NOMBRE IMPORTADOR / EXPORTADOR
            </Text>
            <Text border={{ top: true }}>{data.razonSocial}</Text>
          </View>
          <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              Nº INTERNO
            </Text>
            <Text border={{ top: true }}>{data.numeroDeInterno}</Text>
          </View>
        </View>

        <View style={{ margin: "2px 0" }}>
          <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              Nº DE {data.procedureTypeId === 1 ? "DIM" : "DEX"}
            </Text>
            <Text border={{ top: true }}>{data.numeroDim}</Text>
          </View>
          <View
            width="25%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 2px" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              ADUANA
            </Text>
            <Text border={{ top: true }}>{data.aduana}</Text>
          </View>
          <View
            width="25%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 2px" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              REFERENCIA
            </Text>
            <Text border={{ top: true }}>{data.referenciaCliente}</Text>
          </View>
          <View width="25%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              FECHA DE FACTURACIÓN
            </Text>
            <Text border={{ top: true }}>
              {dataDispatch?.registerDate
                ? dayjs(dataDispatch.registerDate).format("DD/MM/YYYY HH:mm")
                : ""}
            </Text>
          </View>
        </View>

        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          REQUERIMIENTOS DE CLIENTE
        </Text>
        <View>
          <View
            width="50%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 2px 0 0" }}
          >
            <Text bold style={{ margin: "0 auto" }}>
              IMPORTACIÓN
            </Text>
            <Text border={{ top: true }}>
              {data.procedureTypeId === 1 ? "Importación" : ""}
            </Text>
          </View>
          <View width="50%" flexDirection="column" borderContent>
            <Text bold style={{ margin: "0 auto" }}>
              EXPORTACIÓN
            </Text>
            <Text border={{ top: true }}>
              {data.procedureTypeId === 2 ? "Exportación" : ""}
            </Text>
          </View>
        </View>
        {validateArray(data.historialReceptionVerificationDocuments) ? (
          <>
            <Text
              bold
              borderContent
              style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
            >
              DOCUMENTO PARA EL DESPACHO
            </Text>
            <CustomTablePDF
              fields={[
                {
                  label: "Nº",
                  name: "index",
                  type: "index",
                  width: "5",
                },
                {
                  label: "DOCUMENTO",
                  name: "name",
                  width: "25",
                },
                {
                  label: "Nº DOCUMENTO",
                  name: "number",
                  width: "27",
                },
                {
                  label: "PROVEEDOR",
                  name: "provider",
                  width: "20",
                },
                {
                  label: "ESTADO",
                  name: "status",
                  width: "13",
                },
                {
                  label: "ENLACE",
                  name: "url",
                  type: "link",
                  width: "10",
                },
              ]}
              border={{ top: true, right: true, bottom: true, left: true }}
              data={data.historialReceptionVerificationDocuments}
            />
          </>
        ) : null}
        <View style={{ marginTop: "5px" }}>
          <View width="50%" flexDirection="column">
            <View border={{ top: true, right: true, bottom: true, left: true }}>
              <Text bold style={{ margin: "1px auto" }}>
                DOCUMENTO DE FACTURACIÓN A ARCHIVO
              </Text>
            </View>
            <CustomTablePDF
              fields={[
                {
                  label: "TIPO DE DOCUMENTO",
                  name: "doc",
                  width: "50",
                },
                {
                  label: "Nº DOCUMENTO",
                  name: "nro",
                  width: "50",
                },
              ]}
              border={{ right: true, bottom: true, left: true }}
              data={[
                {
                  doc: "Factura",
                  nro: `${dataDispatch?.num ? dataDispatch.num : ""}`,
                },
                {
                  doc: "Planilla",
                  nro: `${dataDispatch?.num ? dataDispatch.num : ""}`,
                },
                { doc: "Carpeta", nro: data.numeroDeInterno },
                { doc: " ", nro: " " },
                { doc: " ", nro: " " },
              ]}
            />
          </View>
          <View width="50%" flexDirection="column">
            <View border={{ top: true, right: true, bottom: true, left: true }}>
              <Text bold style={{ margin: "1px auto" }}>
                TRIBUTOS A PAGAR
              </Text>
            </View>
            <CustomTablePDF
              fields={[
                {
                  label: "TIPO",
                  name: "doc",
                  width: "50",
                },
                {
                  label: "MONTO",
                  name: "amount",
                  width: "50",
                },
              ]}
              border={{ right: true, bottom: true, left: true }}
              data={[
                {
                  doc: "GA",
                  amount: data.ga && data.ga !== "null" ? data.ga : "0",
                },
                {
                  doc: "IVA",
                  amount: data.iva && data.iva !== "null" ? data.iva : "0",
                },
                {
                  doc: "ICE",
                  amount: data.ice && data.ice !== "null" ? data.ice : "0",
                },
                {
                  doc: "ICED",
                  amount: data.iceD && data.iceD !== "null" ? data.iceD : "0",
                },
                {
                  doc: "TOTAL",
                  amount:
                    data.total && data.total !== "null" ? data.total : "0",
                },
              ]}
            />
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          RECEPCIÓN DE DOCUMENTO
        </Text>
        <CustomTablePDF
          fields={[
            {
              label: "RESP. DE ENTREGA Y RECEPCIÓN",
              name: "rol",
              width: "25",
            },
            {
              label: "NOMBRE",
              name: "name",
              width: "42",
            },
            // {
            //   label: "FIRMA",
            //   name: "firm",
            //   width: "33",
            // },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={[
            {
              rol: "LIQUIDADOR ASIGNADO",
              name: validateArray(data.procedureHistories)
                ? data.procedureHistories[0].userInChange
                : "",
            },
            // { rol: "GESTOR ASIGNADO", name: data.gestor, firm: " " },
          ]}
        />

        <CustomTablePDF
          fields={[
            {
              label: "RESP. DE ENTREGA Y RECEPCION",
              name: "resp",
              width: "25",
            },
            {
              label: "FECHA / HORA",
              name: "date",
              type: "date",
              width: "42",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={[
            {
              resp: "IMPRESIÓN DE HOJA DE RUTA",
            },
          ]}
        />
        {validateArray(handleListObservation()) ? (
          <>
            <Text
              bold
              borderContent
              style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
            >
              COMENTARIOS Y OBSERVACIONES
            </Text>
            <CustomTablePDF
              fields={[
                {
                  label: "Observación",
                  name: "observation",
                  width: "30",
                },
                {
                  label: "Descripción",
                  name: "description",
                  width: "70",
                },
              ]}
              border={{ right: true, left: true }}
              data={handleListObservation()}
            />
          </>
        ) : null}
        {validateArray(data.subDataObservacionesDeLaDim) ? (
          <View>
            <View
              width="30%"
              border={{ top: true, right: true, bottom: true, left: true }}
            >
              <Text>Observaciones de la DIM</Text>
            </View>
            <View
              width="70%"
              flexDirection="column"
              border={{ top: true, right: true, bottom: true }}
            >
              {data.subDataObservacionesDeLaDim.map((item) => (
                <Text style={{ margin: "0" }}>
                  <Text bold>{item.label}:</Text> {item.description}
                </Text>
              ))}
            </View>
          </View>
        ) : null}

        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          TRAZABILIDAD POR PROCESOS
        </Text>
        <Text bold border={{ right: true, bottom: true, left: true }}>
          Duración Total: {data.totalDuration}
        </Text>
        <CustomTablePDF
          fields={[
            // {
            //   label: 'Nro',
            //   name: 'label',
            //   width: '5',
            // },
            {
              label: "Encargado",
              name: "userInChange",
              width: "24",
            },
            {
              label: "Proceso",
              name: "stepName",
              width: "19",
            },
            {
              label: "Inicio",
              name: "initialDate",
              type: "date",
              width: "13",
            },
            {
              label: "Final",
              name: "finalDate",
              type: "date",
              width: "13",
            },
            {
              label: "Duración",
              name: "durationString",
              width: "31",
            },
          ]}
          data={data.procedureHistories}
          border={{ right: true, bottom: true, left: true }}
        />
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default GenerateRoadmap;
