import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import * as dayjs from "dayjs";
import { roundOut } from "../../utils/validate";

export const ImportClearancePdf = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/Agda/procedure/${
      procedure.id || procedure.procedureId
    }/liquidation`,
  });

  if (!loading) {
    return <Circle />;
  }

  function handleListExpense(data) {
    let expenses = {
      fobUsd: data.fobUsd ? Number(String(data.fobUsd).replace(",", ".")) : 0,
      fletesUsd: data.fletesUsd
        ? Number(String(data.fletesUsd).replace(",", "."))
        : 0,
      seguroUsd: data.seguroUsd ? Number(data.seguroUsd) : 0,
      gastosUsd: data.gastosUsd ? Number(data.gastosUsd) : 0,
      erogacionesUsd: data.erogacionesUsd ? Number(data.erogacionesUsd) : 0,
      cifUsd:
        data && data.cifBs && data.tc
          ? Number(data.cifBs) / Number(data.tc)
          : 0,
      fobBs:
        data?.fobUsd && data.tc
          ? Number(String(data.fobUsd).replace(",", ".")) * Number(data.tc)
          : 0,
      fletesBs:
        data && data.fletesUsd && data.tc
          ? Number(String(data.fletesUsd).replace(",", ".")) * Number(data.tc)
          : 0,
      seguroBs:
        data && data.seguroUsd && data.tc
          ? Number(data.seguroUsd) * Number(data.tc)
          : 0,
      gastosBs:
        data && data.gastosUsd && data.tc
          ? Number(data.gastosUsd) * Number(data.tc)
          : 0,
      erogacionesBs: data?.erogacionesUsd
        ? Number(data.erogacionesUsd) * Number(data.tc)
        : 0,
      cifBs: data?.cifBs ? Number(data.cifBs) : 0,
    };
    expenses = {
      ...expenses,
      totalCostosUsd:
        expenses.fobUsd +
        expenses.fletesUsd +
        expenses.seguroUsd +
        expenses.gastosUsd +
        expenses.erogacionesUsd,
      totalCostosBs:
        expenses.fobBs +
        expenses.fletesBs +
        expenses.seguroBs +
        expenses.gastosBs +
        expenses.erogacionesBs,
    };
    return expenses;
  }

  return (
    <div>
      <RenderImportClearance
        data={data}
        listExpense={handleListExpense(data)}
      />
    </div>
  );
};

const RenderImportClearance = (props) => {
  const { data, listExpense } = props;
  return (
    <div>
      <PDFViewer
        style={{
          width: window.innerHeight > 1400 ? window.innerWidth : 1400,
          height: window.innerHeight - 100,
        }}
      >
        <Document>
          <Page
            size="A4"
            style={{
              padding: "40px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <View flexDirection="column">
              <View style={{ marginBottom: "5px" }}>
                <View width="16%" justifyContent="center" alignItems="center">
                  <Image
                    src="https://res.cloudinary.com/joeldes/image/upload/v1703637339/Agda/logo_nw1jah.png"
                    style={{ height: "40px" }}
                  />
                </View>
                <View
                  flexDirection="column"
                  width="68%"
                  justifyContent="center"
                  alignItems="center"
                  padding="0 10px"
                >
                  <Text
                    textAlign="center"
                    fontSize="10px"
                    padding="2px 3px"
                    bold
                  >
                    LIQUIDACIÓN DE IMPORTACIÓN
                  </Text>
                </View>
                <View width="16%" justifyContent="space-between">
                  <View
                    width="50%"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Text textAlign="center" padding="2px 3px" bold>
                      Página:
                    </Text>
                    <Text textAlign="center" padding="2px 3px" bold>
                      Fecha:
                    </Text>
                    <Text textAlign="center" padding="2px 3px" bold>
                      Hora:
                    </Text>
                  </View>
                  <View
                    width="50%"
                    alignItems="flex-end"
                    flexDirection="column"
                  >
                    <Text
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber} de ${totalPages}`
                      }
                    />
                    <Text textAlign="center" padding="2px 3px">
                      {dayjs().format("DD/MM/YYYY")}
                    </Text>
                    <Text textAlign="center" padding="2px 3px">
                      {dayjs().format("HH:mm:ss")}
                    </Text>
                  </View>
                </View>
              </View>
              <View justifyContent="space-between">
                <View
                  width="13%"
                  alignItems="flex-start"
                  flexDirection="column"
                >
                  <Text textAlign="center" padding="2px 3px" bold>
                    Carpeta:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    DIM/DAM:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    Cliente:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    Referencia:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    Mercadería:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    Aduana:
                  </Text>
                </View>
                <View
                  width="87%"
                  alignItems="flex-start"
                  flexDirection="column"
                >
                  <Text textAlign="center" padding="2px 3px">
                    {data?.carpeta ?? ""}
                  </Text>
                  <View>
                    <Text textAlign="center" padding="2px 3px">
                      {data?.dimDam ?? ""}
                    </Text>
                  </View>
                  <Text textAlign="center" padding="2px 3px">
                    {data?.cliente ?? ""}
                  </Text>
                  <Text textAlign="center" padding="2px 3px">
                    {data?.referenciaCliente ?? ""}
                  </Text>
                  <Text textAlign="center" padding="2px 3px">
                    {data?.descripcionDeLaMercancia ?? ""}
                  </Text>
                  <Text textAlign="center" padding="2px 3px">
                    {data?.aduana ?? ""}
                  </Text>
                </View>
              </View>
              <View
                style={{ margin: "8px 0 4px 0" }}
                border={{ top: true }}
              ></View>
              <View>
                <Text style={{ marginRight: "15px" }} bold>
                  NOTA DE VALOR
                </Text>
                <Text bold>Fecha:</Text>
                <Text style={{ marginRight: "15px" }}>
                  {data?.a2FechaDeAceptacion ? data.a2FechaDeAceptacion : ""}
                </Text>
                <Text bold>T/C:</Text>
                <Text>{data?.tc ? Number(data.tc) : ""}</Text>
              </View>
              <View justifyContent="space-between">
                <View
                  width="48%"
                  alignItems="flex-start"
                  border={{ bottom: true }}
                >
                  <View width="34%">
                    <Text bold>Conceptos</Text>
                  </View>
                  <View width="34%">
                    <Text bold>Importes en US</Text>
                  </View>
                  <View width="34%">
                    <Text bold>Importes en Bs</Text>
                  </View>
                </View>
                <View
                  width="48%"
                  alignItems="flex-start"
                  border={{ bottom: true }}
                >
                  <View width="24%">
                    <Text bold>Impuestos</Text>
                  </View>
                  <View width="39%" justifyContent="flex-end">
                    <Text bold>Paga Agencia</Text>
                  </View>
                  <View width="39%" justifyContent="flex-end">
                    <Text bold>Paga Cliente</Text>
                  </View>
                </View>
              </View>
              <View justifyContent="space-between">
                <View width="48%" alignItems="flex-start">
                  <View width="34%" flexDirection="column">
                    <Text padding="2px 3px" bold>
                      FOB
                    </Text>
                    <Text padding="2px 3px" bold>
                      Fletes
                    </Text>
                    <Text padding="2px 3px" bold>
                      Seguros
                    </Text>
                    <Text padding="2px 3px" bold>
                      Gastos
                    </Text>
                    <Text padding="2px 3px" bold>
                      Erogaciones
                    </Text>
                    <Text padding="2px 3px" bold>
                      Total costos
                    </Text>
                    <Text padding="2px 3px" bold>
                      CIF
                    </Text>
                  </View>
                  <View
                    width="33%"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <Text padding="2px 3px">
                      {listExpense?.fobUsd
                        ? roundOut(listExpense.fobUsd)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.fletesUsd
                        ? roundOut(listExpense.fletesUsd)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.seguroUsd
                        ? roundOut(listExpense.seguroUsd)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.gastosUsd
                        ? roundOut(listExpense.gastosUsd)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.erogacionesUsd
                        ? roundOut(listExpense.erogacionesUsd)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.totalCostosUsd
                        ? roundOut(listExpense.totalCostosUsd)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.cifUsd
                        ? roundOut(listExpense.cifUsd)
                        : "0.00"}
                    </Text>
                  </View>
                  <View
                    width="33%"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <Text padding="2px 3px">
                      {listExpense?.fobBs
                        ? roundOut(listExpense.fobBs)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.fletesBs
                        ? roundOut(listExpense.fletesBs)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.seguroBs
                        ? roundOut(listExpense.seguroBs)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.gastosBs
                        ? roundOut(listExpense.gastosBs)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.erogacionesBs
                        ? roundOut(listExpense.erogacionesBs)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.totalCostosBs
                        ? roundOut(listExpense.totalCostosBs)
                        : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {listExpense?.cifBs
                        ? roundOut(listExpense.cifBs)
                        : "0.00"}
                    </Text>
                  </View>
                </View>
                <View width="48%" alignItems="flex-start">
                  <View width="24%" flexDirection="column">
                    <Text padding="2px 3px">GA</Text>
                    <Text padding="2px 3px">IVA</Text>
                    <View
                      style={{
                        paddingTop: "1px",
                        borderStyle: "dashed",
                        marginVertical: "3px",
                      }}
                      border={{ top: true, bottom: true }}
                    ></View>
                    <Text padding="2px 3px">SubTotal</Text>
                    <Text padding="2px 3px">IG</Text>
                    <Text padding="2px 3px">Servicios</Text>
                    <View
                      style={{
                        paddingTop: "1px",
                        borderStyle: "dashed",
                        marginVertical: "3px",
                      }}
                      border={{ top: true, bottom: true }}
                    ></View>
                    <Text padding="2px 3px">TOTAL</Text>
                  </View>
                  {data?.controlDePagoDeTributos ===
                  "Pagado por la agencia" ? null : (
                    <View
                      width="38%"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <Text padding="2px 3px">0.00</Text>
                      <Text padding="2px 3px">0.00</Text>
                      <View
                        style={{
                          paddingTop: "1px",
                          borderStyle: "dashed",
                          marginVertical: "3px",
                        }}
                        width="100%"
                        border={{ top: true, bottom: true }}
                      ></View>
                      <Text padding="2px 3px">0.00</Text>
                      <Text padding="2px 3px">0.00</Text>
                      <Text padding="2px 3px">0.00</Text>
                      <View
                        style={{
                          paddingTop: "1px",
                          borderStyle: "dashed",
                          marginVertical: "3px",
                        }}
                        width="100%"
                        border={{ top: true, bottom: true }}
                      ></View>
                      <Text padding="2px 3px">0.00</Text>
                    </View>
                  )}
                  <View
                    width="38%"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <Text padding="2px 3px">
                      {data?.ga ? roundOut(Number(data.ga)) : "0.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {data?.iva ? roundOut(Number(data.iva)) : "0.00"}
                    </Text>
                    <View
                      style={{
                        paddingTop: "1px",
                        borderStyle: "dashed",
                        marginVertical: "3px",
                      }}
                      width="100%"
                      border={{ top: true, bottom: true }}
                    ></View>
                    <Text padding="2px 3px">
                      {data?.ga || data?.iva
                        ? roundOut(Number(data.ga) + Number(data.iva))
                        : ""}
                    </Text>
                    <Text padding="2px 3px">
                      {data?.ig ? roundOut(Number(data.ig)) : "100.00"}
                    </Text>
                    <Text padding="2px 3px">
                      {data?.servicios
                        ? roundOut(Number(data.servicios))
                        : "0.00"}
                    </Text>
                    <View
                      style={{
                        paddingTop: "1px",
                        borderStyle: "dashed",
                        marginVertical: "3px",
                      }}
                      width="100%"
                      border={{ top: true, bottom: true }}
                    ></View>
                    <Text padding="2px 3px">
                      {data?.ga || data?.iva
                        ? roundOut(
                            Number(data.ga) +
                              Number(data.iva) +
                              Number(data.ig ?? 100) +
                              Number(data.servicios)
                          )
                        : ""}
                    </Text>
                  </View>
                  {data?.controlDePagoDeTributos === "Pagado por la agencia" ? (
                    <View
                      width="38%"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <Text padding="2px 3px">0.00</Text>
                      <Text padding="2px 3px">0.00</Text>
                      <View
                        style={{
                          paddingTop: "1px",
                          borderStyle: "dashed",
                          marginVertical: "3px",
                        }}
                        width="100%"
                        border={{ top: true, bottom: true }}
                      ></View>
                      <Text padding="2px 3px">0.00</Text>
                      <Text padding="2px 3px">0.00</Text>
                      <Text padding="2px 3px">0.00</Text>
                      <View
                        style={{
                          paddingTop: "1px",
                          borderStyle: "dashed",
                          marginVertical: "3px",
                        }}
                        width="100%"
                        border={{ top: true, bottom: true }}
                      ></View>
                      <Text padding="2px 3px">0.00</Text>
                    </View>
                  ) : null}
                </View>
              </View>
              <View
                style={{ margin: "8px 0 4px 0" }}
                border={{ top: true }}
              ></View>
              <Text bold>ITEMS DE LA LIQUIDACIÓN</Text>
              <CustomTablePDF
                fields={[
                  {
                    label: "Item",
                    name: "num",
                    width: "5",
                    justifyContent: "center",
                  },
                  {
                    label: "Descripción",
                    name: "descripcion",
                    width: "25",
                  },
                  {
                    label: "Partida",
                    name: "partida",
                    width: "10",
                  },
                  {
                    label: "FOB [Us]",
                    name: "fob",
                    width: "12",
                    type: "money",
                    justifyContent: "flex-end",
                  },
                  {
                    label: "CIF [Bs]",
                    name: "cif",
                    width: "12",
                    type: "money",
                    justifyContent: "flex-end",
                  },
                  {
                    label: "GA",
                    name: "ga",
                    width: "12",
                    type: "money",
                    justifyContent: "flex-end",
                  },
                  {
                    label: "IVA",
                    name: "iva",
                    width: "12",
                    type: "money",
                    justifyContent: "flex-end",
                  },
                  {
                    label: "Otros",
                    name: "otros",
                    width: "12",
                    type: "money",
                    justifyContent: "flex-end",
                  },
                ]}
                border={{ top: true, right: true, bottom: true, left: true }}
                data={[
                  ...data.liquidationItems,
                  {
                    num: " ",
                    descripcion: " ",
                    partida: "TOTALES",
                    fob: data.liquidationItems.reduce(
                      (total, payment) =>
                        Number(total) + Number(payment.fob ?? 0),
                      0
                    ),
                    cif: data.liquidationItems.reduce(
                      (total, payment) =>
                        Number(total) + Number(payment.cif ?? 0),
                      0
                    ),
                    ga: data.liquidationItems.reduce(
                      (total, payment) =>
                        Number(total) + Number(payment.ga ?? 0),
                      0
                    ),
                    iva: data.liquidationItems.reduce(
                      (total, payment) =>
                        Number(total) + Number(payment.iva ?? 0),
                      0
                    ),
                    otros: data.liquidationItems.reduce(
                      (total, payment) =>
                        Number(total) + Number(payment.otros ?? 0),
                      0
                    ),
                  },
                ]}
              />
              <View
                style={{ margin: "10px 0 4px 0" }}
                border={{ top: true }}
              ></View>
            </View>
            <View justifyContent="center">
              <Image
                src="https://res.cloudinary.com/joeldes/image/upload/v1704774719/Agda/logoOEA_nceuhb.png"
                style={{ height: "40px", marginRight: "10px" }}
              />
              <View flexDirection="column" alignItems="center">
                <Text color="#00823d">
                  CASA MATRIZ: Calle Loayza N° 273 - Edif. Virgen de Copacabana
                  Piso 5 - La Paz Bolivia
                </Text>
                <Text color="#00823d">
                  Telf. 2202625/2201822 - Fax 2150047 - Email info@agda.com.bo
                </Text>
                <Text color="#00823d">
                  Sucursal Tambo Quemado: Av. Panamericana s/n Cel. 78833940 -
                  Oruro Bolivia
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};
