import * as Yup from "yup";
import FormDefault from "../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import { Anchor, H3 } from "../../components/Text";
import { requestAuth } from "../../components/services/RequestService";

export const ExportProcedureExcel = ({ openDialog, companyId }) => {
  async function onSubmit(values) {
    await requestAuth(
      "get",
      `/Agda/company/${
        values.CompanyId ? `${values.CompanyId}` : ""
      }/detailPicture?${values.FromDate ? `&FromDate=${values.FromDate}` : ""}${
        values.ToDate ? `&ToDate=${values.ToDate}` : ""
      }${
        values.PartidaArancelaria
          ? `&PartidaArancelaria=${values.PartidaArancelaria}`
          : ""
      }`
    )
      .then((res) => {
        toast.success("Registro exitoso");
        viewLinkExcel(res.data);
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            download
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }

  return (
    <div>
      <FormDefault
        tittle="Reporte de carpetas"
        fields={
          companyId
            ? [
                {
                  name: "FromDate",
                  label: "Fecha de inicio",
                  type: "date",
                },
                {
                  name: "ToDate",
                  label: "Fecha fin",
                  type: "date",
                },
                // {
                //   name: "PartidaArancelaria",
                //   label: "Partida arancelaria",
                // },
              ]
            : [
                {
                  label: "Compañia",
                  name: "CompanyId",
                  typeInput: "select",
                  urlApi: "/company/getallcompanies",
                  value: "id",
                  labelOption: "razonSocial",
                },
                {
                  name: "FromDate",
                  label: "Fecha de inicio",
                  type: "date",
                },
                {
                  name: "ToDate",
                  label: "Fecha fin",
                  type: "date",
                },
                // {
                //   name: "PartidaArancelaria",
                //   label: "Partida arancelaria",
                // },
              ]
        }
        initialValues={{
          Management: 2024,
          CompanyId: companyId ?? "",
        }}
        validationSchema={Yup.object().shape({
          Management: Yup.number().required("Campo requerido."),
        })}
        onSubmit={onSubmit}
        buttonName="Generar"
      />
      <br />
    </div>
  );
};
