import * as Yup from 'yup'
import { Request } from '../../hooks/Request'
import FormDefault from '../../components/Forms/FormDefault'
import Loading from '../../components/Animation/Loading'

const ManageInstitution = () => {
  const { data, loading, call } = Request({
    urlApi: `/InstitutionParams`,
  })
  function handleInitial() {
    let initial = {}
    data.forEach((item) => {
      initial = { ...initial, [item.code]: item.value }
    })
    return initial
  }

  if (!loading) {
    return <Loading />
  }
  return (
    <div className="max-w-[800px] mx-auto mt-4 bg-slate-200 p-4 rounded-lg">
      <FormParams
        data={data}
        call={call}
        fields={data.map((value, index) => ({
          label: value.label,
          labelCustom: (
            <>
              <span className="text-green-600">{index + 1}.</span> {value.label}
            </>
          ),
          name: value.code,
          placeholder: value.label,
        }))}
        initialValues={handleInitial()}
      />
    </div>
  )
}

const FormParams = ({ fields, initialValues, data }) => {
  async function onSubmit(values) {
    console.log(values)
    // const result = await Promise.all(
    //   data.map(async (item) => {
    //     if (item.value === values[item.code]) {
    //       const fileContents = await requestAuth(
    //         'post',
    //         '/files',
    //         formData,
    //       ).then((res) => {
    //         return {
    //           Value: res.data.url,
    //           ProcedureId: procedure.id,
    //           FieldId: element.id,
    //           FileId: res.data.id,
    //         }
    //       })
    //       return fileContents
    //     } else {
    //     }
    //   }),
    // )
  }

  return (
    <FormDefault
      tittle="Gestionar parámetros de la institución"
      fields={fields}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({})}
      onSubmit={onSubmit}
      buttonName="Registrar"
    />
  )
}

export default ManageInstitution
