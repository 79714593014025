import Recurso_3logo from "../../../assets/logo45-5.png";
import FormDefault from "../../../components/Forms/FormDefault";
import * as Yup from "yup";
import { request } from "../../../components/services/RequestService";
import { useAuth } from "../../../contexts/AuthContext";
import { validateArray } from "../../../utils/validate";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { H1, H3 } from "../../../components/Text";
import { RequestNoAuth } from "../../../hooks/RequestNoAuth";
import React from "react";
import styled from "styled-components";
import FormLogin from "../../../containers/auth/Formlogin";

const Recover = (props) => {
  const { data, loading } = RequestNoAuth({
    urlApi: "/institutionParams/generalData",
  });

  function handleToJson(list) {
    if (validateArray(list)) {
      let auxJson = {};
      list.forEach((element) => {
        auxJson = { ...auxJson, [element.code]: element.value };
      });
      return auxJson;
    }
    return {};
  }
  if (!loading) {
    return null;
  }
  return (
    <FormLogin
      title="Recuperar Contraseña"
      fields={[
        {
          label: "Nombre de usuario o correo",
          name: "UsernameOrEmail",
          typeInput: "input",
          type: "text",
          placeholder: "Introduzca su nombre de usuario o correo",
          className: `text-[15px] mt-[5px]`,
        },
      ]}
      validationSchema={{
        UsernameOrEmail: Yup.string().required("Este campo es obligatorio"),
      }}
      onSubmit={async (values) => {
        await request("post", "/auth/recoveryAccount", values)
          .then(() => {
            toast.success("Se te envió un correo electrónico");
          })
          .catch((err) => {
            console.log(err);
            toast.error("No se pudo realizar la acción");
          });
      }}
      buttonName="RECUPERAR"
      data={handleToJson(data)}
      redirect={
        <Link to="/auth/login">
          Volver al <span>Inicio de sesión</span>
        </Link>
      }
    />
  );
};
export default Recover;
