import { H3 } from '../Text'
import * as dayjs from 'dayjs'
import { validateArray } from '../../utils/validate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tippy'

const Table = (props) => {
  const { showField, data, header, onClick, edit, call, responsive } = props
  return (
    <>
      <table className="bg-[#f8fafc] w-full border-collapse m-[0px] p-[0px] table-fixed">
        <thead
          className={
            responsive
              ? `bg-[#f1f5f9] rounded-t-lg hidden`
              : `bg-[#f1f5f9] rounded-t-lg portrait:hidden`
          }
        >
          <tr>
            {header.map((value, index) => (
              <th
                key={index}
                className="m-8 text-lg px-8 text-center font-medium py-4 text-[#1d4ed8] border-b-2 border-[#1d4ed8] portrait:font-bold"
              >
                {value.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-center">
          {!data.length > 0 ? (
            <H3 className="p-[20px]">¡ No Existen Datos !</H3>
          ) : (
            data.map((value, index) => (
              <tr
                onClick={() => !edit && onClick(value, call)}
                className="border-blue-200 sm:border-blue-200 border-2 cursor-pointer"
                key={index}
              >
                {header.map((head, key) => (
                  <td
                    onClick={() => onClick(value, call)}
                    data-label={head.label}
                    key={key}
                    className={
                      responsive
                        ? 'text-lg overflow-auto block text-right border-b-2 border-[#e2e8f0] before:content-[attr(data-label)] before:text-[blue] before:font-semibold before:float-left before:bg-[#f1f5f9] before:p-[15px] scroll'
                        : 'text-lg overflow-auto text-center font-normal border-b-2 border-[#e2e8f0]  portrait:block portrait:text-right portrait:before:content-[attr(data-label)] portrait:before:text-[blue] portrait:before:font-semibold portrait:before:float-left portrait:before:bg-[#f1f5f9] portrait:before:p-[15px] scroll'
                    }
                  >
                    {head.status ? (
                      head.icon === 'fa-file-pen' ? (
                        <button
                          onClick={() => {
                            head.action(value)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={`fa-solid ${head.icon}`}
                            className="text-2xl text-[#1d4ed8] py-4 mb-1"
                          />
                        </button>
                      ) : value.name !== 'client' &&
                        value.name !== 'usersAdmin' &&
                        value.name !== 'formsAdmin' ? (
                        <button
                          onClick={() => {
                            head.action(value)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={`fa-solid ${head.icon}`}
                            className="text-2xl text-[#1d4ed8] py-4 mb-1"
                          />
                        </button>
                      ) : null
                    ) : (
                      <DataType
                        showField={showField}
                        value={value[head.name]}
                        data={value}
                        head={head}
                        onClick={onClick}
                        call={call}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  )
}

const DataType = (props) => {
  const { showField, value, head, onClick, call, data } = props

  function validateType() {
    const listData = {
      date: dayjs(value).format('DD/MM/YYYY HH:mm'),
      custom: head.builder ? head.builder(value, head) : null,
      translate: head.translate ? head.translate[value] : '',
      action: validateArray(head.acvaluetions) ? (
        <div className="flex gap-4 justify-center portrvalueait:justify-end">
          {head.actions.map((act, index) => (
            <Tooltip
              key={index}
              title={act.label}
              position="left"
              trigger="mouseenter"
              className="w-full"
            >
              <div
                className={`${act.color} text-xl cursor-pointer transition ease-in-out hover:-translate-x-1 duration-200`}
                onClick={() => act.action(data, call)}
              >
                <i className={`${act.icon}`}></i>
              </div>
            </Tooltip>
          ))}
        </div>
      ) : null,
    }
    return listData[head.type] || value
  }
  return (
    <div
      className="w-full h-full px-[10px] sm:px-6 py-[10px]"
      onClick={() => {
        showField === undefined ? onClick(value, call) : showField(true)
        onClick(value, call)
      }}
    >
      {validateType()}
    </div>
  )
}

export default Table
