import { useState } from 'react'
import { H3 } from '../../components/Text'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UserInformation = (props) => {
  const { user } = useAuth()
  const [dropDown, setDropDown] = useState(false)

  if (user) {
    return (
      <div className="">
        <button
          className="flex items-center gap-2"
          onClick={() => setDropDown(!dropDown)}
        >
          {user.user?.photoUrl === null ? (
            <div className="bg-white w-[40px] h-[40px] text-[1.4rem] flex justify-center items-center rounded-lg">
              <i className="fa-solid fa-user"></i>
            </div>
          ) : (
            <img src={user.user?.photoUrl} alt="" className="w-[25px]" />
          )}
          <H3 className="text-[#F19E4D] font-normal leading-none text-[1.2rem] sm:text-[1.2rem] xl:text-[1.2rem] text-start">
            {user.user?.name}
            <br />
            <span className="text-white">
              {user.user?.fatherLastName} {user.user?.motherLastName}
            </span>
          </H3>
          <FontAwesomeIcon
            icon={`fas fa-angle-${dropDown ? 'up' : 'down'}`}
            className="text-white"
          />
        </button>
        {dropDown && (
          <>
            <div
              onClick={() => setDropDown(!dropDown)}
              className="absolute w-screen h-screen top-0 left-0"
            ></div>
            <div className="absolute z-10 bg-[#978d8d] w-fit right-5 mt-2">
              <Dropdown setDropDown={setDropDown} dropDown={dropDown} />
            </div>
          </>
        )}
      </div>
    )
  } else {
    return null
  }
}

const Dropdown = ({ setDropDown, dropDown }) => {
  const navigate = useNavigate()
  const { setUser } = useAuth()
  return (
    <div className="bg-[white] shadow-2xl border-[#0000002c] border-[1px] shadow-black  flex flex-col items-center rounded-md hover:text-[#ffffff]">
      <button
        className="text-[#000000] text-[1.6rem] font-medium flex flex-row items-center px-2 sm:px-3 hover:bg-[#2F527B] hover:text-[#ffffff]  hover:rounded-md hover:border-[1px] hover:border-white"
        onClick={() => {
          localStorage.removeItem('id')
          localStorage.removeItem('token_seguridad')
          setDropDown(!dropDown)
          setUser()
          navigate('/')
        }}
      >
        <FontAwesomeIcon
          icon="fa-solid fa-arrow-right-from-bracket"
          className="text-[1.4rem] font-bold mr-2 text-[inherit] hover:text-[#ffffff]"
        />
        Cerrar Sesión
      </button>
    </div>
  )
}

export default UserInformation
