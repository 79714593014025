import { Request } from "../../../hooks/Request";
import { Circle } from "../../../components/Animation";
import { Page, Document, PDFViewer, Image, Link } from "@react-pdf/renderer";
import config from "../../../config.json";
import View from "../../../components/GeneratePdf/View";
import Text from "../../../components/GeneratePdf/Text";
import * as dayjs from "dayjs";
import CustomTablePDF from "../../../components/GeneratePdf/CustomTablePDF";

const CommissionPDFClient = (props) => {
  const { company } = props;

  const { data, loading } = Request({
    urlApi: `/company/${company.id}`,
  });
  if (!loading) {
    return <Circle />;
  }
  if (!data) {
    return null;
  }

  return <CommissionPDF company={data} />;
};

const CommissionPDF = (props) => {
  const { company } = props;
  console.log("🚀 ~ CommissionPDF ~ company:", company);
  const { data, loading } = Request({
    urlApi: `${config.requestContaURL}/api/OpclienteCategoriaRango?NuentCode=${company.code}`,
  });
  if (!loading) {
    return <Circle />;
  }
  if (!data) {
    return null;
  }

  console.log("🚀 ~ CommissionPDF ~ data:", data);
  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
      >
        <Document>
          <Page
            size="A4"
            style={{
              padding: "40px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <View flexDirection="column">
              <View style={{ marginBottom: "5px" }}>
                <View width="20%" justifyContent="center" alignItems="center">
                  <Image
                    src="https://res.cloudinary.com/joeldes/image/upload/v1703637339/Agda/logo_nw1jah.png"
                    style={{ height: "40px" }}
                  />
                </View>
                <View
                  flexDirection="column"
                  width="60%"
                  justifyContent="center"
                  alignItems="center"
                  padding="0 10px"
                >
                  <Text
                    textAlign="center"
                    fontSize="10px"
                    padding="2px 3px"
                    bold
                  >
                    INFORMACIÓN DEL CLIENTE
                  </Text>
                </View>
                <View width="20%" justifyContent="space-between">
                  <View
                    width="50%"
                    // justifyContent="flex-start"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Text textAlign="center" padding="2px 3px" bold>
                      Página:
                    </Text>
                    <Text textAlign="center" padding="2px 3px" bold>
                      Fecha:
                    </Text>
                    <Text textAlign="center" padding="2px 3px" bold>
                      Hora:
                    </Text>
                  </View>
                  <View
                    width="50%"
                    // justifyContent="flex-end"
                    alignItems="flex-end"
                    flexDirection="column"
                  >
                    <Text
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber} de ${totalPages}`
                      }
                    />
                    <Text textAlign="center" padding="2px 3px">
                      {dayjs().format("DD/MM/YYYY")}
                    </Text>
                    <Text textAlign="center" padding="2px 3px">
                      {dayjs().format("HH:mm:ss")}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{ margin: "5px 0 4px 0" }}
                border={{ top: true }}
              ></View>
              <View justifyContent="space-between">
                <View
                  width="13%"
                  alignItems="flex-start"
                  flexDirection="column"
                >
                  <Text textAlign="center" padding="2px 3px" bold>
                    Cliente:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    Documento:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    Email:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    Telefono:
                  </Text>
                  <Text textAlign="center" padding="2px 3px" bold>
                    Dirección:
                  </Text>
                </View>
                <View
                  width="87%"
                  alignItems="flex-start"
                  flexDirection="column"
                >
                  <Text textAlign="center" padding="2px 3px">
                    {company?.razonSocial ?? ""}
                  </Text>
                  <View>
                    <Text textAlign="center" padding="2px 3px">
                      {company?.nit ?? ""}
                    </Text>
                  </View>
                  <Text textAlign="center" padding="2px 3px">
                    {company?.email ?? ""}
                  </Text>
                  <Text textAlign="center" padding="2px 3px">
                    {company?.phone ?? ""}
                  </Text>
                  <Text textAlign="center" padding="2px 3px">
                    {company?.direccion ?? ""}
                  </Text>
                </View>
              </View>
              <View
                style={{ margin: "5px 0 5px 0" }}
                border={{ top: true }}
              ></View>
              <Text fontSize="10px" padding="2px 3px" bold>
                Rango de comisiones
              </Text>
              <CustomTablePDF
                fields={[
                  {
                    label: "Item",
                    name: "num",
                    width: "9",
                    type: "index",
                    justifyContent: "center",
                  },
                  {
                    label: "Inicio",
                    name: "opcliCtgRgoIni",
                    type: "money",
                    width: "26",
                    justifyContent: "flex-end",
                  },
                  {
                    label: "Final",
                    name: "opcliCtgRgoFin",
                    width: "26",
                    type: "money",
                    justifyContent: "flex-end",
                  },
                  {
                    label: "Tipo",
                    name: "opcliCtgRgoTpo",
                    width: "13",
                    justifyContent: "center",
                    type: "translate",
                    translate: {
                      P: "Porcentaje",
                      U: "Estático",
                    },
                  },
                  {
                    label: "Importe",
                    name: "opcliCtgRgoImp",
                    width: "26",
                    type: "money",
                    justifyContent: "flex-end",
                  },
                ]}
                border={{ top: true, right: true, bottom: true, left: true }}
                data={data.filter((item) => item.opcliCtgSucCod === 1)}
              />
            </View>
            <View justifyContent="center">
              <Image
                src="https://res.cloudinary.com/joeldes/image/upload/v1704774719/Agda/logoOEA_nceuhb.png"
                style={{ height: "40px", marginRight: "10px" }}
              />
              <View flexDirection="column" alignItems="center">
                <Text color="#00823d">
                  CASA MATRIZ: Calle Loayza N° 273 - Edif. Virgen de Copacabana
                  Piso 5 - La Paz Bolivia
                </Text>
                <Text color="#00823d">
                  Telf. 2202625/2201822 - Fax 2150047 - Email info@agda.com.bo
                </Text>
                <Text color="#00823d">
                  Sucursal Tambo Quemado: Av. Panamericana s/n Cel. 78833940 -
                  Oruro Bolivia
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default CommissionPDFClient;
