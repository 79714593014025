import * as Yup from "yup";
import { toast } from "react-toastify";
import { fileFormInput } from "../../utils/fieldForm";
import { useModal } from "../../contexts/ModalContext";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";

const FieldManager = () => {
  const { openModal, onClose } = useModal();

  function editDropdown(field, reload) {
    openModal(
      <FormEditField field={field} reload={reload} onClose={onClose} />
    );
  }
  return (
    <CallServiceTable
      //   onClick={viewDropdown}
      //   component={({ reload }) => {
      //     return (
      //       <Button onClick={() => createDropdown(reload)}>
      //         Crear procedimiento <i className="fa-solid fa-plus"></i>
      //       </Button>
      //     )
      //   }}
      urlApi="/field"
      header={[
        {
          name: "label",
          label: "Etiqueta",
          filter: true,
        },
        {
          name: "name",
          label: "Nombre",
        },
        {
          name: "type",
          label: "Tipo",
          type: "translate",
          translate: {
            text: "Texto",
            file: "Archivo",
            fileStatus: "Archivo con estado",
            bool: "booleano",
            controlField: "Campo de control",
            completeDocuments: "Completar documentos",
            requestSerialNumber: "Número correlativo",
            fileRead: "Archivo",
            number: "Numero",
            selectGet: "Lista desplegable",
            checkbox: "Casilla de verificación",
            textArea: "Caja de texto multi-línea",
            date: "Fecha",
          },
        },
        {
          name: "step",
          label: "Pasos asignados",
        },
        {
          name: "edit",
          type: "action",
          label: "Editar",
          actions: [
            {
              label: "Editar",
              icon: "fas fa-edit",
              action: editDropdown,
              color: "text-[#1d4ed8]",
            },
          ],
        },
        // {
        //   name: 'delete',
        //   type: 'action',
        //   label: 'Eliminar',
        //   actions: [
        //     {
        //       label: 'Eliminar',
        //       icon: 'fas fa-trash',
        //       action: deleteDropdown,
        //       color: 'text-red-500',
        //     },
        //   ],
        // },
      ]}
    />
  );
};

const FormEditField = (props) => {
  const { field, onClose, reload } = props;
  return (
    <FormDefault
      tittle="Editar datos del campo"
      fields={fileFormInput}
      initialValues={{
        id: field?.id || "",
        Initial: field?.initial || "",
        Label: field?.label || "",
        Name: field?.name || "",
        Type: field?.type || "text",
        Url: field?.url || "",
        Color: field?.color || "",
        Validate: field?.validate || false,
        TableDisplay: field?.tableDisplay,
        Unique: field?.unique || false,
        // ClientDisplay: field?.clientDisplay || false,
        // AllowModification: field?.allowModification || false,
        Format: field?.format || "",
        AutoGenerated: field?.autoGenerated || false,
        lockEdition: field?.lockEdition || false,
      }}
      validationSchema={Yup.object().shape({
        Label: Yup.string().required("Este campo es obligatorio"),
        Type: Yup.string().required("Este campo es obligatorio"),
        Url: Yup.string()
          .when("Type", {
            is: "fileRead",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "selectGet",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "fileExcel",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subItemsConta",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subData",
            then: Yup.string().required("Campo requerido."),
          }),
      })}
      onSubmit={async (values) => {
        await requestAuth(
          "put",
          `field/${values.id}/processStepId/`,
          //   `field/${values.id}/processStepId/${stepId}`,
          values
        )
          .then(() => {
            toast.success("Campo editado correctamente!");
            reload();
            onClose();
          })
          .catch(() => {
            toast.error("Se ha producido un error al enviar");
          });
      }}
      buttonName="Enviar"
    />
  );
};

export default FieldManager;
