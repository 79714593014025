import { useState } from 'react'
import { H2, H3 } from '../../components/Text'
import { Button } from '../../components/Buttons'
import { Navigation } from '../../components/Buttons'
import TableGeneral from '../../components/ExcelPages/TableGeneral'
import  GeneralDataDim  from '../../components/ExcelPages/GeneralDataDim'
import {
  headerProviders,
  headerBills,
  headerItem,
  headerMinimalDescription,
} from '../../utils/constGenerate'

const RenderDIM = (props) => {
  const {
    procedureId,
    exportExcel,
    importExcel,
    typeId,
    rolId,
    handleDelete,
  } = props
  const [reload, setReload] = useState(true)

  function call() {
    setReload(!reload)
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <H2>Llenado de la DIM</H2>
        <div className="flex gap-2 items-center">
          <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Generar Excel"
            onClick={exportExcel}
          >
            <i className="fas fa-cloud-download-alt"></i>
          </Button>
          <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Extraer datos de un excel"
            onClick={() => importExcel(call)}
          >
            <i className="fas fa-clone"></i>
          </Button>
          <Button
            typeBtn="link"
            position="left"
            href={`/rol/${rolId}/procedure-type/${typeId}/step/1/procedure/${procedureId}/generate/dav`}
            className="text-base p-1.5 text-[#287233] bg-[#2872334f]"
            content="Genere, cargue y exporte un documento DAV"
          >
            DAV
          </Button>
          <Button
            typeBtn="link"
            position="left"
            href={`/rol/${rolId}/procedure-type/${typeId}/step/1/procedure/${procedureId}/generate/dam`}
            className="text-base p-1.5 text-[#287233] bg-[#2872334f]"
            content="Genere, cargue y exporte un documento DAM"
          >
            DAM
          </Button>
        </div>
      </div>
      <Navigation
        actDefault={1}
        navigation={[
          {
            id: 1,
            label: 'Datos Generales',
            component: (
              <GeneralDataDim
                urlApi={`/procedure/${procedureId}/sheet/1/dim`}
                reload={call}
              />
            ),
          },
          {
            id: 2,
            label: 'Proveedores',
            component: (
              <TableGeneral
                addBtn="Adicionar proveedor"
                editBtn="Editar proveedor"
                handleReload={reload}
                header={[
                  ...headerProviders,
                  {
                    name: 'action',
                    type: 'action',
                    label: '',
                    hidden: true,
                    actions: [
                      {
                        label: 'Eliminar',
                        icon: 'fas fa-trash',
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            'el proveedor',
                            `/procedureSheet/provider/${item.id}`,
                          ),
                        color: 'text-red-500',
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/2/dim`}
                urlPost={`/procedureSheet/provider/procedure/${procedureId}`}
                urlPut="/procedureSheet/provider/"
                reload={call}
              />
            ),
          },
          { 
            id: 3,
            label: 'Facturas',
            component: (
              <TableGeneral
                addBtn="Adicionar factura"
                editBtn="Editar factura"
                handleReload={reload}
                header={[
                  ...headerBills,
                  {
                    name: 'action',
                    type: 'action',
                    label: '',
                    hidden: true,
                    actions: [
                      {
                        label: 'Eliminar',
                        icon: 'fas fa-trash',
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            'la factura seleccionada',
                            `/procedureSheet/invoice/${item.id}`,
                          ),
                        color: 'text-red-500',
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/3/dim`}
                urlPost={`/procedureSheet/invoice/procedure/${procedureId}`}
                urlPut="/procedureSheet/invoice/"
                reload={call}
              />
            ),
          },
          {
            id: 4,
            label: 'Items',
            component: (
              <TableGeneral
                addBtn="Adicionar item"
                editBtn="Editar item"
                handleReload={reload}
                header={[
                  ...headerItem,
                  {
                    name: 'action',
                    type: 'action',
                    label: '',
                    hidden: true,
                    actions: [
                      {
                        label: 'Eliminar',
                        icon: 'fas fa-trash',
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            'el item',
                            `/procedureSheet/item/${item.id}`,
                          ),
                        color: 'text-red-500',
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/4/dim`}
                urlPost={`/procedureSheet/item/procedure/${procedureId}`}
                urlPut="/procedureSheet/item/"
                reload={call}
              />
            ),
          },
          {
            id: 5,
            label: 'Descripciones Mínimas',
            component: (
              <TableGeneral
                addBtn="Adicionar descripción mínima"
                editBtn="Editar descripción mínima"
                handleReload={reload}
                header={[
                  ...headerMinimalDescription,
                  {
                    name: 'action',
                    type: 'action',
                    label: '',
                    hidden: true,
                    actions: [
                      {
                        label: 'Eliminar',
                        icon: 'fas fa-trash',
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            'la descripción mínima',
                            `/procedureSheet/minimalDescription/${item.id}`,
                          ),
                        color: 'text-red-500',
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/5/dim`}
                urlPost={`/procedureSheet/minimalDescription/procedure/${procedureId}`}
                urlPut="/procedureSheet/minimalDescription/"
                reload={call}
              />
            ),
          },
          {
            id: 6,
            label: 'Series',
            component: (
              <TableGeneral
                addBtn="Adicionar serie"
                editBtn="Editar serie"
                handleReload={reload}
                header={[
                  {
                    name: 'itemNumber',
                    label: 'Numero del item',
                    typeInput: 'select',
                    urlApi: `/procedure/${procedureId}/sheet/4/dim`,
                    labelOption: 'itemNumber',
                    value: 'id',
                  },
                  { name: 'correlativo', label: 'Correlativo' },
                  { name: 'nroDeSerie', label: 'Num. Serie' },
                  { name: 'imei1', label: 'Imei1' },
                  { name: 'imei2', label: 'Imei2' },
                  { name: 'imei3', label: 'Imei3' },
                  { name: 'subpartida', label: 'subpartida' },
                  {
                    name: 'action',
                    type: 'action',
                    label: '',
                    hidden: true,
                    actions: [
                      {
                        label: 'Eliminar',
                        icon: 'fas fa-trash',
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            'la descripción mínima',
                            `/procedureSheet/serie/item/${item.ItemId}`,
                          ),
                        color: 'text-red-500',
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/6/dim`}
                preProcessPost={(values) =>
                  `/procedureSheet/serie/item/${values.itemNumber}`
                }
                reload={call}
              />
            ),
          },
          {
            id: 7,
            label: 'Timbres',
            component: (
              <TableGeneral
                labelBtn="Adicionar timbre"
                handleReload={reload}
                header={[
                  {
                    name: 'itemNumber',
                    label: 'Numero del item',
                    typeInput: 'select',
                    urlApi: `/procedure/${procedureId}/sheet/4/dim`,
                    labelOption: 'itemNumber',
                    value: 'id',
                  },
                  { name: 'subpartidaTimbre', label: 'Subpartida' },
                  { name: 'numeroDeTramite', label: 'Numero de tramite' },
                  { name: 'correlativoInicio', label: 'Correlativo inicio' },
                  { name: 'correlativoFinal', label: 'Correlativo final' },
                  { name: 'cantidadDeTimbres', label: 'Cantidad de timbres' },
                  { name: 'tipoTimbrado', label: 'Tipo timbrado' },
                  {
                    name: 'action',
                    type: 'action',
                    label: '',
                    hidden: true,
                    actions: [
                      {
                        label: 'Eliminar',
                        icon: 'fas fa-trash',
                        action: (item, reload) =>
                          handleDelete(
                            item,
                            reload,
                            'la descripción mínima',
                            `/procedureSheet/timbre/item/${item.ItemId}`,
                          ),
                        color: 'text-red-500',
                      },
                    ],
                  },
                ]}
                urlApi={`/procedure/${procedureId}/sheet/7/dim`}
                preProcessPost={(values) =>
                  `/procedureSheet/timbre/item/${values.itemNumber}`
                }
                reload={call}
              />
            ),
          },
        ]}
      />
    </>
  )
}

export default RenderDIM
