import * as Yup from "yup";
import { toast } from "react-toastify";
import { Anchor } from "../../../components/Text";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";


const ImportClient = (props) => {
  const { reload, onClose } = props;
  function onSubmit(values) {
    let formData = new FormData();
    formData.append("File", values.File);
    formData.append("originalName", values.File.name);
    requestAuth("post", "/Company/reader", formData)
      .then(() => {
        reload();
        onClose();
        toast.success("Datos extraídos");
      })
      .catch(() => {
        toast.error("No se pudo extraer los datos");
      });
  }

    return (
        <div>
          <FormDefault
            tittle="Formulario para importar clientes"
            bonusContent={
              <>
                <span>
                  <Anchor
                    position="left"
                    className="px-1.5 py-1"
                    style={{
                      background: "gray",
                      color: "white",
                      borderRadius: "5px",
                      hover: {
                        color: "red",
                      },
                    }}
                    href="https://res.cloudinary.com/wmcgi/raw/upload/v1678978358/trazo/templates/clientes_activos_nz4ghz.xlsx"
                  >
                    Descargar plantilla
                  </Anchor>
                </span>
                <div
                  style={{
                    color: "gray",
                    padding: "10px",
                    background: "white",
                    borderRadius: "5px",
                    fontSize: "13px",
                    textAlign: "justify",
                  }}
                >
                  <ul style={{ listStyle: "square inside" }}>
                    <b>
                      Pasos para la importación de compañias a través de un archivo
                      excel:
                    </b>
                    <li>
                      Pulsar el botón <b>Descargar plantilla</b>.
                    </li>
                    <li>Llenar la plantilla con los datos de las compañias.</li>
                    <li>
                      Una vez llenada la plantilla excel con los datos necesarios,
                      pulsar el botón <b>Examinar...</b>
                    </li>
                    <li>Seleccionar la plantilla editada.</li>
                    <li>
                      Pulsar el botón <b>Importar</b>.
                    </li>
                  </ul>
                </div>
              </>
            }
            fields={[
              {
                label: "Adjuntar archivo excel",
                name: "File",
                type: "file",
                typeInput: "file",
              },
            ]}
            initialValues={{
              File: null,
            }}
            validationSchema={Yup.object().shape({
              File: Yup.mixed().required("Campo requerido.").nullable(),
            })}
            onSubmit={onSubmit}
            buttonName="Importar"
          />
        </div>
      );
}

export default ImportClient;