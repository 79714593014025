import config from "../config.json";
import { useAuth } from "./AuthContext";
import { requestAuth } from "../components/services/RequestService";
import { useState, createContext, useContext, useEffect } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

const SocketPendingContext = createContext();

export const SocketPendingProvider = ({ children }) => {
  const { user } = useAuth();
  const [connectionPending, setConnectionPending] = useState({
    connect: null,
    connectionId: null,
  });

  useEffect(() => {
    if (user && user !== undefined) {
      handleSocket();
    }
  }, [user]);

  function handleSocket() {
    const connect = new HubConnectionBuilder()
      .withUrl(`${config.requestURL}/notificationHub`)
      .withAutomaticReconnect()
      .build();

    setConnectionPending((a) => ({
      ...a,
      connect: connect,
    }));
  }

  function setConnectionId(connectionId) {
    setConnectionPending((a) => ({ ...a, connectionId: connectionId }));
  }

  return (
    <SocketPendingContext.Provider
      value={{ connectionPending, setConnectionId }}
    >
      {children}
    </SocketPendingContext.Provider>
  );
};

export const useSocketPending = () => useContext(SocketPendingContext);
