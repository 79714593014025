import { useParams } from "react-router-dom";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
// import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import { FillForm } from "../../containers/steps";
import { validateArray } from "../../utils/validate";
import TableLisDocument from "../../components/common/TableLisDocument";

const ProceduresInCharge = () => {
  const { rolId } = useParams();
  const { openModal, onClose } = useModal();
  const { openDialog } = useDialog();
  function handleProcedure(procedure, reloadTable) {
    openModal(
      <FillForm
        procedure={{
          ...procedure,
          // processStepId: rol === "accountant" ? 16 : 5,
          processStepId: 14,
        }}
        reload={reloadTable}
        onClose={onClose}
        openModal={openModal}
        rolId={rolId}
        // step={rol === "accountant" ? "4" : "3"}
        step={11}
        typeId="1"
      />
    );
  }

  // function generateSettlementProforma(procedure) {
  //   openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  // }

  return (
    <CallServiceTable
      urlApi="/procedure/byInCharge"
      addFilters={[
        {
          name: "procedureNumber",
          label: "Tramite",
          filter: true,
        },
        {
          name: "internCode",
          label: "Nro. interno",
          filter: true,
        },
        {
          name: "clientCode",
          label: "Cod. Cliente",
          filter: true,
        },
        {
          name: "dimNumber",
          label: "Nro. DIM/DEX",
          filter: true,
        },
        {
          name: "invoice",
          label: "Factura",
          filter: true,
        },
        {
          name: "docEmbarque",
          label: "Embarque",
          filter: true,
        },
      ]}
      // filters={[
      //   {
      //     name: "companyId",
      //     url: "/company/getallcompanies",
      //     default: "Todas las compañías",
      //     valueOption: "id",
      //     labelOption: "razonSocial",
      //   },
      // ]}
      header={[
        {
          name: "number",
          label: "Trámite",
        },
        {
          name: "internCode",
          label: "Nro. interno",
        },
        {
          name: "nroCliente",
          label: "Cod. Cliente",
        },
        {
          name: "dimNumber",
          label: "Nro. DIM/DEX",
        },
        {
          name: "nroEmbarque",
          label: "Embarque",
        },
        {
          name: "billNumber",
          label: "Factura",
        },
        {
          name: "companyName",
          label: "Cliente",
        },
        {
          name: "customsClearance",
          label: "Aduana",
        },
        {
          name: "canalColor",
          label: "Canal",
        },
        {
          name: "procedureTypeName",
          label: "Patrón",
        },
        {
          name: "creationDate",
          label: "Fecha de inicio",
          type: "date",
        },
        {
          name: "currentStep",
          label: "Progreso",
          type: "custom",
          builder: (value, _, data) => {
            if (data.step > data.totalSteps) {
              return "Terminado";
            }
            return value;
          },
        },
        {
          name: "Action",
          type: "custom",
          label: "Acciones",
          disabledOnClick: true,
          sticky: true,
          builder: (_, __, data, call, buttons) => {
            const listButton = buttons ? JSON.parse(buttons) : [];
            return (
              <div className="flex items-center justify-center gap-1 px-1">
                {/* <div
                  className={`text-red-500 text-xl cursor-pointer`}
                  onClick={() => handleSendProcedure(data, call)}
                >
                  <Tooltip title="Enviar" position="right" trigger="mouseenter">
                    <i className={"fas fa-share-square" + " p-1"}></i>
                  </Tooltip>
                </div> */}
                {listButton && validateArray(listButton) ? (
                  <TableLisDocument listButton={listButton} procedure={data} />
                ) : null}
              </div>
            );
          },
          // actions: [
          //   {
          //     label: 'Enviar',
          //     icon: 'fas fa-share-square',
          //     action: handleSendProcedure,
          //     color: 'text-red-500',
          //   },
          //   ...optionValidate,
          //   {
          //     label: 'Ver documentos',
          //     icon: 'fa-solid fa-folder',
          //     action: handleDocument,
          //     color: 'text-[#1d4ed8]',
          //   },
          //   {
          //     label: 'Verificar documentos',
          //     icon: 'fa-solid fa-list-check',
          //     action: handleDocumentVerify,
          //     color: 'text-[#1d4ed8]',
          //   },
          // ],
        },
      ]}
      addUrl="status=pending"
      onClick={handleProcedure}
      statusPaination={true}
    />
  );
};

export default ProceduresInCharge;
