import { Button } from "../Buttons";
import { H3 } from "../Text";

const Pagination = (props) => {
  const { pag, setPageNumber } = props;

  if (!pag) {
    return null;
  }
  return (
    <div>
      <div className="flex flex-row justify-between rounded-md rounded-t-none mx-auto bg-[#f1f5f9] py-2 gap-4">
        <div className="flex flex-col justify-center ml-2">
          <H3 className="font-normal text-start">
            Total paginas:{" "}
            <strong className="text-[#2563eb]">{pag.totalPages}</strong>
          </H3>
          <H3 className="font-normal text-start">
            Total items:{" "}
            <strong className="text-[#2563eb]">{pag.totalItems}</strong>
          </H3>
        </div>
        <div className="flex flex-row justify-center gap-4">
          <Button
            disabled={pag.currentPage <= 1}
            onClick={() => setPageNumber(1)}
            className="bg-[#dcf9fc] text-[#2563eb]"
          >
            <i className="fas fa-angle-double-left"></i>
          </Button>
          <Button
            disabled={pag.currentPage <= 1}
            onClick={() => setPageNumber((a) => a - 1)}
            className="bg-[#dcf9fc] text-[#2563eb]"
          >
            <i className="fas fa-angle-left"></i>
          </Button>
          <div className="flex flex-row gap-4 items-center portrait:flex-col">
            {pag.currentPage > 2 ? (
              <Button
                onClick={() => setPageNumber((a) => a - 1)}
                className="bg-[#dcf9fc] text-[#2563eb]"
              >
                {pag.currentPage - 2}
              </Button>
            ) : null}
            {pag.currentPage > 1 ? (
              <Button
                onClick={() => setPageNumber((a) => a - 1)}
                className="bg-[#dcf9fc] text-[#2563eb]"
              >
                {pag.currentPage - 1}
              </Button>
            ) : null}
            <Button
              disabled={true}
              className="bg-[#fff] text-[#2563eb] border-2 border-[#2563eb]"
            >
              {pag.currentPage}
            </Button>
            {pag.totalPages > pag.currentPage ? (
              <Button
                onClick={() => setPageNumber((a) => a + 1)}
                className="bg-[#dcf9fc] text-[#2563eb]"
              >
                {pag.currentPage + 1}
              </Button>
            ) : null}
            {pag.totalPages > pag.currentPage + 1 ? (
              <Button
                onClick={() => setPageNumber((a) => a + 2)}
                className="bg-[#dcf9fc] text-[#2563eb]"
              >
                {pag.currentPage + 2}
              </Button>
            ) : null}
          </div>
          <Button
            disabled={pag.currentPage >= pag.totalPages}
            onClick={() => setPageNumber((a) => a + 1)}
            className="bg-[#dcf9fc] text-[#2563eb]"
          >
            <i className="fas fa-angle-right"></i>
          </Button>
          <Button
            disabled={pag.currentPage >= pag.totalPages}
            onClick={() => setPageNumber(pag.totalPages)}
            className="bg-[#dcf9fc] text-[#2563eb]"
          >
            <i className="fas fa-angle-double-right"></i>
          </Button>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};

export default Pagination;
