import { Button } from "../../components/Buttons";
import * as Yup from "yup";
import { useModal } from "../../contexts/ModalContext";
import { H3 } from "../../components/Text";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import { Request } from "../../hooks/Request";
import { useState } from "react";
import { validateArray } from "../../utils/validate";
import PdfTemplate from "../../containers/ManegeProcedure/PdfTemplate";
import { DialogConfirmation } from "../../components/Modal";
import { headerList } from "../../utils/constant";
import * as dayjs from "dayjs";
import PdfDocumentRdrRetorno from "../../containers/ManegeProcedure/PdfDocumentRdrRetorno";
import { Circle } from "../../components/Animation";
import FormDefault from "../../components/Forms/FormDefault";
import PdfDocumentRdr from "../../containers/ManegeProcedure/PdfDocumentRdr";

const ManageArchive = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function editLot(lot, reload) {
    openModal(<FormEditLot lot={lot} reload={reload} onClose={onClose} />);
  }

  function changeStateRecibido(lot, reload) {
    const formData = {
      ...lot,
      status: "Recibido",
    };

    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quiere recibir el lote?</H3>}
        onClose={dialogClose}
        method="put"
        data={formData}
        url={`/lot`}
        texBtn="Confirmar"
        handleSuccess={reload}
      />
    );
  }

  function changeStateConcluir(lot, reload) {
    const formData = {
      ...lot,
      status: "Concluido",
      num: lot.num,
      id: lot.id,
      userId: lot.userId,
    };

    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quiere concluir el lote?</H3>}
        onClose={dialogClose}
        method="put"
        data={formData}
        url={`/lot`}
        texBtn="Confirmar"
        handleSuccess={reload}
      />
    );
  }

  function createLot(reload) {
    openModal(<FormLot reload={reload} onClose={onClose} />);
  }

  function handleDocumentRdrPdf(lot) {
    openDialog(<PdfDocumentRdr lot={lot} />);
  }

  return (
    <>
      <CallServiceTable
        urlApi="/lot/pag"
        component={({ reload }) => {
          return (
            <Button className="py-2" onClick={() => createLot(reload)}>
              Generar reporte <i className="fas fa-file-pdf"></i>
              {/* <i className="fa-solid fa-plus"></i> */}
            </Button>
          );
        }}
        addUrl="order=Entregado-Recibido-Concluido&type=Gestor"
        filters={[
          {
            name: "Status",
            label: "Todos los estados",
            default: "Todos los estados",
            options: [
              {
                label: "Entregado",
                name: "Entregado",
              },
              {
                label: "Recibido",
                name: "Recibido",
              },
              {
                label: "Concluido",
                name: "Concluido",
              },
            ],
          },
          {
            name: "userId",
            url: "/Dropdown/rol_gestor/options",
            label: "Todos los gestores",
            valueOption: "id",
            labelOption: "name",
          },
        ]}
        header={[
          {
            label: "Nro de lote",
            name: "num",
            filter: true,
          },
          {
            label: "Fecha de Entrega",
            name: "deliveryDate",
            type: "date",
          },
          {
            label: "Estado",
            name: "status",
            type: "custom",
            builder: (status) => {
              const baground =
                status === "Entregado"
                  ? "bg-green-600"
                  : status === "Concluido"
                  ? "bg-gray-500"
                  : status === "Recibido"
                  ? "bg-yellow-500"
                  : "bg-blue-400";

              const state =
                status === "Entregado"
                  ? "Entregado"
                  : status === "Concluido"
                  ? "Concluido"
                  : status === "Recibido"
                  ? "Recibido"
                  : "Creado";

              return (
                <span
                  className={`block w-fit px-2 py-1 rounded-full text-white ${baground}`}
                >
                  {state}
                </span>
              );
            },
          },
          {
            label: "Gestor",
            name: "userFullName",
            filter: true,
          },
          {
            name: "lotProcedures",
            label: "Carpetas",
            type: "custom",
            builder: (data, sd, lot) => {
              return (
                <div className="">
                  {data && validateArray(data)
                    ? data.map((item) => (
                        <div key={item.id} className="flex flex-wrap">
                          <div className="flex ">
                            <p
                              className={`border-2 border-gray-500 ${
                                item.estadoDeLaCarpeta === "Sub. para Archivo"
                                  ? "bg-teal-600 text-white"
                                  : item.estadoDeLaCarpeta ===
                                    "Obs. por Archivo"
                                  ? "bg-red-600 text-white animate-pulse"
                                  : ""
                              } rounded-md px-1`}
                            >
                              {item.nroInterno || "---"}
                            </p>
                            {item.estadoDeLaCarpeta === "Obs. por Archivo" ? (
                              <span className="text-red-500 whitespace-nowrap ml-1">
                                Hace{" "}
                                {dayjs().diff(
                                  dayjs(item.obsCreationDate),
                                  "day"
                                ) !== 0
                                  ? dayjs().diff(
                                      dayjs(item.obsCreationDate),
                                      "day"
                                    ) + " días"
                                  : dayjs().diff(
                                      dayjs(item.obsCreationDate),
                                      "hour"
                                    ) !== 0
                                  ? dayjs().diff(
                                      dayjs(item.obsCreationDate),
                                      "hour"
                                    ) + " horas"
                                  : dayjs().diff(
                                      dayjs(item.obsCreationDate),
                                      "minute"
                                    ) + " minutos"}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              );
            },
          },
          {
            name: "id",
            label: "Recibir",
            type: "custom",
            builder: (_, __, lot, call) => {
              return lot.status === "Entregado" ? (
                <div className="flex justify-center">
                  <button
                    className={`block p-2 text-white rounded-lg bg-yellow-500 hover:bg-yellow-600`}
                    onClick={() => changeStateRecibido(lot, call)}
                  >
                    Recibir
                  </button>
                </div>
              ) : (
                <div className="bg-gray-100 border border-gray-500 p-1 px-2">
                  <p className="text-center">Recibido</p>
                  <p className="text-center">
                    {dayjs(lot.receiptDate).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
              );
            },
          },
          {
            name: "id",
            label: "Concluir",
            type: "custom",
            builder: (_, __, lot, call) => {
              return lot.status === "Concluido" ? (
                <div className="bg-gray-100 border border-gray-500 p-1 px-2">
                  <p className="text-center">Concluido</p>
                  <p className="text-center">
                    {dayjs(lot.endDate).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
              ) : lot.status === "Recibido" ? (
                <div className="flex justify-center">
                  <button
                    className={`block p-2 text-white rounded-lg bg-gray-500 hover:bg-gray-600`}
                    onClick={() => changeStateConcluir(lot, call)}
                  >
                    Concluir
                  </button>
                </div>
              ) : (
                " "
              );
            },
          },
          {
            name: "edit",
            type: "action",
            label: "Acciones",
            actions: [
              {
                label: "Revisar Carpetas",
                icon: "fas fa-eye",
                action: editLot,
                color: "text-[#585858]",
              },
              {
                label: "Generar RDR-003",
                icon: "fas fa-file-pdf",
                action: handleDocumentRdrPdf,
                color: "text-[#FF8257]",
              },
            ],
          },
        ]}
      />
    </>
  );
};

const FormLot = (props) => {
  const [procedureSelected, setProcedureSelected] = useState([]);
  const { openDialog, dialogClose } = useDialog();

  function handleDeleteItem(id) {
    setProcedureSelected(
      procedureSelected.filter((procedure) => procedure.id !== id)
    );
  }

  async function addLots() {
    const formData = {
      procedureIds: procedureSelected.map((p) => p.id),
      type: "Gestores",
      forUser: procedureSelected[0].asignGestor,
    };

    await requestAuth("post", "/lot", formData)
      .then((res) => {
        // reload();
        setProcedureSelected([]);
        handleDocumentRdrPdf(res.data);
        toast.success("Se creo con éxito");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  async function addLotsSolicitud() {
    openDialog(
      <div className="h-[300px] flex items-center justify-center">
        <FormDefault
          tittle="Nombre del usuario"
          fields={[
            {
              label: "Nombre del usuario",
              name: "gestor",
              typeInput: "select",
              urlApi: "/Users/all",
              value: "fullName",
              labelOption: "fullName",
            },
          ]}
          initialValues={{
            gestor: "",
          }}
          validationSchema={Yup.object().shape({
            gestor: Yup.string().required("Campo requerido"),
          })}
          onSubmit={(values) => addSolicitud({ ...values })}
          buttonName="Generar RDR-003"
        />
        <br />
      </div>
    );
  }

  async function addSolicitud(user) {
    const formData = {
      procedureIds: procedureSelected.map((p) => p.id),
      type: "Solicitud",
      forUser: user.gestor,
    };

    await requestAuth("post", "/lot", formData)
      .then((res) => {
        // reload();
        setProcedureSelected([]);
        handleDocumentRdrPdf(res.data);
        toast.success("Se creo con éxito");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  function openLote() {
    openDialog(
      <SelectProcedureDialog
        procedureSelected={procedureSelected}
        setProcedureSelected={setProcedureSelected}
      />
    );
  }

  function handleDocumentRdrPdf(lot) {
    openDialog(<PdfDocumentRdrRetorno lot={lot} />);
  }

  function deleteLot(lot, reload) {
    // if(lot.status!=="Creado"){
    //   toast.warning("No se puede eliminar el lote por que ya fue entregado");
    //   return;
    // }
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el lote?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Lot/${lot.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }

  return (
    <>
      <div className="flex gap-5 justify-between text-center mt-2">
        <h2 className="text-2xl font-bold">Lista de Archivos</h2>
        <Button className="py-2" onClick={() => openLote()}>
          Seleccionar Archivos <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <div className="m-5 ">
        {validateArray(procedureSelected) ? (
          procedureSelected.map((procedure) => (
            <div
              key={procedure.id}
              className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between"
            >
              <div>
                <p>
                  <span className="font-semibold">Nro. Interno: </span>
                  {procedure.internCode}
                </p>
                <p>
                  <span className="font-semibold">Cliente: </span>
                  {procedure.companyName}
                </p>
                <p>
                  <span className="font-semibold">Gestor asignado: </span>
                  {procedure.asignGestor}
                </p>
              </div>
              <button onClick={() => handleDeleteItem(procedure.id)}>
                <i className="fas fa-trash text-[#ff2d55]"></i>
              </button>
            </div>
          ))
        ) : (
          <p className="text-center font-semibold text-red-400">
            No se selecciono ninguna carpeta
          </p>
        )}
      </div>
      <div className="flex gap-2">
        <Button
          className="py-2 w-full"
          disabled={procedureSelected.length === 0}
          onClick={() => addLotsSolicitud()}
        >
          Reporte a solicitud
        </Button>
        <Button
          className="py-2 w-full"
          disabled={procedureSelected.length === 0}
          onClick={() => addLots()}
        >
          Reporte para gestores
        </Button>
      </div>
      <div className="mt-5">
        <CallServiceTable
          urlApi="/lot/pag"
          // addUrl="type=Gestores"
          // component={({ reload }) => {
          //   return (
          //     <Button className="py-2" onClick={() => createLot(reload)}>
          //       Crear Lote <i className="fa-solid fa-plus"></i>
          //     </Button>
          //   );
          // }}
          filters={[
            {
              name: "type",
              label: "Todos los estados",
              default: "Todos los estados",
              options: [
                {
                  label: "Para gestores",
                  name: "Gestores",
                  initial: true,
                },
                {
                  label: "A solicitud",
                  name: "Solicitud",
                },
              ],
            },
          ]}
          header={[
            {
              label: "Nro",
              name: "num",
              filter: true,
            },
            {
              label: "Tipo",
              name: "type",
            },
            {
              label: "Fecha",
              name: "creationDate",
              type: "date",
            },
            {
              label: "Para",
              name: "forUser",
            },
            // {
            //   label: "Apellido del Usuario",
            //   name: "fatherLastNameUser",
            // },
            {
              name: "lotProcedures",
              label: "Carpetas",
              type: "custom",
              builder: (data, sd, lot) => {
                return (
                  <div className="">
                    {data && validateArray(data)
                      ? data.map((item) => (
                          <div key={item.id} className="flex flex-wrap">
                            <p
                              className={`border-2 border-gray-500 ${
                                item.status === "Observado"
                                  ? "bg-orange-400"
                                  : item.status === "Retornado"
                                  ? "bg-purple-300"
                                  : item.status === "Corregido" &&
                                    lot.status === "Corregido"
                                  ? "bg-teal-400"
                                  : item.status === "ObservadoArch"
                                  ? "bg-red-300"
                                  : ""
                              } rounded-md px-1`}
                            >
                              {item.nroInterno || "---"}
                            </p>
                          </div>
                        ))
                      : null}
                  </div>
                );
              },
            },
            {
              name: "edit",
              type: "action",
              label: "Reportes",
              actions: [
                {
                  label: "Generar RDR-003",
                  icon: "fas fa-file-pdf",
                  action: handleDocumentRdrPdf,
                  color: "text-[#FF8257]",
                },
                {
                  label: "Eliminar",
                  icon: "fas fa-trash",
                  action: deleteLot,
                  color: "text-red-500",
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
};

const FormEditLot = (props) => {
  const { lot, onClose, reload } = props;

  const { data } = Request({
    urlApi: `/lot/${lot.id}/lotProcedures`,
  });

  return (
    <>
      <div className="flex gap-5 justify-center">
        <h2 className="text-2xl font-bold">Lista de Carpetas</h2>
      </div>
      <div className="my-10 mx-5">
        {data?.map((procedure) => (
          <Procedure
            key={procedure.id}
            reload={reload}
            procedure={procedure}
            lot={lot}
          />
        ))}
      </div>

      <Button className="py-2 block mx-auto" onClick={() => onClose()}>
        Cerrar
      </Button>
    </>
  );
};

const Procedure = ({ procedure, reload, lot }) => {
  const { data, loading } = Request({
    urlApi: `/Procedure/${procedure.id}/datasets?fieldnames=observacionesDeLasCarpeta&fieldnames=estadoDeLaCarpeta`,
  });

  if (!loading) {
    return <Circle />;
  }

  return (
    <div className="bg-white rounded-md px-5 py-2 mb-2">
      <Observations
        fields={data}
        procedure={procedure}
        reload={reload}
        lot={lot}
      />
    </div>
  );
};

const Observations = ({ fields, procedure, reload, lot }) => {
  const { data, call, loading } = Request({
    urlApi: `/dataSet/procedure/${procedure.procedureId}/field/${fields[0].id}/subdata`,
  });

  const [state, setState] = useState(procedure.estadoDeLaCarpeta);

  const { openDialog, dialogClose } = useDialog();

  if (!loading) {
    return <Circle />;
  }

  const obsArchivo = data.filter(
    (item) => item.label === "Observaciones de Archivo"
  );
  const obsGestor = data.filter(
    (item) => item.label === "Observaciones del Gestor"
  );

  if (state === "verificar") {
    changeStateSubsanado();
  }

  async function changeStateSubsanado() {
    if (obsArchivo?.some((item) => item.description4 === "Observado")) {
      const formDataState = [
        {
          value: "Obs. por Archivo",
          procedureId: procedure.procedureId,
          fieldId: fields[1].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    } else if (obsArchivo?.some((item) => item.description4 === "Subsanado")) {
      const formDataState = [
        {
          value: "Sub. para Archivo",
          procedureId: procedure.procedureId,
          fieldId: fields[1].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    } else if (obsGestor?.some((item) => item.description4 === "Observado")) {
      const formDataState = [
        {
          value: "Observado",
          procedureId: procedure.procedureId,
          fieldId: fields[1].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    } else if (obsGestor?.some((item) => item.description4 === "Subsanado")) {
      const formDataState = [
        {
          value: "Subsanado",
          procedureId: procedure.procedureId,
          fieldId: fields[1].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    } else {
      const formDataState = [
        {
          value: null,
          procedureId: procedure.procedureId,
          fieldId: fields[1].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    }
  }

  function addObservation(obs) {
    openDialog(
      <InputObservation
        dialogClose={dialogClose}
        reload={reload}
        obs={obs}
        procedureId={procedure.procedureId}
        fieldIdState={fields[1].id}
        fieldId={fields[0].id}
        call={call}
      />
    );
  }

  function deleteObservation(obs) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Esta seguro de eliminar la observación?</H3>}
        onClose={dialogClose}
        method="DELETE"
        url={`/dataSet/procedure/subData/${obs.id}`}
        texBtn="Eliminar"
        handleSuccess={refresh}
      />
    );
  }

  function handleTemplatePdf(obs) {
    openDialog(<PdfTemplate procedure={procedure} obs={obs} lot={lot} />);
  }

  async function refresh() {
    reload();
    call();
    setState("verificar");
    call();
  }

  return (
    <div className="">
      <div className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between items-center">
        <div>
          <p>
            <span className="font-bold">Nro. Interno: </span>
            {procedure.nroInterno}
          </p>
          <p>
            <span className="font-bold">Cliente: </span>
            {procedure.companyRazonSocial || "---"}
          </p>
        </div>
        {lot.status === "Recibido" ? (
          <div>
            <button
              onClick={() => addObservation()}
              className={`block bg-green-700 text-white text-sm rounded-full px-2 py-1 hover:bg-[#629947]`}
            >
              <i className="fas fa-pen text-white mr-2"></i>
              Añadir obsevación
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      {obsArchivo?.map((obs) =>
        obs.description4 === "Observado" ? (
          <div key={obs.id} className="bg-slate-200 rounded-md px-5 py-2 mb-2">
            <div className="flex gap-2 justify-between items-center">
              <div className="text-xs text-slate-500">
                <p>Observado por: {obs.description3}</p>
                <p>
                  En fecha: {dayjs(obs.creationDate).format("DD/MM/YYYY HH:mm")}
                </p>
              </div>
              <div>
                <p className="text-red-500 text-center font-bold">Observado</p>
                <div className="flex justify-center gap-1">
                  <button
                    onClick={() => addObservation(obs)}
                    className="block bg-[#F3B303] hover:bg-[#ECC759] text-white text-sm rounded-full px-2 py-1"
                  >
                    <i className="fas fa-pencil text-white mr-2"></i>
                    Editar
                  </button>
                  <button
                    onClick={() => handleTemplatePdf(obs)}
                    className="block bg-orange-500 hover:bg-orange-600 text-white text-sm rounded-full px-2 py-1"
                  >
                    <i className="fas fa-file-pdf text-white mr-2"></i>
                    Imprimir
                  </button>
                  <button
                    onClick={() => deleteObservation(obs)}
                    className="block bg-red-500 hover:bg-red-600 text-white text-sm rounded-full px-2 py-1"
                  >
                    <i className="fas fa-trash text-white mr-2"></i>
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
            <div className="p-5 bg-red-300 border border-red-600 rounded-lg mt-5">
              <p>{obs.description}</p>
            </div>
          </div>
        ) : (
          <div key={obs.id} className="bg-slate-200 rounded-md px-5 py-2 mb-2">
            <div className="flex gap-2 justify-between items-center">
              <div className="text-xs text-slate-500">
                <p>Observado por: {obs.description3}</p>
                <p>
                  En fecha: {dayjs(obs.creationDate).format("DD/MM/YYYY HH:mm")}
                </p>
              </div>
              <div>
                <p className="text-teal-500 text-center font-bold">Subsanado</p>
                <div className="flex justify-center gap-1">
                  <button
                    onClick={() => handleTemplatePdf(obs)}
                    className="block bg-teal-500 hover:bg-teal-600 text-white text-sm rounded-full px-2 py-1"
                  >
                    <i className="fas fa-file-pdf text-white mr-2"></i>
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <div className="px-5 pt-5 pb-2 bg-teal-300 border border-teal-600 rounded-lg mt-5">
              <p>{obs.description}</p>
              <div className="mt-2 flex justify-end">
                <div className="text-xs text-slate-500">
                  <p>Subsanado por: {obs.description2}</p>
                  <p>
                    En fecha: {dayjs(obs.endDate).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

const InputObservation = ({
  dialogClose,
  procedureId,
  fieldId,
  fieldIdState,
  call,
  obs,
  reload,
}) => {
  const [inputValue, setInputValue] = useState(obs?.description || "");

  const inputChange = ({ target }) => {
    setInputValue(target.value);
  };

  async function addObservation() {
    if (inputValue.trim().length === 0) return;

    if (obs) {
      const formData = {
        ...obs,
        label: "Observaciones de Archivo",
        description: inputValue.trim(),
      };

      await requestAuth("PUT", `/dataSet/procedure/subdata/${obs.id}`, formData)
        .then((res) => {
          toast.success("Se guardo la observación");
          dialogClose();
          reload();
          call();
        })
        .catch(() => {
          toast.error("Se produjo un error al actualizar la observación");
        });
    } else {
      const formData = {
        label: "Observaciones de Archivo",
        description: inputValue.trim(),
      };

      await requestAuth(
        "POST",
        `/dataSet/procedure/${procedureId}/field/${fieldId}/subdata`,
        formData
      )
        .then((res) => {
          // toast.success("Se guardo la observación");
          // dialogClose();
          // call();
        })
        .catch(() => {
          toast.error("Se produjo un error al añadir observación");
        });

      const formDataState = [
        {
          value: "Obs. por Archivo",
          procedureId: procedureId,
          fieldId: fieldIdState,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          toast.success("Se guardo la observación");
          dialogClose();
          call();
          reload();
        })
        .catch(() => {
          toast.error("Se produjo un error al añadir observación");
        });
    }
  }

  return (
    <>
      <h4 className="text-center text-xl font-bold">Observaciones</h4>
      <input
        type="text"
        className="w-full bg-white border border-gray-300 px-3 py-2 rounded-md outline-none caret-blue-600 focus:border-blue-600 my-3"
        placeholder="Sin observaciones"
        value={inputValue}
        onChange={inputChange}
      />
      <div className="w-96 flex gap-4 m-4">
        <>
          <Button
            onClick={() => dialogClose()}
            className="w-full bg-[#fff] text-[#E7641D] border-2 border-[#E7641D]"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => addObservation()}
            className="w-full bg-[#E7641D]"
          >
            Guardar
          </Button>
        </>
      </div>
    </>
  );
};

const SelectProcedureDialog = ({ procedureSelected, setProcedureSelected }) => {
  const [proceduresList, setProceduresList] = useState(procedureSelected);

  function handleAdd(procedure, reload) {
    // if (procedure.estadoDeLaCarpeta === "Observado"){
    //   toast.warning("El archivo tiene observaciones por subsanar");
    //   return;
    // }
    // if (procedure.estadoDeLaCarpeta === "Obs. por Archivo"){
    //   toast.warning("La carpeta aun tiene observaciones");
    //   return;
    // }
    // if (procedure.estadoDeLaCarpeta === "Subsanado" || procedure.estadoDeLaCarpeta===null){
    //   toast.warning("La carpeta aun no fue entregada");
    //   return;
    // }
    if (proceduresList.find((p) => p.id === procedure.id)) {
      toast.warning("El archivo ya existe en el lote");
      return;
    }
    setProceduresList([...proceduresList, procedure]);
    setProcedureSelected([...proceduresList, procedure]);
  }

  return (
    <div className="w-[70vw]">
      <H3 className="mb-2">
        Carpetas seleccionadas:{" "}
        {proceduresList.map((item) => (
          <span key={item.id} className="bg-red-300 mx-1 px-2 rounded-lg">
            {item.internCode}
          </span>
        ))}
      </H3>
      <CallServiceTable
        filters={[
          {
            name: "procedureType",
            url: "/procedureType",
            default: "Todos los tramites",
            valueOption: "id",
            labelOption: "name",
          },
          {
            name: "companyId",
            url: "/company/getallcompanies",
            default: "Todas las compañías",
            valueOption: "id",
            labelOption: "razonSocial",
          },
          {
            name: "Year",
            default: "Todas las gestiones",
            options: [
              {
                label: "2025",
                name: "2025",
                initial: true,
              },
              {
                label: "2024",
                name: "2024",
              },
              {
                label: "2023",
                name: "2023",
              },
              {
                label: "2022",
                name: "2022",
              },
              {
                label: "2021",
                name: "2021",
              },
              {
                label: "2020",
                name: "2020",
              },
              {
                label: "2019",
                name: "2019",
              },
              {
                label: "2018",
                name: "2018",
              },
              {
                label: "2017",
                name: "2017",
              },
              {
                label: "2016",
                name: "2016",
              },
              {
                label: "2015",
                name: "2015",
              },
            ],
          },
          // {
          //   name: "gestor",
          //   url: "/users/ListUsers?PageNumber=1&userId=1",
          //   label: "Todos los gestores",
          //   valueOption: "name",
          //   labelOption: "name",
          // },
          {
            name: "gestor",
            url: "/Dropdown/rol_gestor/options",
            label: "Todos los gestores",
            valueOption: "name",
            labelOption: "name",
          },
          {
            name: "EstadoDeLaCarpeta",
            default: "Todas las carpetas",
            label: "Todas las carpetas",
            options: [
              {
                label: "Observado",
                name: "Observado",
              },
              {
                label: "Subsanado",
                name: "Subsanado",
              },
              {
                label: "Obs. por Archivo",
                name: "Obs. por Archivo",
              },
              {
                label: "Sub. para Archivo",
                name: "Sub. para Archivo",
              },
              {
                label: "Entregado",
                name: "Entregado",
              },
            ],
          },
        ]}
        urlApi="/procedure/openprocedures"
        addFilters={[
          {
            name: "internCode",
            label: "Nro. interno",
            filter: true,
          },
          {
            name: "dimNumber",
            label: "Nro. DIM/DEX",
            filter: true,
          },
          {
            name: "númeroDePedido",
            label: "Nro. de Pedido",
            filter: true,
          },
        ]}
        header={[
          {
            label: "Estado",
            name: "estadoDeLaCarpeta",
            type: "custom",
            builder: (state, a, pro) => {
              if (state === null) {
                return (
                  <div className="relative inline-block">
                    <span className="block w-fit px-2 py-1 rounded-full text-white bg-blue-800">
                      Creado
                    </span>
                  </div>
                );
              }

              const background =
                state === "Observado"
                  ? "bg-orange-600 animate-pulse"
                  : state === "Subsanado"
                  ? "bg-emerald-500"
                  : state === "Obs. por Archivo"
                  ? "bg-red-600 animate-pulse"
                  : state === "Sub. para Archivo"
                  ? "bg-teal-600"
                  : state === "Entregado"
                  ? "bg-green-600"
                  : "";

              return (
                <div className="relative inline-block">
                  <span
                    className={`block w-fit px-2 py-1 whitespace-nowrap rounded-full text-white ${background}`}
                  >
                    {state}
                  </span>
                  {state === "Observado" || state === "Obs. por Archivo" ? (
                    <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 border border-red-600 bg-white text-red-600 text-xs font-bold rounded-full px-1 flex items-center justify-center ">
                      {dayjs().diff(dayjs(pro.obsCreationDate), "day") !== 0
                        ? dayjs().diff(dayjs(pro.obsCreationDate), "day") +
                          " días"
                        : dayjs().diff(dayjs(pro.obsCreationDate), "hour") !== 0
                        ? dayjs().diff(dayjs(pro.obsCreationDate), "hour") +
                          " horas"
                        : dayjs().diff(dayjs(pro.obsCreationDate), "minute") +
                          " minutos"}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
          {
            label: "Gestor Asignado",
            name: "asignGestor",
          },
          ...headerList,

          {
            name: "manage",
            type: "action",
            label: "Gestionar",
            sticky: true,
            actions: [
              {
                label: "Agregar",
                icon: "fas fa-plus",
                action: handleAdd,
                color: "text-[#646464]",
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default ManageArchive;
