import { H3, H2, Anchor } from "../../components/Text";
import { Circle } from "../../components/Animation";
import { Button } from "../../components/Buttons";
import TableComplete from "../../components/Tables/TableComplete";
import { DialogConfirmation } from "../../components/Modal";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import { Request } from "../../hooks/Request";

const ManageAppointments = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function createCite(reload) {
    openModal(<FormCite reload={reload} onClose={onClose} />);
  }

  function editCite(cite, reload) {
    openModal(<FormCite cite={cite} reload={reload} onClose={onClose} />);
  }

  function deleteCite(cite, reload) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el cite?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Cite/${cite.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }

  function viewAppointment(cite) {
    openModal(<ViewAppointment cite={cite} onClose={onClose} />);
  }

  return (
    <>
      <CallServiceTable
        onClick={viewAppointment}
        component={({ reload }) => {
          return (
            <Button className="py-2" onClick={() => createCite(reload)}>
              Crear cite <i className="fa-solid fa-plus"></i>
            </Button>
          );
        }}
        filters={[
          {
            name: "type",
            label: "Todos los tipos",
            default: "Todos los tipos",
            options: [
              {
                label: "Solicitud",
                name: "Solicitud",
              },
              {
                label: "Aclaraciones",
                name: "Aclaraciones",
              },
              {
                label: "Autorizaciones",
                name: "Autorizaciones",
              },
              {
                label: "Correcciones",
                name: "Correcciones",
              },
              {
                label: "Remisiones",
                name: "Remisiones",
              },
              {
                label: "Respuestas acta de reconocimiento",
                name: "Respuestas acta de reconocimiento",
              },
              {
                label: "Respuestas orden de control diferido",
                name: "Respuestas orden de control diferido",
              },
              {
                label: "Respuestas vista de cargo",
                name: "Respuestas vista de cargo",
              },
            ],
          },
          {
            name: "receiver",
            url: "/Dropdown/recipients/options",
            label: "Todos los destinatarios",
            valueOption: "name",
            labelOption: "name",
          },
          {
            name: "state",
            label: "Todos los estados",
            default: "Todos los estados",
            options: [
              {
                label: "Emitido",
                name: "Emitido",
              },
              {
                label: "Recepcionado",
                name: "Recepcionado",
              },
            ],
          },
        ]}
        urlApi="/Cite"
        header={[
          {
            label: "Nro de cite",
            name: "citeNumber",
            filter: true,
          },
          {
            label: "Cliente",
            name: "type",
          },
          {
            label: "Asunto o referencia",
            name: "subject",
            filter: true,
          },
          {
            label: "Destinatario",
            name: "receiver",
          },
          {
            label: "Asignado a ...",
            name: "entity",
          },
          // {
          //   label: "Posición",
          //   name: "position",
          // },
          // {
          //   label: "Estado",
          //   name: "state",
          // },
          {
            name: "edit",
            type: "action",
            label: "Editar",
            actions: [
              {
                label: "Editar",
                icon: "fas fa-edit",
                action: editCite,
                color: "text-[#1d4ed8]",
              },
            ],
          },
          {
            name: "delete",
            type: "action",
            label: "Eliminar",
            actions: [
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: deleteCite,
                color: "text-red-500",
              },
            ],
          },
        ]}
      />
    </>
  );
};

const FormCite = (props) => {
  const { cite, onClose, reload } = props;

  async function handleCreateOption(value, dropdown) {
    await requestAuth("post", "/dropdown/option", {
      Name: value,
      DropdownListLabel: dropdown,
    });
  }

  return (
    <FormDefault
      tittle={cite ? "Editar cite" : "Crear nuevo cite"}
      fields={[
        {
          label: "Cliente",
          name: "type",
          typeInput: "select",
          urlApi: "/company/getallcompanies",
          value: "razonSocial",
          labelOption: "razonSocial",
          // options: [
          //   {
          //     label: "Solicitud",
          //     value: "Solicitud",
          //   },
          //   {
          //     label: "Aclaraciones",
          //     value: "Aclaraciones",
          //   },
          //   {
          //     label: "Autorizaciones",
          //     value: "Autorizaciones",
          //   },
          //   {
          //     label: "Correcciones",
          //     value: "Correcciones",
          //   },
          //   {
          //     label: "Remisiones",
          //     value: "Remisiones",
          //   },
          //   {
          //     label: "Respuestas acta de reconocimiento",
          //     value: "Respuestas acta de reconocimiento",
          //   },
          //   {
          //     label: "Respuestas orden de control diferido",
          //     value: "Respuestas orden de control diferido",
          //   },
          //   {
          //     label: "Respuestas vista de cargo",
          //     value: "Respuestas vista de cargo",
          //   },
          // ],
        },
        {
          label: "Asunto o referencia",
          name: "subject",
        },
        {
          label: "Destinatario",
          name: "receiver",
          typeInput: "text",
          // urlApi: "/Dropdown/recipients/options",
          // value: "name",
          // labelOption: "name",
        },
        {
          label: "Asignado",
          name: "entity",
          typeInput: "text",
          // urlApi: "/Dropdown/recipient-entities/options",
          // value: "name",
          // labelOption: "name",
        },
        // {
        //   label: "Posición del destinatario",
        //   name: "position",
        //   typeInput: "selectNewOption",
        //   urlApi: "/Dropdown/recipient-position/options",
        //   value: "name",
        //   labelOption: "name",
        // },
        // {
        //   label: "Estado",
        //   name: "state",

        //   typeInput: "select",
        //   options: [
        //     {
        //       label: "Emitido",
        //       value: "Emitido",
        //     },
        //     {
        //       label: "Recepcionado",
        //       value: "Recepcionado",
        //     },
        //   ],
        // },
      ]}
      initialValues={{
        citeNumber: cite?.citeNumber ?? "",
        type: cite?.type ?? "",
        issuing: cite?.issuing ?? "",
        subject: cite?.subject ?? "",
        receiver: cite?.receiver ?? "",
        entity: cite?.state ?? "",
        state: cite?.state ?? "",
      }}
      validationSchema={Yup.object().shape()}
      onSubmit={async (values) => {
        if (values.receiver_isNew) {
          await handleCreateOption(values.receiver, "recipients");
        }
        if (values.entity_isNew) {
          await handleCreateOption(values.entity, "recipient-entities");
        }
        if (values.position_isNew) {
          await handleCreateOption(values.position, "recipient-position");
        }        
        await requestAuth(
          cite ? "put" : "post",
          cite ? `/cite/${cite.id}` : "/Cite",
          values
        )
          .then(() => {
            reload();
            onClose();
            toast.success("Se creo con éxito");
          })
          .catch((response) => {
            toast.error(
              response.response.data
                ? response.response.data
                : "Se produjo un error al crear"
            );
          });
      }}
      buttonName={cite ? "Editar" : "Crear"}
    />
  );
};

const ViewAppointment = ({ cite }) => {
  const { openDialog, dialogClose } = useDialog();
  const { data, loading, call } = Request({
    urlApi: `/Cite/${cite.id}`,
  });

  function addDocument(reload) {
    openDialog(
      <FormAddDocument cite={cite} reload={reload} onClose={dialogClose} />
    );
  }

  function handleDelete(file) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el documento?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/cite/file/${file.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }
  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      <div className="flex justify-between">
        <H2>Lista de documentos</H2>
        <Button onClick={() => addDocument(call)}>
          <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <br />
      <TableComplete
        header={[
          {
            name: "name",
            label: "Documento",
          },
          {
            name: "status",
            label: "Tipo de documento",
          },
          {
            name: "url",
            label: "Enlace",
            type: "custom",
            builder: (item) => {
              if (item) {
                return (
                  <Anchor
                    href={item}
                    className="text-[#1d4ed8] underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir
                  </Anchor>
                );
              } else {
                return "-";
              }
            },
          },
          {
            name: "add",
            type: "action",
            label: "Opciones",
            sticky: true,
            actions: [
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: handleDelete,
                color: "text-red-500",
              },
            ],
          },
        ]}
        data={data.files}
      />
    </div>
  );
};

const FormAddDocument = (props) => {
  const { cite, onClose, reload } = props;
  return (
    <>
      <FormDefault
        tittle="Adicionar documento"
        fields={[
          {
            label: "Nombre del documento",
            name: "name",
            placeholder: "Nombre del documento",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Tipo de documento",
            name: "status",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Documento de emisión",
                value: "Documento de emisión",
              },
              {
                label: "Constancia de recepción",
                value: "Constancia de recepción",
              },
            ],
          },
          {
            label: "Archivo",
            name: "url",
            type: "file",
            typeInput: "file",
          },
        ]}
        initialValues={{
          status: "Documento de emisión",
        }}
        validationSchema={Yup.object().shape({
          url: Yup.mixed().required("Campo requerido.").nullable(),
          name: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          let formData = new FormData();
          formData.append("CiteId", cite.id);
          formData.append("File", values.url);
          formData.append("Status", values.status);
          formData.append("originalName", values.url.name);
          formData.append("Description", values.name);
          formData.append("name", values.name);

          await requestAuth("post", "/cite/file", formData)
            .then((res) => {
              onClose();
              reload();
              toast.success("Se creo con éxito");
            })
            .catch(() => {
              toast.error("Se produjo un error al subir el archivo");
            });
        }}
        buttonName="Crear"
      />
      <br />
    </>
  );
};
export default ManageAppointments;
