import React, { useState } from 'react'
import { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { H1 } from '../Text'

const reorder = (list, startIndex, endIndex) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DragList = ({ data, orderFieldsActive, onSaveChange, tittle }) => {
  const [fields, setFields] = useState()

  useEffect(() => {
    setFields(data)
  }, [data])

  return (
    <>
      {orderFieldsActive ? (
        <DragDropContext
          onDragEnd={(result) => {
            if (result.destination === null) return

            setFields(
              reorder(fields, result.source.index, result.destination.index),
            )
          }}
        >
          {tittle !== undefined ? (
            <H1 className="text-center my-[20px] font-bold">{tittle}</H1>
          ) : null}
          <Droppable droppableId="field" isCombineEnabled>
            {(droppableProvided) => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                className="task-container flex flex-col"
              >
                {fields?.map((field, index) => (
                  <Draggable
                    key={field?.id}
                    draggableId={field?.name}
                    index={index}
                  >
                    {(draggabelProvided) => (
                      <span
                        {...draggabelProvided.draggableProps}
                        ref={draggabelProvided.innerRef}
                        {...draggabelProvided.dragHandleProps}
                        className="w-full text-center bg-slate-300 py-2 mb-1"
                      >
                        {field?.label || field?.name}
                      </span>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="w-full flex items-center">
            <button
              className="font-bold rounded-lg px-[20px] py-[10px] w-[300px] bg-amber-400 my-[40px] mx-auto"
              onClick={() => {
                onSaveChange(fields)
              }}
            >
              Realizar cambios
            </button>
          </div>
        </DragDropContext>
      ) : (
        ''
      )}
    </>
  )
}

export default DragList
