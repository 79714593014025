import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SubModal = (props) => {
  const { children, setactivateForm, activateForm, exitesc, onClose } = props

  function handleClose() {
    if (onClose) {
      onClose()
    } else {
      setactivateForm(false)
    }
  }
  // exitEsc(handleClose || exitesc)
  return (
    <div
      className={
        activateForm
          ? 'z-40 h-[100%] overflow-y-auto bg-slate-200 fixed top-0 right-0 duration-500 left-0 lg:left-[50%]'
          : 'z-40 h-[100%] overflow-y-auto bg-slate-200 fixed -right-[100%] bottom-0 duration-500 left-full '
      }
    >
      <div className="h-[100%] w-[100%] absolute" onClick={handleClose}></div>
      <div className="overflow-y-auto relative w-full bg-inherit px-[10px] sm:px-[40px] py-[20px] mx-auto h-auto">
        <button
          className="font-bold text-[20px] ml-[95%] mt-[20px]"
          type="button"
          onClick={handleClose}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-xmark"
            className="text-4xl lg:text-6xl"
          />
        </button>
        {activateForm ? children : null}
      </div>
    </div>
  )
}
// const exitEsc = (setClose) => {
//   document.addEventListener('keydown', (event) => {
//     if (event.which === 27 && event.keyCode === 27) {
//       setClose(false)
//     }
//   })
// }
export default SubModal
