import { createContext, useContext } from 'react'
import { ToastContainer } from 'react-toastify'
const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
  return (
    <AlertContext.Provider value>
      {children}
      <div>
        <ToastContainer
          className="font-bold text-[18px] text-center"
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </AlertContext.Provider>
  )
}

export const useAlert = () => useContext(AlertContext)
