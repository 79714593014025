import { useState, createContext, useContext } from 'react'
import ModalFullScreen from '../components/Modal/ModalFullScreen'

const DialogContext = createContext()

export const DialogProvider = ({ children }) => {
  const [modal, setModal] = useState({
    status: false,
    children: null,
  })

  function dialogClose() {
    setModal({
      status: false,
      children: null,
    })
  }
  function openDialog(children) {
    setModal({
      status: true,
      children: children,
    })
  }
  return (
    <DialogContext.Provider value={{ dialog: modal, openDialog, dialogClose }}>
      {children}
      <ModalFullScreen onClose={dialogClose} activateForm={modal.status}>
        {modal.children}
      </ModalFullScreen>
    </DialogContext.Provider>
  )
}

export const useDialog = () => useContext(DialogContext)
