import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import { Request } from "../../hooks/Request";
import config from "../../config.json";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import { roundOut } from "../../utils/validate";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export const PlanillaPDF = ({ dispatchDocument, procedure }) => {
  const { data: planillas, loading } = Request({
    urlApi: `${config.requestContaURL}/api/PaymentItem/per/${procedure.numRef}/docType/2?exclude=true`,
  });
  const { data: attachments, loading: attachmentsLoading } = Request({
    urlApi: `${config.requestContaURL}/api/Attachment/dispatchDocument/${dispatchDocument.id}`,
  });

  function handleValidate(listAttachments) {
    let auxAttachment = listAttachments;
    const polAttachment = listAttachments.find(
      (attachment) => attachment.type === "Pol.Importación"
    );

    if (!polAttachment) {
      attachments.push({
        id: "0",
        type: "Pol.Importación",
        document: procedure?.dim ?? "",
        dispatchDocumentId: dispatchDocument.id,
      });
    }
    return auxAttachment;
  }

  return (
    <div>
      {!loading || !attachmentsLoading ? (
        <Circle />
      ) : (
        <PDFViewer
          style={{
            width: 900,
            height: window.innerHeight - 100,
          }}
        >
          <Document>
            <Page
              size="A4"
              style={{
                padding: "30px",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <View flexDirection="column">
                <View
                  justifyContent="space-between"
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Image
                    src="https://res.cloudinary.com/joeldes/image/upload/v1703637339/Agda/logo_nw1jah.png"
                    style={{ width: 80 }}
                  />
                  <View flexDirection="column" alignItems="center">
                    <Text fontSize="10">PLANILLA DE DESPACHO</Text>
                    <Text fontSize="10">
                      La Paz Casa Matriz - N° {dispatchDocument.num ?? "-"}
                    </Text>
                  </View>
                  <View flexDirection="column" style={{ width: "17%" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text fontSize="10">Fecha:</Text>
                      <Text fontSize="10">
                        {dayjs(dispatchDocument.registerDate).format(
                          "DD/MM/YYYY"
                        ) ?? "-"}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text fontSize="10">Hora:</Text>
                      <Text fontSize="10">
                        {dayjs(dispatchDocument.registerDate).format(
                          "HH:mm:ss"
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
                <View justifyContent="space-between">
                  <Text fontSize="10">
                    Razón Social: {procedure?.clienteNombre}
                  </Text>
                  <Text fontSize="10">
                    Cliente: {procedure?.clienteCodigo ?? "-"}
                  </Text>
                </View>
                <View
                  style={{ borderTop: "1px solid black", marginVertical: 5 }}
                ></View>
                <Text fontSize="10">REFERENCIAS:</Text>
                <View
                  style={{
                    flexWrap: "wrap",
                    gap: "0 10px",
                  }}
                >
                  <Text fontSize="10">
                    Ref.: {procedure?.referenciaCliente}
                  </Text>
                  <Text fontSize="10">Mer.: {procedure?.mercaderia}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "0 10px",
                  }}
                >
                  <Text fontSize="10">Carpeta: {procedure?.numRef}</Text>
                  <Text fontSize="10">
                    {procedure.procedureTypeId === 2 ? "DEX" : "DIM"}:{" "}
                    {procedure?.dim}
                    {procedure?.fechaAceptacion !== "-"
                      ? `- ${procedure.fechaAceptacion}`
                      : " "}
                  </Text>
                  <Text fontSize="10">
                    FOB[Us]:{" "}
                    {procedure?.fobUsd
                      ? procedure?.fobUsd.includes(",")
                        ? procedure?.fobUsd
                        : Number(procedure?.fobUsd).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                      : "-"}
                  </Text>
                  <Text fontSize="10">
                    CIF[Bs]:{" "}
                    {procedure?.cifBs
                      ? procedure?.cifBs.includes(",")
                        ? procedure?.cifBs
                        : Number(procedure?.cifBs).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                      : "-"}
                  </Text>
                </View>
                <View
                  style={{ borderTop: "1px solid black", marginVertical: 5 }}
                ></View>
                <Text
                  fontSize="10"
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  POR LO SIGUIENTE, DEBE:
                </Text>
                <CustomTablePDF
                  data={planillas.map((item) => ({
                    ...item,
                    acronymExpense:
                      item.expense?.acronym || item.caEgrDetSrvCod,
                    descriptionExpense:
                      item.expense?.description || item.concept,
                  }))}
                  fields={[
                    {
                      label: "Item",
                      name: "acronymExpense",
                      width: "12",
                    },
                    {
                      label: "Description",
                      name: "descriptionExpense",
                      width: "60",
                    },
                    {
                      label: "Importe Bs",
                      name: "finalAmount",
                      width: "30",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      type: "money",
                    },
                  ]}
                  border={{
                    top: true,
                    right: true,
                    bottom: true,
                    left: true,
                  }}
                  fontSize="10"
                />
                <Text
                  fontSize="10"
                  textAlign="right"
                  style={{
                    fontFamily: "Helvetica-Bold",
                    marginVertical: "5px",
                  }}
                >
                  PAGADO POR SU CUENTA: Bs.{" "}
                  {dispatchDocument.totalSheet.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
                <Text
                  fontSize="10"
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  SON: {dispatchDocument.totalSheetString}
                </Text>
                <View
                  style={{ borderTop: "1px solid black", marginVertical: 5 }}
                ></View>
                <Text
                  fontSize="10"
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  CONSOLIDACIÓN:
                </Text>
                <View
                  justifyContent="space-between"
                  style={{
                    marginVertical: 5,
                  }}
                >
                  <Text fontSize="10" style={{ maxWidth: "60%" }}>
                    SON: {dispatchDocument.totalProcedureString}
                  </Text>
                  <View style={{ maxWidth: "40%" }}>
                    <CustomTablePDF
                      data={[
                        {
                          doc: `Planilla N°: ${dispatchDocument.num ?? "-"}`,
                          amount: dispatchDocument.totalSheet.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          ),
                        },
                        {
                          doc: `Factura N°: ${dispatchDocument.num ?? "-"}`,
                          amount: dispatchDocument.totalInvoice.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          ),
                        },
                        {
                          doc: "TOTAL TRÁMITE",
                          amount: `Bs. ${dispatchDocument.totalProcedure.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`,
                        },
                      ]}
                      fields={[
                        {
                          label: "Documento",
                          name: "doc",
                          width: "50",
                        },
                        {
                          label: "Importe Bs",
                          name: "amount",
                          width: "50",
                          justifyContent: "flex-end",
                        },
                      ]}
                      fontSize="10"
                      border={{
                        top: true,
                        right: true,
                        bottom: true,
                        left: true,
                      }}
                    />
                    <View
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                        height: 3,
                        marginVertical: 0,
                      }}
                    ></View>
                  </View>
                </View>
                <View
                  style={{ borderTop: "1px solid black", marginVertical: 5 }}
                ></View>
                <Text
                  fontSize="10"
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  DOCUMENTOS ADJUNTOS:
                </Text>
                <CustomTablePDF
                  data={handleValidate(attachments).reverse()}
                  fields={[
                    {
                      label: "Item",
                      name: "dispatchDocumentId",
                      width: "10",
                      type: "index",
                      justifyContent: "center",
                    },
                    {
                      label: "Tipo",
                      name: "type",
                      width: "60",
                    },
                    {
                      label: "Documento",
                      name: "document",
                      width: "30",
                    },
                  ]}
                  border={{
                    top: true,
                    right: true,
                    bottom: true,
                    left: true,
                  }}
                  fontSize="10"
                />
              </View>

              <View flexDirection="column" style={{ gap: 10 }}>
                <View flexDirection="row" justifyContent="space-around">
                  <Image
                    src="https://res.cloudinary.com/joeldes/image/upload/v1704774719/Agda/logoOEA_nceuhb.png"
                    style={{ width: 50 }}
                  />
                  <View
                    style={{ width: "100%" }}
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Text fontSize="9px" color="#00823d">
                      CASA MATRIZ: Calle Loayza N° 273 - Edif. Virgen de
                      Copacabana Piso 5 - La Paz Bolivia
                    </Text>
                    <Text fontSize="9px" color="#00823d">
                      Telf. 2202625/2201822 - Fax 2150047 - Email
                      info@agda.com.bo
                    </Text>
                    <Text fontSize="9px" color="#00823d">
                      Sucursal Tambo Quemado: Av. Panamericana s/n Cel. 78833940
                      - Oruro Bolivia
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </div>
  );
};
