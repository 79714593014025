import { H3, H2, Anchor } from "../../components/Text";
import { Circle } from "../../components/Animation";
import { Button } from "../../components/Buttons";
import TableComplete from "../../components/Tables/TableComplete";
import { DialogConfirmation } from "../../components/Modal";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import { Request } from "../../hooks/Request";

const ProofDeliveries = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function createDelivery(reload) {
    openModal(<FormDelivery reload={reload} onClose={onClose} />);
  }

  function editDelivery(delivery, reload) {
    openModal(
      <FormDelivery delivery={delivery} reload={reload} onClose={onClose} />
    );
  }

  function deleteDelivery(delivery, reload) {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres eliminar la constancia de entrega?</H3>
        }
        onClose={dialogClose}
        method="delete"
        url={`/constance/${delivery.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }

  function viewDelivery(delivery) {
    openModal(<ViewDelivery delivery={delivery} onClose={onClose} />);
  }

  return (
    <>
      <CallServiceTable
        onClick={viewDelivery}
        component={({ reload }) => {
          return (
            <Button className="py-2" onClick={() => createDelivery(reload)}>
              Crear Constancia de entrega <i className="fa-solid fa-plus"></i>
            </Button>
          );
        }}
        // filters={[
        //   {
        //     name: "type",
        //     label: "Todos los tipos",
        //     default: "Todos los tipos",
        //     options: [
        //       {
        //         label: "Solicitud",
        //         name: "Solicitud",
        //       },
        //       {
        //         label: "Aclaraciones",
        //         name: "Aclaraciones",
        //       },
        //       {
        //         label: "Autorizaciones",
        //         name: "Autorizaciones",
        //       },
        //       {
        //         label: "Correcciones",
        //         name: "Correcciones",
        //       },
        //       {
        //         label: "Remisiones",
        //         name: "Remisiones",
        //       },
        //       {
        //         label: "Respuestas acta de reconocimiento",
        //         name: "Respuestas acta de reconocimiento",
        //       },
        //       {
        //         label: "Respuestas orden de control diferido",
        //         name: "Respuestas orden de control diferido",
        //       },
        //       {
        //         label: "Respuestas vista de cargo",
        //         name: "Respuestas vista de cargo",
        //       },
        //     ],
        //   },
        //   {
        //     name: "receiver",
        //     url: "/Dropdown/recipients/options",
        //     label: "Todos los destinatarios",
        //     valueOption: "name",
        //     labelOption: "name",
        //   },
        //   {
        //     name: "state",
        //     label: "Todos los estados",
        //     default: "Todos los estados",
        //     options: [
        //       {
        //         label: "Emitido",
        //         name: "Emitido",
        //       },
        //       {
        //         label: "Recepcionado",
        //         name: "Recepcionado",
        //       },
        //     ],
        //   },
        // ]}
        urlApi="/constance"
        header={[
          {
            label: "Nro constancia",
            name: "number",
            filter: true,
          },
          {
            label: "Cliente",
            name: "cliente",
          },
          {
            label: "Nit",
            name: "nit",
            filter: true,
          },
          {
            label: "Teléfono",
            name: "telefono",
          },
          {
            name: "edit",
            type: "action",
            label: "Editar",
            actions: [
              {
                label: "Editar",
                icon: "fas fa-edit",
                action: editDelivery,
                color: "text-[#1d4ed8]",
              },
            ],
          },
          {
            name: "delete",
            type: "action",
            label: "Eliminar",
            actions: [
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: deleteDelivery,
                color: "text-red-500",
              },
            ],
          },
        ]}
      />
    </>
  );
};

const FormDelivery = (props) => {
  const { delivery, onClose, reload } = props;

  async function handleCreateOption(value, dropdown) {
    await requestAuth("post", "/dropdown/option", {
      Name: value,
      DropdownListLabel: dropdown,
    });
  }

  return (
    <FormDefault
      tittle={
        delivery
          ? "Editar constancia de entrega"
          : "Crear nueva constancia de entrega"
      }
      fields={[
        {
          label: "Cliente",
          name: "cliente",
        },
        {
          label: "NIT",
          name: "nit",
        },
        {
          label: "Teléfono",
          name: "telefono",
        },
        {
          label: "Atención",
          name: "atn",
        },
        {
          label: "Descripción",
          name: "descripcion",
        },
        {
          label: "Dirección",
          name: "direccion",
        },
        {
          label: "Observaciones",
          name: "obs",
        },
      ]}
      initialValues={{
        cliente: delivery?.cliente ?? "",
        atn: delivery?.atn ?? "",
        descripcion: delivery?.descripcion ?? "",
        direccion: delivery?.direccion ?? "",
        obs: delivery?.obs ?? "",
        nit: delivery?.nit ?? "",
        telefono: delivery?.telefono ?? "",
      }}
      validationSchema={Yup.object().shape()}
      onSubmit={async (values) => {
        // if (values.receiver_isNew) {
        //   await handleCreateOption(values.receiver, "recipients");
        // }
        // if (values.entity_isNew) {
        //   await handleCreateOption(values.entity, "recipient-entities");
        // }
        // if (values.position_isNew) {
        //   await handleCreateOption(values.position, "recipient-position");
        // }
        await requestAuth(
          delivery ? "put" : "post",
          delivery ? `/constance/${delivery.id}` : "/constance",
          values
        )
          .then(() => {
            reload();
            onClose();
            toast.success("Se creo con éxito");
          })
          .catch((response) => {
            toast.error(
              response.response.data
                ? response.response.data
                : "Se produjo un error al crear"
            );
          });
      }}
      buttonName={delivery ? "Editar" : "Crear"}
    />
  );
};

const ViewDelivery = ({ delivery }) => {
  const { openDialog, dialogClose } = useDialog();
  const { data, loading, call } = Request({
    urlApi: `/constance/${delivery.id}/files`,
  });

  function addDocument() {
    openDialog(
      <FormDocument delivery={delivery} reload={call} onClose={dialogClose} />
    );
  }

  function handleEdit(document) {
    openDialog(
      <FormDocument
        document={document}
        delivery={delivery}
        reload={call}
        onClose={dialogClose}
      />
    );
  }

  function handleDelete(file) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el documento?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/constance/file/${file.fileId}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }
  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      <div className="flex justify-between">
        <H2>Lista de documentos</H2>
        <Button onClick={() => addDocument(call)}>
          <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <br />
      <TableComplete
        header={[
          {
            name: "name",
            label: "Documento",
          },
          {
            name: "number",
            label: "Número",
          },
          {
            name: "provider",
            label: "Emisor/proveedor",
          },
          {
            name: "status",
            label: "Estado",
          },
          {
            name: "url",
            label: "Enlace",
            type: "custom",
            builder: (item) => {
              if (item) {
                return (
                  <Anchor
                    href={item}
                    className="text-[#1d4ed8] underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir
                  </Anchor>
                );
              } else {
                return "-";
              }
            },
          },
          {
            name: "add",
            type: "action",
            label: "Opciones",
            sticky: true,
            actions: [
              {
                label: "Editar",
                icon: "fas fa-edit",
                action: handleEdit,
                color: "text-[#1d4ed8]",
              },
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: handleDelete,
                color: "text-red-500",
              },
            ],
          },
        ]}
        data={data}
      />
    </div>
  );
};

const FormDocument = (props) => {
  const { document, delivery, onClose, reload } = props;

  async function handleCreate(values) {
    let formData = new FormData();
    formData.append("constanceId", delivery.id);
    formData.append("name", values.name);
    formData.append("number", values.number);
    formData.append("provider", values.provider);
    formData.append("status", values.status);
    formData.append("file", values.url);
    formData.append("originalName", values.url.name);
    await requestAuth("post", "/constance/file", formData)
      .then(() => {
        onClose();
        reload();
        toast.success("Se creo con éxito");
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function handleEdit(values) {
    let formData = new FormData();
    formData.append("fileId", document.fileId);
    formData.append("name", values.name);
    formData.append("number", values.number);
    formData.append("provider", values.provider);
    formData.append("status", values.status);
    if (typeof values.url === "string") {
      formData.append("url", values.url);
      formData.append("file", null);
    } else {
      formData.append("file", values.url);
      formData.append("originalName", values.url.name);
    }
    await requestAuth("put", "/constance/file", formData)
      .then(() => {
        onClose();
        reload();
        toast.success("Se creo con éxito");
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  return (
    <>
      <FormDefault
        tittle={document ? "Editar documento" : "Adicionar documento"}
        fields={[
          {
            label: "Archivo",
            name: "url",
            type: "file",
            typeInput: "file",
          },
          {
            label: "Documento",
            name: "name",
          },
          {
            label: "Número",
            name: "number",
          },
          {
            label: "Emisor/proveedor",
            name: "provider",
          },
          {
            label: "Estado",
            name: "status",
          },
        ]}
        initialValues={{
          url: document?.url ?? "",
          name: document?.name ?? "",
          number: document?.number ?? "",
          provider: document?.provider ?? "",
          status: document?.status ?? "",
        }}
        validationSchema={Yup.object().shape({
          url: Yup.mixed().required("Campo requerido.").nullable(),
          name: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={document ? handleEdit : handleCreate}
        buttonName={document ? "Editar" : "Crear"}
      />
      <br />
    </>
  );
};
export default ProofDeliveries;
