import * as Yup from "yup";
import { validateArray } from "./validate";

export const validateType = {
  text: Yup.string().required("Campo requerido"),
  controlField: Yup.string().required("Campo requerido"),
  selectGet: Yup.string().required("Campo requerido"),
  file: Yup.mixed().required("Campo requerido.").nullable(),
  fileRead: Yup.mixed().required("Campo requerido.").nullable(),
  fileExcel: null,
  // fileExcel: Yup.string().required('Campo requerido'),
  number: Yup.number()
    .typeError("Eso no parece un número")
    .required("Campo requerido"),
};

export const headerListContent = [
  { name: "initialDate", label: "Fecha de inicio" },
  { name: "dimNumber", label: "Nro. DIM/DEX" },
  { name: "stepDetail", label: "Paso actual" },
  { name: "companyName", label: "Cliente" },
  { name: "referenciaCliente", label: "Referencia cliente", filter: true },
  { name: "nroDeOrdenDeCompra", label: "Orden de compra", filter: true },
  { name: "descripcionMercancia", label: "Mercadería", filter: true },
  {
    name: "partidaArancelariaItem",
    label: "Partida arancelaria",
    filter: true,
    type: "custom",
    builder: (_, __, data) => {
      return (
        <div className="">
          {data?.listItems && validateArray(data.listItems)
            ? data.listItems.map((item) => (
                <div className="flex flex-wrap">
                  <p className="border-2 border-gray-500 rounded-md px-1">
                    {item.siNdnCod}
                  </p>
                </div>
              ))
            : null}
        </div>
      );
    },
  },
  {
    name: "descripcionItem",
    label: "Descripción partida",
    filter: true,
    type: "custom",
    builder: (_, __, data) => {
      return (
        <div className="">
          {data?.listItems && validateArray(data.listItems)
            ? data.listItems.map((item) => (
                <div className="flex flex-wrap">
                  <p className="border-2 border-gray-500 rounded-md px-1">
                    {item.opCarImpItmDscCom}
                  </p>
                </div>
              ))
            : null}
        </div>
      );
    },
  },
  { name: "aduana", label: "Aduana" },
];

export const headerList = [
  {
    name: "internCode",
    label: "Nro. interno",
  },
  ...headerListContent,
];
export const filtersDefault = [
  {
    name: "status",
    default: "Todos los trámites",
    options: [
      {
        label: "Concluidos",
        name: "finished",
      },
      {
        label: "Pendientes",
        name: "pending",
      },
    ],
  },
  {
    name: "companyId",
    url: "/company/getallcompanies",
    default: "Todas las compañías",
    valueOption: "id",
    labelOption: "razonSocial",
  },
  {
    // url: '/company/getallcompanies',
    // name: 'companyId',
    // valueOption: 'id',
    // labelOption: 'razonSocial',
    type: "rangeDate",
  },
];

export const dataGeneral = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "fa-regular fa-rectangle-list",
    // type: 'table',
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "45",
        name: "campo2",
        type: "text",
      },
      {
        id: 3,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo3",
        type: "text",
      },
      {
        id: 4,
        label: "Example4",
        initial: "4544",
        name: "campo4",
        type: "text",
      },
      {
        id: 5,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo5",
        type: "text",
      },
      {
        id: 6,
        label: "Example",
        initial: "45",
        name: "campo6",
        type: "text",
      },
      {
        id: 7,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo7",
        type: "text",
      },
      {
        id: 8,
        label: "Example4",
        initial: "4544",
        name: "campo8",
        type: "text",
      },
      {
        id: 9,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo9",
        type: "text",
      },
      {
        id: 10,
        label: "Example",
        initial: "45",
        name: "campo10",
        type: "text",
      },
      {
        id: 11,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo11",
        type: "text",
      },
      {
        id: 12,
        label: "Example4",
        initial: "4544",
        name: "campo12",
        type: "text",
      },
      {
        id: 13,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo13",
        type: "text",
      },
      {
        id: 14,
        label: "Example4",
        initial: "4544",
        name: "campo14",
        type: "text",
      },
    ],
  },
  {
    id: 2,
    head: "Operadores",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 Aduana",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
  {
    id: 3,
    head: "Lugares",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 Destino",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
  {
    id: 4,
    head: "Transporte",
    icon: "",
    type: "list",
    items: [
      {
        id: 1,
        label: "A3 Transporte",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "45",
        name: "campo2",
        type: "text",
      },
      {
        id: 3,
        label: "A3 N° de referencia",
        initial: "47fff",
        name: "campo3",
        type: "text",
      },
      {
        id: 4,
        label: "Example4",
        initial: "4544",
        name: "campo4",
        type: "text",
      },
    ],
  },
];
export const dataGeneral2 = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
];
export const dataGeneral3 = [
  {
    id: 1,
    head: "Identificacion de la declaracion",
    icon: "",
    items: [
      {
        id: 1,
        label: "A3 N° de referencia",
        initial: "473",
        name: "campo1",
        type: "text",
      },
      {
        id: 2,
        label: "Example",
        initial: "473",
        name: "campo1",
        type: "text",
      },
    ],
  },
];

export const monthList = [
  {
    id: 1,
    label: "enero",
    color: "rgb(58,182,229)",
  },
  {
    id: 2,
    label: "febrero",
    color: "rgb(53,184,193)",
  },
  {
    id: 3,
    label: "marzo",
    color: "rgb(205,222,151)",
  },
  {
    id: 4,
    label: "abril",
    color: "rgb(180,212,104)",
  },
  {
    id: 5,
    label: "mayo",
    color: "rgb(128,186,53)",
  },
  {
    id: 6,
    label: "junio",
    color: "rgb(254,223,0)",
  },
  {
    id: 7,
    label: "julio",
    color: "rgb(233,78,22)",
  },
  {
    id: 8,
    label: "agosto",
    color: "rgb(230,39,35)",
  },
  {
    id: 9,
    label: "septiembre",
    color: "rgb(248,201,76)",
  },
  {
    id: 10,
    label: "octubre",
    color: "rgb(248,161,81)",
  },
  {
    id: 11,
    label: "noviembre",
    color: "rgb(225,123,46)",
  },
  {
    id: 12,
    label: "diciembre",
    color: "rgb(172,223,251)",
  },
];
export const filtersDefaultChart = [
  // {
  //   name: "procedureType",
  //   url: "/procedureType",
  //   default: "Todos los tramites",
  //   valueOption: "id",
  //   labelOption: "name",
  // },
  // {
  //   name: "liquidator",
  //   url: "/Dropdown/rol_liquidator/options",
  //   label: "Todos los liquidadores",
  //   valueOption: "name",
  //   labelOption: "name",
  // },
  // {
  //   name: "gestor",
  //   url: "/Dropdown/rol_gestor/options",
  //   label: "Todos los gestores",
  //   valueOption: "name",
  //   labelOption: "name",
  // },
  // {
  //   name: "inCharge",
  //   url: "/Dropdown/rol_InCharge/options",
  //   label: "Todos los encargados",
  //   valueOption: "id",
  //   labelOption: "name",
  // },
  // {
  //   name: "prioridad",
  //   url: "/Dropdown/prioridad/options",
  //   label: "Prioridad",
  //   valueOption: "name",
  //   labelOption: "name",
  // },
  // {
  //   name: "complejidad",
  //   url: "/Dropdown/ComplejidadDelDespacho/options",
  //   label: "Complejidad",
  //   valueOption: "name",
  //   labelOption: "name",
  // },
  // {
  //   name: "dateQuery",
  //   label: "Filtrar fecha por: ",
  //   options: [
  //     {
  //       label: "Fecha de facturación",
  //       name: "fechaDeFacturación",
  //     },
  //     {
  //       label: "Fecha aceptación",
  //       name: "a2FechaDeAceptación",
  //     },
  //   ],
  // },
  // {
  //   name: "months",
  //   label: "Todos los meses",
  //   options: [
  //     {
  //       label: "Enero",
  //       name: "1",
  //     },
  //     {
  //       label: "Febrero",
  //       name: "2",
  //     },
  //     {
  //       label: "Marzo",
  //       name: "3",
  //     },
  //     {
  //       label: "Abril",
  //       name: "4",
  //     },
  //     {
  //       label: "Mayo",
  //       name: "5",
  //     },
  //     {
  //       label: "Junio",
  //       name: "6",
  //     },
  //     {
  //       label: "Julio",
  //       name: "7",
  //     },
  //     {
  //       label: "Agosto",
  //       name: "8",
  //     },
  //     {
  //       label: "Septiembre",
  //       name: "9",
  //     },
  //     {
  //       label: "Octubre",
  //       name: "10",
  //     },
  //     {
  //       label: "Noviembre",
  //       name: "11",
  //     },
  //     {
  //       label: "Diciembre",
  //       name: "12",
  //     },
  //   ],
  // },
];
