import { useState } from 'react'
// import Button from '../../components/Buttons/Button'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import SubModal from '../../components/Modal/SubModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MyLoans = () => {
  // const [modal, setModal] = useState(false)
  function handleBuilder(data, head) {
    if (data) {
      return 'Entregado'
    }
    return 'Prestado'
  }
  return (
    <div>
      <CallServiceTable
        urlApi="/loans"
        header={[
          {
            name: 'nameProcedure',
            label: 'Tramite',
            filter: true,
          },
          {
            name: 'reason',
            label: 'Motivo',
            filter: false,
          },
          // {
          //   name: 'fullNameRequestingUser',
          //   label: 'Solicitante',
          //   filter: false,
          // },
          {
            name: 'loanDate',
            label: 'Fecha de préstamo',
            type: 'date',
            filter: false,
          },
          {
            name: 'tentativeDate',
            label: 'Fecha tentativa de la devolución',
            type: 'date',
            filter: false,
          },
          {
            name: 'nameCompany',
            label: 'Compañía',
            filter: false,
          },
          {
            name: 'returnObservation',
            label: 'Estado',
            type: 'custom',
            builder: handleBuilder,
            filter: false,
          },
          {
            name: 'returnObservation',
            label: 'Observaciones de la devolución',
          },
        ]}
        addUrl={`type=request&userId=${window.localStorage.getItem('id')}`}
        // onClick={onClick}
        statusPaination={true}
      />
      {/* <LoanDetail modal={modal} setModal={setModal} /> */}
    </div>
  )
}

const LoanDetail = (props) => {
  const { modal, setModal } = props
  return (
    <SubModal setactivateForm={setModal} activateForm={modal}>
      <button
        className="font-bold text-[20px] ml-[95%] mt-[20px]"
        onClick={() => setModal(!modal)}
      >
        <FontAwesomeIcon
          icon="fa-solid fa-xmark"
          className="text-4xl lg:text-6xl"
        />
      </button>
    </SubModal>
  )
}

export default MyLoans
