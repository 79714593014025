import React from 'react'
import { toast } from 'react-toastify'
import Button from '../../components/Buttons/Button'
import ModalFullScreen from '../../components/Modal/ModalFullScreen'
import SubModal from '../../components/Modal/SubModal'
import { requestAuth } from '../../components/services/RequestService'
import Texto from '../../components/Text/Texto'

const DeleteProcedure = ({
  data,
  modalState,
  modalActive,
  reload,
  setreload,
}) => {
  return (
    <ModalFullScreen setactivateForm={modalState} activateForm={modalActive}>
      <Texto className="font-bold my-[10px]">
        ¡Se eliminara el procedimiento {data?.name}!
      </Texto>
      <Button
        className="bg-red-500 text-white flex items-center justify-center my-[20px]"
        onClick={() => {
          requestAuth('delete', `/procedureType/${data.id}`)
            .then(() => {
              setreload(!reload)
              toast.success('Archivo eliminado')
              modalState(false)
            })
            .catch(() => {
              toast.error('Error al enviar')
            })
        }}
      >
        Eliminar
      </Button>
    </ModalFullScreen>
  )
}

export default DeleteProcedure
