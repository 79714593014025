import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import { Request } from "../../hooks/Request";
import config from "../../config.json";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import { roundOut } from "../../utils/validate";
import { useEffect, useState } from "react";
import QRCode from "qrcode";

const FacturaPDF = ({ dispatchDocument, procedure }) => {
  const [src, setSrc] = useState("");
  const { data: planillas, loading } = Request({
    urlApi: `${config.requestContaURL}/api/PaymentItem/per/${procedure.numRef}/docType/2?exclude=true`,
  });

  const urlSiat = dispatchDocument.url1
    ? new URL(dispatchDocument.url1).searchParams.get("cuf")
    : null;

  function handleListCuf(str) {
    const strAux = [];
    for (let i = 0; i < str.length; i += 15) {
      strAux.push(str.substring(i, i + 15));
    }
    return strAux;
  }

  useEffect(() => {
    if (dispatchDocument?.url1) {
      QRCode.toDataURL(dispatchDocument.url1)
        .then(setSrc)
        .catch((e) => console.log(e));
    }
  }, [dispatchDocument]);

  return (
    <div>
      {!loading ? (
        <Circle />
      ) : (
        <PDFViewer
          style={{
            width: 900,
            height: window.innerHeight - 100,
          }}
        >
          <Document>
            <Page
              size="A4"
              style={{
                padding: "30px 30px 30px 30px",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <View flexDirection="column">
                <View justifyContent="space-between">
                  <View flexDirection="column" width="60%">
                    <Image
                      src="https://res.cloudinary.com/joeldes/image/upload/v1703637339/Agda/logo_nw1jah.png"
                      style={{ width: 120 }}
                    />
                    <View flexDirection="column" alignItems="center">
                      <Text fontSize="11px" padding="2px 3px">
                        AGENTES GENERALES DESPACHANTES DE ADUANA S.A.
                      </Text>
                      <Text fontSize="10px">CASA MATRIZ</Text>
                      <Text fontSize="10px" padding="2px 3px">
                        No. Punto de Venta 0
                      </Text>
                      <Text fontSize="10px" padding="2px 3px">
                        Edif. Virgen de Copacabana - Piso 5
                      </Text>
                      <Text fontSize="10px" padding="2px 3px">
                        Calle Loayza N° 273 - Zona Central
                      </Text>
                      <Text fontSize="10px" padding="2px 3px">
                        Teléfonos (591-2) 2202625 - 2201822
                      </Text>
                      <Text fontSize="10px" padding="2px 3px">
                        http://www.agda.com.bo
                      </Text>
                      <Text fontSize="10px" padding="2px 3px">
                        LA PAZ
                      </Text>
                    </View>
                  </View>
                  <View flexDirection="column" width="38%">
                    <View
                      style={{
                        border: "1px solid black",
                        gap: "5px",
                        padding: "5px 10px",
                      }}
                    >
                      <View flexDirection="column" style={{ width: "45%" }}>
                        <Text bold fontSize="11">
                          NIT:
                        </Text>
                        <Text bold fontSize="11">
                          FACTURA N°:
                        </Text>
                        <Text bold fontSize="11">
                          AUTORIZACIÓN:
                        </Text>
                      </View>
                      <View flexDirection="column" style={{ width: "55%" }}>
                        <Text bold fontSize="11">
                          1016557021
                        </Text>
                        <Text bold fontSize="11">
                          {dispatchDocument.num ?? "-"}
                        </Text>
                        {urlSiat ? (
                          <View flexDirection="column">
                            {handleListCuf(urlSiat).map((item, index) => (
                              <Text
                                key={index}
                                bold
                                fontSize="11"
                                padding="2px 3px"
                              >
                                {item}
                              </Text>
                            ))}
                          </View>
                        ) : (
                          <Text
                            style={{
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            -
                          </Text>
                        )}
                      </View>
                    </View>
                    <Text
                      fontSize="15"
                      textAlign="center"
                      style={{ marginTop: "10" }}
                    >
                      FACTURA
                    </Text>
                    <Text fontSize="11" textAlign="center">
                      (CON DERECHO A CRÉDITO FISCAL)
                    </Text>
                  </View>
                </View>
                <View style={{ gap: "15", marginTop: "15" }}>
                  <Text fontSize="10" textAlign="center">
                    Ref.: {procedure.referenciaCliente}
                  </Text>
                  <Text fontSize="10" textAlign="center">
                    Mer.: {procedure.mercaderia}
                  </Text>
                </View>
                <View style={{ gap: "15" }}>
                  <Text fontSize="10" textAlign="center">
                    Carpeta: {procedure.numRef}
                  </Text>
                  <Text fontSize="10" textAlign="center">
                    {procedure.procedureTypeId === 2 ? "DEX" : "DIM"}:{" "}
                    {procedure?.dim}
                    {procedure?.fechaAceptacion !== "-"
                      ? `- ${procedure.fechaAceptacion}`
                      : " "}
                  </Text>
                  {procedure.procedureTypeId === 2 ? (
                    <View></View>
                  ) : (
                    <>
                      <Text fontSize="10">
                        FOB[Us]:{" "}
                        {procedure.fobUsd
                          ? procedure.fobUsd.includes(",")
                            ? procedure.fobUsd
                            : Number(procedure.fobUsd).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : "-"}
                      </Text>
                      <Text fontSize="10">
                        CIF[Bs]:{" "}
                        {procedure.cifBs
                          ? procedure.cifBs.includes(",")
                            ? procedure.cifBs
                            : Number(procedure.cifBs).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : "-"}
                      </Text>
                    </>
                  )}
                </View>
                <View padding="5"></View>
                <CustomTablePDF
                  fields={[
                    {
                      label: `Código\nProducto\nServicio`,
                      name: "caEgrDetSrvCod",
                      width: "15",
                      justifyContent: "center",
                    },
                    {
                      label: "Descripción",
                      name: "concept",
                      width: "51",
                      justifyContent: "start",
                      alignItems: "center",
                    },
                    {
                      label: "Precio\nUnitario",
                      name: "finalAmount",
                      width: "17",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      type: "money",
                    },
                    {
                      label: "Subtotal",
                      name: "finalAmount",
                      width: "17",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      type: "money",
                    },
                  ]}
                  border={{
                    top: true,
                    right: true,
                    bottom: true,
                    left: true,
                  }}
                  fontSize="11"
                  data={planillas}
                />
                <View width="100%" justifyContent="space-between">
                  <Text fontSize="11">
                    SON: {dispatchDocument.totalInvoiceString}
                  </Text>
                  <View flexDirection="column" style={{ minWidth: "231" }}>
                    <View
                      justifyContent="flex-end"
                      border={{ right: true, bottom: true, left: true }}
                    >
                      <View width="140">
                        <Text fontSize="10">SUBTOTAL Bs</Text>
                      </View>
                      <View
                        width="91"
                        border={{ left: true }}
                        justifyContent="flex-end"
                      >
                        <Text fontSize="11">
                          {roundOut(Number(dispatchDocument.totalInvoice))}
                        </Text>
                      </View>
                    </View>
                    <View
                      justifyContent="flex-end"
                      border={{ right: true, bottom: true, left: true }}
                    >
                      <View width="140">
                        <Text fontSize="10">DESCUENTO Bs</Text>
                      </View>
                      <View
                        width="91"
                        border={{ left: true }}
                        justifyContent="flex-end"
                      >
                        <Text fontSize="11">0.00</Text>
                      </View>
                    </View>
                    <View
                      justifyContent="flex-end"
                      border={{ right: true, bottom: true, left: true }}
                    >
                      <View width="140">
                        <Text fontSize="10">TOTAL Bs</Text>
                      </View>
                      <View
                        width="91"
                        border={{ left: true }}
                        justifyContent="flex-end"
                      >
                        <Text fontSize="11">
                          {roundOut(Number(dispatchDocument.totalInvoice))}
                        </Text>
                      </View>
                    </View>
                    <View
                      justifyContent="flex-end"
                      border={{ right: true, bottom: true, left: true }}
                    >
                      <View width="140">
                        <Text fontSize="10">IMPORTE GIFT CARD Bs</Text>
                      </View>
                      <View
                        width="91"
                        border={{ left: true }}
                        justifyContent="flex-end"
                      >
                        <Text fontSize="11">0.00</Text>
                      </View>
                    </View>
                    <View
                      justifyContent="flex-end"
                      border={{ right: true, bottom: true, left: true }}
                    >
                      <View width="140">
                        <Text fontSize="10">TOTAL A PAGAR Bs</Text>
                      </View>
                      <View
                        width="91"
                        border={{ left: true }}
                        justifyContent="flex-end"
                      >
                        <Text fontSize="11">
                          {roundOut(Number(dispatchDocument.totalInvoice))}
                        </Text>
                      </View>
                    </View>
                    <View
                      justifyContent="flex-end"
                      border={{ right: true, bottom: true, left: true }}
                    >
                      <View width="140">
                        <Text fontSize="10">BASE CRÉDITO FISCAL Bs</Text>
                      </View>
                      <View
                        width="91"
                        border={{ left: true }}
                        justifyContent="flex-end"
                      >
                        <Text fontSize="11">
                          {roundOut(Number(dispatchDocument.totalInvoice))}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View flexDirection="column" style={{ gap: 10 }}>
                <View alignItems="center" justifyContent="space-around">
                  <View flexDirection="column" alignItems="center" width="75%">
                    <Text bold fontSize="11" textAlign="center">
                      ESTA FACTURA CONTRIBUYE AL DESARROLLO DEL PAÍS. EL USO
                      ILÍCITO DE ESTA SERÁ SANCIONADO PENALMENTE DE ACUERDO A
                      LEY
                    </Text>
                    <Text fontSize="11" textAlign="center">
                      Ley N° 453: El proveedor de servicios debe habilitar
                      medios e instrumentos para efectuar consultas y
                      reclamaciones.
                    </Text>
                    <Text fontSize="11" textAlign="center">
                      Este documento es la Representación Gráfica de un
                      Documento Fiscal Digital emitido en una modalidad de
                      facturación en línea
                    </Text>
                  </View>
                  <View>
                    {src ? <Image src={src} style={{ width: 100 }} /> : null}
                  </View>
                </View>
                <View flexDirection="row" justifyContent="space-around">
                  <Image
                    src="https://res.cloudinary.com/joeldes/image/upload/v1704774719/Agda/logoOEA_nceuhb.png"
                    style={{ width: 50 }}
                  />
                  <View
                    style={{ width: "100%" }}
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Text fontSize="9px" color="#00823d">
                      CASA MATRIZ: Calle Loayza N° 273 - Edif. Virgen de
                      Copacabana Piso 5 - La Paz Bolivia
                    </Text>
                    <Text fontSize="9px" color="#00823d">
                      Telf. 2202625/2201822 - Fax 2150047 - Email
                      info@agda.com.bo
                    </Text>
                    <Text fontSize="9px" color="#00823d">
                      Sucursal Tambo Quemado: Av. Panamericana s/n Cel. 78833940
                      - Oruro Bolivia
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </div>
  );
};

export default FacturaPDF;
