import "./App.css";
import RouteMaster from "./routes/RouteMaster";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { AlertProvider } from "./contexts/AlertContext";
import { ModalProvider } from "./contexts/ModalContext";
import { DialogProvider } from "./contexts/DialogContext";
import { SocketProvider } from "./contexts/SocketContext";
import { SocketPendingProvider } from "./contexts/SocketPendingContext";

function App() {
  return (
    <BrowserRouter>
      <ContextContainer>
        <RouteMaster />
      </ContextContainer>
    </BrowserRouter>
  );
}

const ContextContainer = ({ children }) => {
  return (
    <AuthProvider>
      <AlertProvider>
        <DialogProvider>
          <ModalProvider>
            <SocketProvider>
              <SocketPendingProvider>{children}</SocketPendingProvider>
            </SocketProvider>
          </ModalProvider>
        </DialogProvider>
      </AlertProvider>
    </AuthProvider>
  );
};

export default App;
