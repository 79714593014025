import { EditUser, AddDocument } from "./";
import { useState } from "react";
import { Request } from "../../../hooks/Request";
import { Texto, Anchor } from "../../../components/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../components/Buttons/Button";
import { useModal } from "../../../contexts/ModalContext";
import { useDialog } from "../../../contexts/DialogContext";
import { DialogConfirmation } from "../../../components/Modal";
import { H3 } from "../../../components/Text";

const UserDetail = (props) => {
  const { userId, reload } = props;
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  const { data: userInfo, call } = Request({
    urlApi: userId ? `/users/${userId}/GetUserId` : null,
  });

  function handleEditUser() {
    openModal(<EditUser userInfo={userInfo} onReload={onReload} />);
  }
  function handleAddDocument() {
    openModal(<AddDocument userId={userId} onReload={onReload} />);
  }
  function handleDeleteDocument(document) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Desea eliminar el archivo {document.name}?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/files/${document.fileId}`}
        texBtn="Eliminar"
        handleSuccess={() =>{
          call();
          onClose();
          onReload();
        }}
      />
    );
  }

  function handleChangeStatus() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>
            ¿Estas seguro que quieres{" "}
            {userInfo?.userToReturn?.status === "Inactivo"
              ? "habilitar"
              : "eliminar"}{" "}
            al usuario?
          </H3>
        }
        onClose={dialogClose}
        method="put"
        url={`users/Activate/${userInfo?.userToReturn?.id}`}
        texBtn={
          userInfo?.userToReturn?.status === "Inactivo"
            ? "Habilitar"
            : "Eliminar"
        }
        handleSuccess={() => {
          call();
          onReload();
          onClose();
        }}
      />
    );
  }

  function onReload() {
    reload();
    openModal(<UserDetail {...props} />);
  }

  return (
    <div>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Nombres:</strong>{" "}
        {userInfo?.userToReturn?.name}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Apellido Paterno:</strong>{" "}
        {userInfo?.userToReturn?.fatherLastName}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Apellido Materno:</strong>{" "}
        {userInfo?.userToReturn?.motherLastName}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Nombre de usuario:</strong>{" "}
        {userInfo?.userToReturn?.username}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Ciudad:</strong>{" "}
        {userInfo?.userToReturn?.city
          ? userInfo?.userToReturn?.city
          : "No registrado"}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Correo:</strong>{" "}
        {userInfo?.userToReturn?.email}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Celular:</strong>{" "}
        {userInfo?.userToReturn?.cellphoneNumber}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
        <strong className="font-semibold text-black">Estado:</strong>{" "}
        {userInfo?.userToReturn?.status}
      </Texto>
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white flex shadow-xl">
        <Texto className="font-semibold text-black mr-[10px]">Roles: </Texto>
        <ul>
          {userInfo?.rolsUserToReturn.length > 0 ? (
            userInfo?.rolsUserToReturn?.map((rol) => {
              return (
                <li key={rol.id} className=" font-normal">
                  {rol?.label}
                </li>
              );
            })
          ) : (
            <Texto>Sin roles asignados</Texto>
          )}
        </ul>
      </div>
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white  shadow-xl">
        <Texto className="font-semibold text-black mr-[10px]">
          Archivos de usuario:{" "}
        </Texto>
        <ul className="w-full">
          {userInfo?.fileListToReturn.length > 0 ? (
            userInfo?.fileListToReturn?.map((file) => {
              return (
                <li
                  key={file.id}
                  className="bg-slate-200 my-2 flex items-center justify-between text-center p-1 pl-4 rounded-lg gap-4"
                >
                  <Anchor
                    className="w-full text-base border-b-2 text-black hover:border-[#1502FF] font-normal hover:text-[#1502FF] duration-200"
                    href={file.url}
                    target="_blanck"
                  >
                    <FontAwesomeIcon
                      className="text-lg mr-2"
                      icon="fa-regular fa-file"
                    />
                    {file?.name}
                  </Anchor>
                  <Button
                    content="Eliminar archivo"
                    className="px-2 py-2"
                    onClick={() => handleDeleteDocument(file)}
                  >
                    <FontAwesomeIcon
                      className="text-white text-[18px] sm:text-[20px]"
                      icon="fa-solid fa-trash-can"
                    />
                  </Button>
                </li>
              );
            })
          ) : (
            <Texto>Sin documentación para mostrar</Texto>
          )}
        </ul>
      </div>
      <div className="flex sm:flex-row mt-6 justify-center w-full items-center gap-4">
        <Button
          content="Editar datos del usuario"
          onClick={handleEditUser}         
        >
          <FontAwesomeIcon
            className="text-white text-lg"
            icon="fa-solid fa-pen-to-square"
          />
        </Button>
        <Button
          content="Eliminar usuario"
          className="w-[50px]"
          onClick={handleChangeStatus}          
        >
          <FontAwesomeIcon
            className="text-white text-lg"
            icon="fas fa-trash"            
          />
        </Button>
        <Button
          content="Adjuntar documento"
          className="w-[50px]"
          onClick={handleAddDocument}          
        >
          <FontAwesomeIcon
            className="text-white text-lg"
            icon="fa-solid fa-upload"
          />
        </Button>
      </div>
    </div>
  );
};

export default UserDetail;
