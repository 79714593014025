import { useState } from 'react'
import { toast } from 'react-toastify'
import { H1, H2 } from '../../components/Text'
import {
  adjuntarDex,
  readDam,
  readDim,
  readParte,
} from '../../components/services/ReadFile'
import { Request } from '../../hooks/Request'
import { Circle } from '../../components/Animation'
import { Button } from '../../components/Buttons'
import { validateStatus, validateArray } from '../../utils/validate'
import { requestAuth } from '../../components/services/RequestService'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import { useDialog } from '../../contexts/DialogContext'

const SendProcedure = (props) => {
  const { procedure, reload, openModal, step, onClose } = props
  const [isSubmitting, setIsSubmitting] = useState(false)

  // console.log("procedure ",procedure)

  const { data, loading } = Request({
    urlApi: `/processstep/${procedure.processStepId}/procedure/${procedure.id}?type=2`,
  })

  
  function saveDataProcedure() {
    // console.log("procedure data ",data)
    const listRead = data.fields.filter(
      (f) => f.type === 'fileRead' && f.initial,
    )
    setIsSubmitting(true)
    if (listRead && listRead.length > 0) {
      readDataProcedure(0, listRead, [])
    } else {
      nextStep()
    }
  }

  function readDataProcedure(index, fields, result) {
    if (index < fields.length) {
      switch (fields[index].url) {
        case 'adjuntarDim':
          readDim(fields, index, procedure, result, readDataProcedure)
          break
        case 'dimRegularizada':
          readDim(fields, index, procedure, result, readDataProcedure)
          break
        case 'adjuntarDex':
          adjuntarDex(fields, index, procedure, result, readDataProcedure)
          break
        case 'documentoDam':
          readDam(fields, index, procedure, result, readDataProcedure)
          break
        case 'parteDeRecepcion':
          readParte(fields, index, procedure, result, readDataProcedure)
          break
      }
    } else {
      const badResult = result.filter(
        (read) => !validateStatus(read.readStatus) && read.validate,
      )

      if (validateArray(badResult)) {
        setIsSubmitting(false)
        reload()
        onClose()
        toast.error(badResult[0].readData)
      } else {
        nextStep()
      }
    }
  }

  function nextStep() {
    requestAuth('put', `dataset/NextStep/${procedure.id}?query=${step}`)
      .then(() => {
        setIsSubmitting(false)
        reload()
        onClose()
        toast.success('Envio exitoso')
      })
      .catch(() => {
        setIsSubmitting(false)
        toast.error('El formulario no ha sido completado')
      })
  }

  function handleSendEmail() {
    openModal(
      <EmailConfig
        saveDataProcedure={saveDataProcedure}
        // openModal={openModal}
        procedure={procedure}
        reload={reload}
        onClose={onClose}
      />,
    )
  }

  if (!loading) {
    return <Circle />
  }

  return (
    <div className="flex flex-col justify-center py-[30px]">
      <H1>¿Estás seguro de que quieres enviar el trámite?</H1>
      {isSubmitting ? (
        <div className="w-full h-[100px] flex justify-center items-center">
          <svg className=" animate-spin h-[30px] mr-3" viewBox="0 0 24 24">
            <i className="text-white font-bold fa-solid fa-circle-notch "></i>
          </svg>
        </div>
      ) : (
        <>
          {/* <button
            type="submit"
            className="my-[10px] md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[20px] leading-6 bg-green-600 text-center text-white py-[15px]"
            onClick={handleSendEmail}
          >
            Enviar y enviar correo
          </button> */}
          <button
            type="submit"
            className="my-[10px] md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[20px] leading-6 bg-[#618BCC] text-center text-white py-[15px]"
            onClick={saveDataProcedure}
          >
            Enviar
          </button>
          <button
            type="button"
            onClick={() => onClose()}
            className="my-[10px] md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[20px] leading-6 bg-[#FBCE00] text-center py-[15px]"
          >
            Cancelar
          </button>
        </>
      )}
    </div>
  )
}

const EmailConfig = (props) => {
  const { procedure, reload, onClose, saveDataProcedure } = props
  const [listField, setListField] = useState([])
  const { openDialog, dialogClose } = useDialog()
  const { data, loading, call } = Request({
    urlApi: `/processstep/${procedure.processStepId}/procedure/${procedure.id}?type=2`,
  })

  function handleList(item, isRemove) {
    if (!isRemove) {
      if (listField.find((element) => element.id === item.id)) {
      } else {
        setListField((a) => [...a, item])
      }
    } else {
      const listResult = listField.filter((element) => element.id != item.id)
      setListField(listResult)
    }
  }
  function handleViewField() {
    openDialog(
      <>
        <CallServiceTable
          urlApi="/field"
          header={[
            {
              name: 'label',
              label: 'Nombre del campo',
              filter: true,
            },
            {
              name: 'type',
              label: 'Tipo de campo',
              filter: false,
              type: 'translate',
              translate: {
                text: 'Texto',
                file: 'Archivo',
                fileRead: 'Archivo',
                number: 'Numero',
                selectGet: 'Lista desplegable',
                checkbox: 'Casilla de verificación',
                textArea: 'Caja de texto multi-línea',
                date: 'Fecha',
              },
            },

            {
              name: 'edit',
              type: 'action',
              label: 'Añadir',
              actions: [
                {
                  label: 'Añadir campo',
                  icon: 'fas fa-circle-plus',
                  action: (values) => {
                    handleList(values, false)
                    dialogClose()
                  },
                  color: 'text-[#1d4ed8]',
                },
              ],
            },
          ]}
          statusPaination={true}
          showTable={false}
        />
        ,
      </>,
    )
  }

  function handleSend() {
    requestAuth('put', `/dataSet/procedure/${procedure.id}/SendEmail`, {
      fieldIds: listField.map((item) => item.id),
    })
      .then(() => {
        saveDataProcedure()
      })
      .catch(() => {
        toast.error('El correo no ha sido enviado')
      })
  }

  if (!loading) {
    return <Circle />
  }
  return (
    <div>
      <H2>Campos a enviar en el correo</H2>
      {validateArray(data.fields) ? (
        <>
          <h3 className="font-semibold mb-2">Campos del paso:</h3>
          <div className="flex flex-wrap gap-1">
            {data.fields.map((item) => {
              const validateAdd = listField.find(
                (element) => element.id === item.id,
              )
                ? true
                : false
              return (
                <div
                  key={item.id}
                  onClick={() => handleList(item, validateAdd)}
                  className={`${
                    validateAdd ? 'opacity-30 bg-blue-300' : 'bg-white'
                  } px-3 py-1 rounded-full cursor-pointer`}
                >
                  <h3>{item.label}</h3>
                </div>
              )
            })}
          </div>
        </>
      ) : null}
      <br />
      <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold">Campos seleccionados:</h3>
        <Button
          className="py-1 px-2 text-white"
          content="Añadir un campo de otro paso"
          onClick={handleViewField}
        >
          <i className="fas fa-stream"></i>
        </Button>
      </div>
      {validateArray(listField) ? (
        <>
          <div className="flex flex-wrap gap-1">
            {listField.map((item) => {
              return (
                <div
                  key={item.id}
                  onClick={() => handleList(item, true)}
                  className={`bg-blue-200 px-3 py-1 rounded-full cursor-pointer`}
                >
                  <h3>
                    {item.label} <i className="fas fa-times text-red-600"></i>
                  </h3>
                </div>
              )
            })}
          </div>
        </>
      ) : null}
      <div className="flex justify-end mt-4">
        <Button
          className="py-2 font-medium bg-green-500"
          content="Añadir un campo de otro paso"
          onClick={handleSend}
        >
          Guardar y enviar <i className="fas fa-paper-plane"></i>
        </Button>
      </div>
    </div>
  )
}

export default SendProcedure
