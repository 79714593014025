import { AddDocument, EditCompany } from "./";
import Button from "../../../components/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Request } from "../../../hooks/Request";
import { H1, Texto, Anchor } from "../../../components/Text";
import { useDialog } from "../../../contexts/DialogContext";
import { DialogConfirmation } from "../../../components/Modal";
import { H3 } from "../../../components/Text";
import { MessageUnsigned } from "../../../components/Forms/FormMessages";
import { validateArray } from "../../../utils/validate";
import { useModal } from "../../../contexts/ModalContext";
import AddClient from "./AddClient";
import FieldsCompany from "./FieldsCompany";
import { data } from "autoprefixer";

const CompanyDetail = (props) => {
  const { reload, companyID } = props;
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  const { data: companyInfo, call } = Request({
    urlApi: companyID ? `/company/${companyID}` : null,
  });

  const { data: clients } = Request({
    urlApi: `company/${companyID}/ClientsCompany`,
  });

  function onReload() {
    reload();
    openModal(<CompanyDetail {...props} />);
  }
  function handleAddDocument() {
    openModal(<AddDocument companyID={companyID} onReload={onReload} />);
  }
  function handleEditCompany() {
    openModal(<EditCompany companyInfo={companyInfo} onReload={onReload} />);
  }
  function handleAddClient() {
    openModal(
      <AddClient clients={clients} companyID={companyID} onReload={onReload} />
    );
  }
  function handleDeleteDocument(document) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Desea eliminar el archivo {document.name}?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/files/${document.id}`}
        texBtn="Eliminar"
        handleSuccess={() => {
          call();
          onClose();
          onReload();
        }}
      />
    );
  }
  function handleChangeStatus() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>
            ¿Estas seguro que quieres{" "}
            {companyInfo?.status === "Inactivo" ? "habilitar" : "eliminar"} al
            usuario?
          </H3>
        }
        onClose={dialogClose}
        method="put"
        url={`company/activate/${companyInfo.id}`}
        texBtn={companyInfo?.status === "Inactivo" ? "Habilitar" : "Eliminar"}
        handleSuccess={() => {
          call();
          onReload();
          onClose();
        }}
      />
    );
  }

  function convertData(convertData) {
    let date = new Date(convertData);
    let fecha = "";
    if (
      date.getDate() !== 0 &&
      date.getMonth() !== 0 &&
      date.getFullYear() !== 0
    ) {
      return (fecha =
        date.getDate() + " / " + date.getMonth() + " / " + date.getFullYear());
    }
    return "No registrado";
  }
  return (
    <div>
      <H1>Datos del cliente</H1>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Id de compañía:</strong>{" "}
        {companyInfo?.num || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Razón Social:</strong>{" "}
        {companyInfo?.razonSocial || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">
          Fecha de creación:
        </strong>{" "}
        {convertData(companyInfo?.creationDate || <MessageUnsigned />)}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">NIT:</strong>{" "}
        {companyInfo?.nit || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">
          Correo electrónico:
        </strong>{" "}
        {companyInfo?.email || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Estado:</strong>{" "}
        {companyInfo?.status || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Sigla:</strong>{" "}
        {companyInfo?.sigla || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Tipo cliente:</strong>{" "}
        {companyInfo?.tipoCliente || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">País:</strong>{" "}
        {companyInfo?.pais || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Departamento:</strong>{" "}
        {companyInfo?.departamento || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Ciudad:</strong>{" "}
        {companyInfo?.ciudad || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Domicilio legal:</strong>{" "}
        {companyInfo?.domicilioLegal || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Actividad:</strong>{" "}
        {companyInfo?.actividad || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">Paga tributos:</strong>{" "}
        {companyInfo?.pagaTributos || <MessageUnsigned />}
      </Texto>
      <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
        <strong className="font-semibold  text-black">
          Descripción del negocio:
        </strong>{" "}
        {companyInfo?.descripcionNegocio || <MessageUnsigned />}
      </Texto>

      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] text-white flex flex-col sm:flex-row shadow-xl">
        <Texto className="font-semibold text-black mr-3">
          Clientes de la compañía:
        </Texto>
        <ul>
          {!validateArray(clients)
            ? "Esta compañia no tiene clientes"
            : clients?.map((client, i) => {
                return (
                  <li key={i} className="text-base">
                    - {client?.name} {client?.fatherLastName}{" "}
                    {client?.motherLastName}
                  </li>
                );
              })}
        </ul>
      </div>
      <FieldsCompany companyId={companyID} />
      {/* {data !== undefined && <ListContact company={data} />} */}
      {/* {data !== undefined && <ListInCharge company={data} />} */}
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] text-white flex flex-col shadow-xl">
        <Texto className="font-semibold text-black mr-3">
          Archivos de la compañía
        </Texto>
        <ul>
          {companyInfo?.files && validateArray(companyInfo.files) ? (
            companyInfo.files.map((file, i) => {
              return (
                <li
                  key={file.id}
                  className="bg-slate-200 my-[10px] flex items-center justify-between text-center font-normal py-[10px] px-[20px]"
                >
                  <Anchor
                    className="text-black w-full mx-[10px] text-[18px] border-b-2 sm:text-[20px] hover:border-[#1502FF] hover:text-[#1502FF] duration-200"
                    href={file.url}
                    target="_blanck"
                  >
                    <FontAwesomeIcon
                      className="text-[20px] mx-[10px]"
                      icon="fa-regular fa-file"
                    />
                    {file?.name}
                  </Anchor>
                  <div
                    className="sm:mr-[20px]"
                    // title="Eliminar archivo"
                    // position="top"
                    trigger="mouseenter"
                  >
                    <Button
                      className="w-[30px] flex items-center justify-center m-0"
                      content="Eliminar archivo"
                      onClick={() => handleDeleteDocument(file)}
                    >
                      <FontAwesomeIcon
                        className="text-white text-[18px] sm:text-[20px]"
                        icon="fa-solid fa-trash-can"
                      />
                    </Button>
                  </div>
                </li>
              );
            })
          ) : (
            <Texto>Sin documentación para mostrar</Texto>
          )}
        </ul>
      </div>
      <div className="flex justify-center flex-wrap w-full">
        <div
          className="m-[10px] sm:mr-[20px]"
          // title="Añadir cliente"
          // position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[60px] sm:w-[60px]"
            content="Añadir Cliente"
            onClick={handleAddClient}
          >
            <FontAwesomeIcon
              className="text-white text-[20px] sm:text-[25px]"
              icon="fa-solid fa-users"
            />
          </Button>
        </div>
        <div
          className="m-[10px] sm:mr-[20px]"
          // title="Adjuntar documento"
          // position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[60px] sm:w-[60px]"
            content="Adjuntar documento"
            onClick={handleAddDocument}
          >
            <FontAwesomeIcon
              className="text-white text-[20px] sm:text-[25px]"
              icon="fa-solid fa-upload"
            />
          </Button>
        </div>
        <div
          className="m-[10px] sm:mr-[20px]"
          // title="Editar datos de la compañia"
          // position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[60px] sm:w-[60px]"
            content="Editar datos de la compañia"
            onClick={handleEditCompany}
          >
            <FontAwesomeIcon
              className="text-white text-[20px] sm:text-[25px]"
              icon="fa-solid fa-pen-to-square"
            />
          </Button>
        </div>
        <div
          className="m-[10px] sm:mr-[20px]"
          title={
            companyInfo?.status === "Activo"
              ? "Inhabilitar compañía"
              : "Habilitar compañía"
          }
          position="top"
          trigger="mouseenter"
        >
          <Button
            className="w-[60px] sm:w-[60px]"
            content={
              companyInfo?.status === "Activo"
                ? "Inhabilitar compañía"
                : "Habilitar compañía"
            }
            onClick={handleChangeStatus}
          >
            <FontAwesomeIcon
              className="text-white text-[20px] sm:text-[25px]"
              icon={
                companyInfo?.status === "Activo"
                  ? "fa-solid fa-eye-slash"
                  : "fa-solid fa-eye"
              }
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetail;
