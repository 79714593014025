import Navbar from './Navbar'
import { useState } from 'react'
import Header from './header/Header'
import styled from 'styled-components'
const Template = ({ path, main }) => {
  const [menu, setMenu] = useState(false)

  return (
    <div className="flex w-screen h-screen max-h-screen static">
      {!menu && (
        <div
          onClick={() => setMenu(!menu)}
          className="hidden portrait:block absolute w-screen h-screen bg-[#ffffff2d]"
        ></div>
      )}
      <Navbar path={path} setNavsMenu={setMenu} navsmenu={menu} />
      <div className="w-full h-full">
        <Header setMenu={setMenu} menu={menu} />
        <MainStyle menu={menu}>{main ? main : null}</MainStyle>
      </div>
    </div>
  )
}

const MainStyle = styled.div`
  height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  padding: 10px 10px 0;
  overflow-y: auto;
  margin: 0 auto;
  max-width: ${({ menu }) =>
    menu ? 'calc(100vw - 60px)' : 'calc(100vw - 230px)'};
  @media screen and (max-width: 800px) {
    max-width: 100vw;
  }
`

export default Template
