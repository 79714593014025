import * as Yup from "yup";
import * as dayjs from "dayjs";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { H2, H3 } from "../../components/Text";
import { Button } from "../../components/Buttons";
import { Circle } from "../../components/Animation";
import { DialogConfirmation } from "../../components/Modal";
import { useModal } from "../../contexts/ModalContext";
import { Request } from "../../hooks/Request";
import { useDialog } from "../../contexts/DialogContext";
import { validateArray } from "../../utils/validate";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";

const ListProcedures = () => {
  const { openModal, onClose } = useModal();
  const { openDialog } = useDialog();

  function handleDetail(procedure) {
    openModal(
      <HistoryDetail
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
      />
    );
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  return (
    <>
      <CallServiceTable
        onClick={handleDetail}
        urlApi="procedure/openprocedures"
        filters={[
          {
            name: "status",
            default: "Todos los trámites",
            options: [
              {
                label: "Concluidos",
                name: "finished",
              },
              {
                label: "Pendientes",
                name: "pending",
              },
            ],
          },
          {
            name: "companyId",
            url: "/company/getallcompanies",
            default: "Todas las compañías",
            valueOption: "id",
            labelOption: "razonSocial",
          },
          {
            // url: '/company/getallcompanies',
            // name: 'companyId',
            // valueOption: 'id',
            // labelOption: 'razonSocial',
            type: "rangeDate",
          },
        ]}
        addFilters={[
          {
            name: "procedureNumber",
            label: "Tramite",
            filter: true,
          },
          {
            name: "internCode",
            label: "Nro. interno",
            filter: true,
          },
          {
            name: "dimNumber",
            label: "Nro. DIM/DEX",
            filter: true,
          },
        ]}
        header={[
          {
            name: "number",
            label: "Trámite",
          },
          {
            name: "creationDate",
            label: "Fecha de inicio",
            type: "date",
          },
          {
            name: "format",
            label: "Tipo de trámite",
          },
          {
            name: "internCode",
            label: "Nro. interno",
          },
          {
            name: "dimNumber",
            label: "Nro. DIM/DEX",
          },
          {
            name: "billNumber",
            label: "Factura",
          },
          {
            name: "",
            label: "Embarque",
          },
          {
            name: "",
            label: "Cod. Cliente",
          },
          {
            name: "companyName",
            label: "Cliente",
          },
          {
            name: "",
            label: "Aduana",
          },
          {
            name: "procedureTypeName",
            label: "Patrón",
          },
          {
            name: "",
            label: "Canal",
          },
          {
            name: "currentStepName",
            label: "Paso Actual",
            type: "custom",
            builder: (value, _, data) => {
              if (data.step > data.totalSteps) {
                return "Terminado";
              }
              return value;
            },
          },
          {
            name: "currentStep",
            label: "Progreso",
            type: "custom",
            builder: (value, _, data) => {
              if (data.step > data.totalSteps) {
                return "Terminado";
              }
              return value;
            },
          },
          {
            name: "Reports",
            type: "action",
            label: "Reportes",
            actions: [
              // {
              //   label: "Declaración jurada",
              //   icon: "fas fa-signature",
              //   action: generateSwornDeclaration,
              //   color: "text-[#1d4ed8] pl-3",
              // },
              // {
              //   label: "Generar informe de la recepción de documentos",
              //   icon: "fas fa-tasks",
              //   action: listDocumentPdf,
              //   color: "text-[#1d4ed8]",
              // },
              // {
              //   label: "Generar entrega de documentos",
              //   icon: "fas fa-file-signature",
              //   action: generateDocumentsDelivery,
              //   color: "text-[#1d4ed8]",
              // },
              // {
              //   label: "Generar liquidación - proforma",
              //   icon: "fas fa-coins",
              //   action: generateSettlementProforma,
              //   color: "text-[#1d4ed8]",
              // },
              {
                label: "Generar hoja de ruta",
                icon: "fas fa-route",
                action: generateRoadmap,
                color: "text-[#1d4ed8]",
              },
              // {
              //   label: "Generar planilla de gastos",
              //   icon: "fas fa-money-bill",
              //   action: expenseSheet,
              //   color: "text-[#1d4ed8] pr-5",
              // },
            ],
          },
          // {
          //   name: "manage",
          //   type: "action",
          //   label: "Gestionar",
          //   sticky: true,
          //   actions: [
          //     {
          //       label: "Editar trámite",
          //       icon: "fas fa-edit",
          //       action: editCountryside,
          //       color: "text-[#1d4ed8]",
          //     },
          //     {
          //       label: "Editar valores",
          //       icon: "fas fa-th-list",
          //       action: editProcedure,
          //       color: "text-[#1d4ed8]",
          //     },
          //   ],
          // },
        ]}
      />
    </>
  );
};

const HistoryDetail = ({ procedure, openModal, onClose }) => {
  const [page, setpage] = useState(1);
  const { openDialog, dialogClose } = useDialog();

  const { data, loading, call, pagination } = Request({
    urlApi: `/procedure/procedureHistory/${procedure.id}?PageNumber=${page}`,
  });

  useEffect(() => {
    if (loading) {
      call();
    }
  }, [page]);

  function handlePagination(number) {
    setpage((a) => a + number);
  }

  function handleEdit(procedureHistory) {
    openModal(
      <FormEdit
        procedure={procedure}
        procedureHistory={procedureHistory}
        onClose={onClose}
        reload={call}
      />
    );
  }
  function handleAddHistory() {
    openModal(
      <FormEdit
        procedure={procedure}
        procedureHistory={null}
        onClose={onClose}
        reload={call}
      />
    );
  }

  function handleDelete(procedureHistory) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el historial?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/procedureHistory/${procedureHistory.procedureHistoryId}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  const Pagination = ({ pag }) => {
    return (
      <div className="flex items-center gap-1">
        <Button
          onClick={() => handlePagination(-1)}
          disabled={pag.currentPage <= 1}
          className="px-2 py-2"
        >
          <i className="fas fa-angle-left"></i>
        </Button>
        {pag.currentPage > 1 ? (
          <h4 className="bg-white p-2 rounded-md">{pag.currentPage - 1}</h4>
        ) : null}
        <h4 className="bg-white p-2 rounded-md border-2 border-[#FBCE00]">
          {pag.currentPage}
        </h4>
        {pag.currentPage < pag.totalPages ? (
          <h4 className="bg-white p-2 rounded-md">{pag.currentPage + 1}</h4>
        ) : null}
        <Button
          onClick={() => handlePagination(1)}
          disabled={pag.currentPage >= pag.totalPages}
          className="px-2 py-2"
        >
          <i className="fas fa-angle-right"></i>
        </Button>
      </div>
    );
  };

  if (!loading) {
    return <Circle />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <H2 margin="0">Historial del trámite</H2>
        <div className="flex gap-3">
          <Button onClick={handleAddHistory} className="px-2 py-2">
            <i className="fas fa-plus"></i>
          </Button>
          <Pagination pag={pagination ? JSON.parse(pagination) : null} />
        </div>
      </div>
      {validateArray(data)
        ? data.map((item) => (
            <div className="flex gap-2 mb-1" key={item.procedureHistoryId}>
              <div
                className={`flex items-center justify-between gap-2 w-full px-2 py-1  ${
                  item.stepAction === "startStep"
                    ? "border-2 border-blue-500"
                    : item.stepAction === "finishStep"
                    ? "border-2 border-blue-500"
                    : ""
                } bg-slate-100 rounded-md`}
              >
                <div className="flex items-center gap-2">
                  <h3 className="font-bold text-green-600">
                    {item.stepNumber}
                  </h3>
                  <div className="">
                    <h4 className="">{item.user}</h4>
                    <h4 className="">
                      {item.description
                        ? item.description
                        : item.stepAction === "startStep"
                        ? "Paso iniciado"
                        : item.stepAction === "finishStep"
                        ? "Paso finalizado"
                        : "Llenando el formulario"}
                    </h4>
                  </div>
                </div>
                <div className="">
                  <h4 className="text-end">{item.step}</h4>
                  <h4 className="text-end">
                    {dayjs(item.date).format("DD/MM/YYYY HH:mm")}
                  </h4>
                </div>
              </div>
              <Button className="px-2" onClick={() => handleEdit(item)}>
                <i className="far fa-edit"></i>
              </Button>
              <Button className="px-2" onClick={() => handleDelete(item)}>
                <i className="fas fa-trash"></i>
              </Button>
            </div>
          ))
        : null}
    </>
  );
};

const FormEdit = (props) => {
  const { procedure, procedureHistory, onClose, reload } = props;
  return (
    <>
      <FormDefault
        tittle={procedureHistory ? "Editar datos" : "Adicionar"}
        fields={[
          {
            label: "Editar fecha",
            name: "registerDate",
            type: "datetime-local",
          },
          {
            label: "Seleccione el paso:",
            name: "step",
            aditionalName: "StepName",
            typeInput: "select",
            urlApi: `/processstep/GetProcessStepsByProcedureTypeId/${procedure.procedureTypeId}`,
            value: "step",
            labelOption: "name",
            labelOptionParam: "step",
          },
          {
            label: "Id del usuario:",
            name: "userId",
            typeInput: "tableSelect",
            urlApi: `/processstep/GetProcessStepsByProcedureTypeId/${procedure.procedureTypeId}`,
            header: [],
            component: () => {
              return <div className="">sasd</div>;
            },
          },

          {
            label: "Acción del paso",
            name: "stepAction",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Paso iniciado",
                value: "startStep",
              },
              {
                label: "Paso finalizado",
                value: "finishStep",
              },
            ],
          },
        ]}
        initialValues={{
          registerDate: procedureHistory?.date
            ? procedureHistory.date.split(".")[0]
            : "",
          step: procedureHistory?.stepNumber ? procedureHistory.stepNumber : "",
          userId: procedureHistory?.userId ? procedureHistory.userId : "",
          stepAction: procedureHistory?.stepAction
            ? procedureHistory.stepAction
            : "",
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values) => {
          await requestAuth(
            procedureHistory ? "put" : "post",
            "/procedureHistory",
            procedureHistory
              ? {
                  ProcedureHistoryId: procedureHistory.procedureHistoryId,
                  ...values,
                }
              : {
                  ProcedureId: procedure.id,
                  ...values,
                }
          )
            .then(() => {
              onClose();
              reload();
              toast.success("Se guardo con éxito");
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : "Se produjo un error al guardar"
              );
            });
        }}
        buttonName={procedureHistory ? "Editar" : "Guardar"}
      />
      <br />
    </>
  );
};

export default ListProcedures;
