import Recurso_3logo from "../../../assets/logo45-5.png";
import FormDefault from "../../../components/Forms/FormDefault";
import * as Yup from "yup";
import { request } from "../../../components/services/RequestService";
import { useAuth } from "../../../contexts/AuthContext";
import { validateArray } from "../../../utils/validate";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { H1, H3 } from "../../../components/Text";
import { RequestNoAuth } from "../../../hooks/RequestNoAuth";
import React from "react";
import styled from "styled-components";
import FormLogin from "../../../containers/auth/Formlogin";
import { de } from "date-fns/locale";

const PasswordRecovery = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const { setUser } = useAuth();
    const params = useParams();

    const { data, loading } = RequestNoAuth({
        urlApi: "/institutionParams/generalData",
      });
    
      function handleToJson(list) {
        if (validateArray(list)) {
          let auxJson = {};
          list.forEach((element) => {
            auxJson = { ...auxJson, [element.code]: element.value };
          });
          return auxJson;
        }
        return {};
      }
      if (!loading) {
        return null;
      }
      return(
      <FormLogin
        title="Restablecer Contraseña"
        fields={[
          {
            label: "Correo electrónico",
            name: "email",
            typeInput: "input",
            type: "email",
            placeholder: "Introduzca su correo electrónico",
            className: `text-[15px] mt-[5px]`,
          },
          {
            label: "Contraseña",
            name: "password",
            typeInput: "input",
            type: "password",
            placeholder: "Introduzca su nombre de usuario",
            className: `text-[15px] mt-[5px]`,
          },
        ]}
        onSubmit={async (values) => {
          await request("post", "/auth/verifyRecovery", {
            ...values,
            verifyKey: params.verifyKey,
          })
            .then(() => {
              navigate("/auth/login");
              toast.success(
                "Se cambio tu contraseña ahora puedes iniciar sesión"
              );
            })
            .catch((err) => {
              console.log(err);
              toast.error("No se pudo realizar la acción");
            });
        }}
        validationSchema={{
          email: Yup.string().email().required("Este campo es obligatorio"),
          password: Yup.string().required("Este campo es obligatorio"),
        }}
        buttonName="RESTABLECER"
        redirect={
          <Link to="/auth/login">
            Volver al <span>Inicio de sesión</span>
          </Link>
        }
        data={handleToJson(data)}
      />
    );
}
export default PasswordRecovery;