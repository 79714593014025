import { H3 } from "../Text";
import * as dayjs from "dayjs";
import { Tooltip } from "react-tippy";
import { validateArray } from "../../utils/validate";

const TableComplete = (props) => {
  const {
    header,
    data,
    call,
    onClick = () => {},
    height,
    subList,
    buttons,
  } = props;
  return (
    <div
      style={{
        maxHeight: height === null ? "" : `${height}px`,
      }}
      className="mx-auto overflow-scroll"
    >
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-base text-[#1d4ed8]">
          <tr>
            {validateArray(header)
              ? header.map((head, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={`${
                      index === 0 ? "sticky z-0 left-0" : ""
                    } py-3 px-6 bg-[#f1f5f9] ${
                      // head.type === 'action'?
                      head.sticky ? "sticky right-0 px-4" : ""
                      // : ''
                    }`}
                  >
                    {head.label}
                  </th>
                ))
              : null}
          </tr>
        </thead>
        <tbody>
          {validateArray(data)
            ? data.map((item, index) =>
                subList ? (
                  item[subList].map((subItem, j) => {
                    return (
                      <ContainTable
                        key={j}
                        call={call}
                        // item={{ ...subItem, ...item }}
                        item={subItem}
                        header={header}
                        onClick={onClick}
                        buttons={buttons}
                      />
                    );
                  })
                ) : (
                  <ContainTable
                    key={index}
                    call={call}
                    item={item}
                    header={header}
                    onClick={onClick}
                    buttons={buttons}
                  />
                )
              )
            : null}
        </tbody>
      </table>
      {!validateArray(data) && (
        <H3 className="text-center text-gray-500 my-2">La lista esta vacía</H3>
      )}
    </div>
  );
};
const ContainTable = (props) => {
  const { item, header, onClick, call, buttons } = props;
  return (
    <tr className="bg-white border-b">
      {validateArray(header)
        ? header.map((head, j) =>
            j === 0 ? (
              <th
                key={j}
                scope="row"
                onClick={() => onClick(item, call)}
                className="sticky left-0 py-4 px-6 font-medium text-gray-900 bg-[#fcfcfc]"
              >
                <DataType
                  value={item[head.name]}
                  head={head}
                  data={item}
                  call={call}
                />
              </th>
            ) : head.type !== "action" ? (
              <td
                key={j}
                className={`${
                  head.sticky ? "sticky right-0 bg-[#f7f7f7]" : "py-2 px-1"
                }`}
                onClick={
                  head.disabledOnClick ? () => {} : () => onClick(item, call)
                }
              >
                <DataType
                  value={item[head.name]}
                  head={head}
                  data={item}
                  call={call}
                  buttons={buttons}
                />
              </td>
            ) : (
              <td
                key={j}
                className={`${
                  head.sticky ? "sticky right-0 bg-[#f7f7f7]" : ""
                }`}
              >
                {validateArray(head.actions) ? (
                  <div className="flex gap-4 justify-center mx-3">
                    {head.actions.map((act, index) =>
                      act && act.action ? (
                        <div
                          key={index}
                          // className={`${act.color} text-xl cursor-pointer`}
                          className={`${act.color} text-xl cursor-pointer ${
                            act.validate
                              ? item.showButtonJoinPdfs
                                ? ""
                                : "hidden"
                              : ""
                          }`}
                          onClick={() => act.action(item, call)}
                        >
                          <Tooltip
                            // title={act.validate ? (item.showButtonJoinPdfs ? item.showButtonJoinPdfs : act.label) : act.label}
                            title={act.label}
                            position="right"
                            trigger="mouseenter"
                          >
                            <i className={act.icon + " p-1"}></i>
                          </Tooltip>
                        </div>
                      ) : null
                    )}
                  </div>
                ) : null}
              </td>
            )
          )
        : null}
    </tr>
  );
};

const DataType = (props) => {
  const { value, head, data, call, buttons } = props;

  function validateType() {
    const listData = {
      date:
        value === "0001-01-01T00:00:00" || value === null
          ? "-"
          : dayjs(value).format("DD/MM/YYYY HH:mm"),
      custom: head.builder
        ? head.builder(value, head, data, call, buttons)
        : null,
      translate: head.translate ? head.translate[value] : "",
      // action: validateArray(head.actions) ? (
      //   <div className="flex gap-4 justify-center portrait:justify-end">
      //     {head.actions.map((act, index) => (
      //       <Tooltip
      //         key={index}
      //         title={act.label}
      //         position="left"
      //         trigger="mouseenter"
      //         className="w-full"
      //       >
      //         <div
      //           className={`${act.color} text-xl cursor-pointer transition ease-in-out hover:-translate-x-1 duration-200`}
      //           // onClick={() => act.action(value, call)}
      //         >
      //           <i className={`${act.icon}`}></i>
      //         </div>
      //       </Tooltip>
      //     ))}
      //   </div>
      // ) : null,
    };
    return listData[head.type] || value;
  }
  return <div>{validateType()}</div>;
};

export default TableComplete;
