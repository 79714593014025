import { useState, Fragment } from "react";
import { validateArray } from "../../utils/validate";
import Button from "./Button";
const Navigation = (props) => {
  const { navigation, actDefault, minWidth = "min-w-[150px]" } = props;
  const [active, setactive] = useState(actDefault);
  return (
    <div className="w-full">
      {validateArray(navigation) && navigation.length > 1 ? (
        <div className="flex gap-[8px] bg-[white] rounded-lg p-[8px] flex-wrap">
          {navigation.map((item) => (
            <Button
              key={item.id}
              type="button"
              onClick={() => setactive(item.id)}
              className={`w-auto ${minWidth} ${
                item.id === active
                  ? "bg-[#1d4ed8] text-[white]"
                  : "bg-[#c7c7c9] text-[white]"
              } py-[4px] 2xl:py-[4px] px-[10px] my-[0px]`}
            >
              {item.label}
            </Button>
          ))}
        </div>
      ) : null}
      <div id="TabbedNavigationContent" className="TabbedNavigation__content">
        {active && validateArray(navigation)
          ? navigation
              .filter((item) => item.id === active)
              .map((nav) => <Fragment key={nav.id}>{nav.component}</Fragment>)
          : null}
      </div>
    </div>
  );
};

export default Navigation;
