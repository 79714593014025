export const fileFormInput = [
  {
    label: "Nombre del campo",
    name: "Label",
    placeholder: "Nombre del campo",
    type: "text",
    typeInput: "input",
  },
  {
    label: "Tipo",
    name: "Type",
    type: "select",
    typeInput: "select",
    options: [
      {
        label: "Texto",
        value: "text",
      },
      {
        label: "Numero",
        value: "number",
      },
      { label: "Fecha", value: "date" },
      { label: "Fecha y hora", value: "datetime-local" },
      { label: "Lista desplegable", value: "selectGet" },
      { label: "Sub-lista desplegable", value: "subSelect" },
      { label: "Lista desplegable (adición)", value: "selectNewOption" },
      { label: "Lista desplegable multiple", value: "selectGetMultiple" },
      {
        label: "Archivo",
        value: "file",
      },
      {
        label: "Archivo con estado",
        value: "fileStatus",
      },
      {
        label: "Campo con valor multiple",
        value: "subData",
      },
      {
        label: "Campo con valor multiple (adición)",
        value: "subDataAdd",
      },
      {
        label: "Campo con valor multiple contabilidad (adición)",
        value: "subDataContaAdd",
      },
      {
        label: "Items de contabilidad",
        value: "subItemsConta",
      },
      {
        label: "Solicitar número correlativo",
        value: "requestSerialNumber",
      },
      {
        label: "Campo de control",
        value: "controlField",
      },
      {
        label: "Campo de control de entrega mercadería",
        value: "controlFieldDelivery",
      },
      {
        label: "Campo de incidencia",
        value: "IncidentField",
      },
      {
        label: "Archivo para la lectura",
        value: "fileRead",
      },
      { label: "Generar excel", value: "fileExcel" },
      { label: "Revisar excel", value: "reviewExcel" },
      { label: "Corregir excel", value: "correctExcel" },
      { label: "Casilla de verificación", value: "checkbox" },
      { label: "Caja de texto multi-línea", value: "textArea" },
      { label: "Completar documentos", value: "completeDocuments" },
      {
        label: "Completar documentos (Crear Opciones)",
        value: "completeDocumentsAdd",
      },
      { label: "Formulario secundario", value: "secondaryForm" },
    ],
  },
  {
    label: "Tipo de archivo",
    name: "Url",
    type: "optionSelect",
    typeInput: "select",
    options: [
      {
        label: "Tipo de archivo",
        value: "",
      },
      {
        label: "Parte de recepción",
        value: "parteDeRecepcion",
      },
      {
        label: "DIM",
        value: "adjuntarDim",
      },
      {
        label: "DIM Regularizada",
        value: "dimRegularizada",
      },
      {
        label: "DEX",
        value: "adjuntarDex",
      },
      {
        label: "DAM",
        value: "documentoDam",
      },
    ],
    conditionType: "listValidate",
    reference: "Type",
    condition: "fileRead",
  },
  {
    label: "Tipo de documento",
    name: "Url",
    type: "optionSelect",
    typeInput: "select",
    options: [
      {
        label: "Tipo de archivo",
        value: "",
      },
      {
        label: "DAV",
        value: "dav",
      },
      {
        label: "DAM",
        value: "dam",
      },
      {
        label: "DIM",
        value: "dim",
      },
      {
        label: "DEX",
        value: "dex",
      },
      {
        label: "Clonar Items",
        value: "clone-items",
      },
    ],
    conditionType: "listValidate",
    reference: "Type",
    condition: "fileExcel,reviewExcel,correctExcel",
  },
  {
    label: "Seleccione la lista desplegable",
    name: "Url",
    type: "select",
    typeInput: "select",
    urlApi: "/Dropdown/List",
    conditionType: "listValidate",
    reference: "Type",
    condition:
      "selectGet,selectNewOption,subItemsConta,subData,subDataAdd,subDataContaAdd,selectGetMultiple",
    labelOption: "name",
    value: "urlLabel",
  },
  {
    label: "Selecciona el campo padre",
    name: "FatherDropdown",
    type: "selectTable",
    typeInput: "selectTable",
    urlApi: "/field",
    conditionType: "listValidate",
    reference: "Type",
    condition: "subSelect",
    labelComponent: (item) =>
      item ? (
        <div className="w-full flex flex-col justify-center px-3 py-2 bg-white border border-gray-300 rounded-md outline-none caret-blue-600 focus:border-blue-600 disabled:opacity-100">
          <h4>
            <span className="font-medium text-blue-900">Nombre del campo:</span>{" "}
            {item.label ?? ""}
          </h4>
          <h4>
            <span className="font-medium text-blue-900">
              Lista desplegable:
            </span>{" "}
            {item.url ?? ""}
          </h4>
        </div>
      ) : null,
    value: "id",
  },
  {
    label: "Seleccione la categoría",
    name: "Url",
    urlApiDynamic: (value) =>
      `${
        value?.FatherDropdown && value.FatherDropdown.url
          ? `/dropdown/${value.FatherDropdown.url}/categories`
          : ""
      }`,
    type: "select",
    typeInput: "select",
    conditionType: "notEmpty",
    reference: "FatherDropdown",
    labelOption: "name",
    value: "name",
  },
  {
    label: "Servicio del formulario",
    name: "Url",
    placeholder: "Servicio del formulario",
    conditionType: "listValidate",
    reference: "Type",
    condition: "secondaryForm",
  },

  {
    label: "Color",
    name: "Color",
    placeholder: "Color",
    type: "color",
    typeInput: "color",
  },
  {
    label: "Campo requerido",
    name: "Validate",
    type: "checkbox",
  },
  {
    label: "Campo único por despacho",
    name: "Unique",
    type: "checkbox",
  },
  {
    label: "Bloquear edición",
    name: "lockEdition",
    type: "checkbox",
  },
  {
    label: "Campo auto-generable",
    name: "AutoGenerated",
    type: "checkbox",
    conditionType: "listValidate",
    reference: "Type",
    condition: "requestSerialNumber",
  },
  {
    label: "Formato ej: [yyyy][-][correlative:4] -> 2022-0001 ",
    name: "Format",
    placeholder: "[yyyy][-][correlative:4]",
    type: "text",
    typeInput: "input",
    conditionType: "listValidate",
    reference: "Type",
    condition: "requestSerialNumber",
  },
];

export const fieldCategoryFormInput = [
  {
    label: "Categoría",
    name: "Category",
    type: "optionSelect",
    typeInput: "select",
    options: [
      {
        label: "IM4 'DESPACHO AL CONSUMO'",
        value: "IM4 'DESPACHO AL CONSUMO'",
      },
      {
        label: "IMA4 'DESPACHO ANTICIPADO'",
        value: "IMA4 'DESPACHO ANTICIPADO'",
      },
      {
        label: "IMI4 'DESPACHO INMEDIATO'",
        value: "IMI4 'DESPACHO INMEDIATO'",
      },
      {
        label: "IM5 'IMPORTACION TEMPORAL'",
        value: "IM5 'IMPORTACION TEMPORAL'",
      },
      {
        label: "IM6 'REIMPORTACION'",
        value: "IM6 'REIMPORTACION'",
      },
      {
        label: "DEX1 'EXPORTACION DEFINITIVO'",
        value: "DEX1 'EXPORTACION DEFINITIVO'",
      },
      {
        label: "DEX2 'EXPORTACION TEMPORAL'",
        value: "DEX2 'EXPORTACION TEMPORAL'",
      },
    ],
  },
  {
    label: "Estado de documento",
    name: "Status",
    type: "optionSelect",
    typeInput: "select",
    options: [
      {
        label: "Original",
        value: "Original",
      },
      {
        label: "Copia",
        value: "Copia",
      },
      {
        label: "Original o copia",
        value: "Original o copia",
      },
      {
        label: "Fotocopia legalizada",
        value: "Fotocopia legalizada",
      },
      {
        label: "Fotocopia simple",
        value: "Fotocopia simple",
      },
      {
        label: "No aplica",
        value: "No aplica",
      },
    ],
  },
  {
    label: "Habilitar verificación",
    name: "Validation",
    type: "checkbox",
  },
];
export const airlineField = [
  {
    label: "Monto",
    name: "amount",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Tipo de pago",
    name: "pay",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Nº de cuenta",
    name: "account",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Banco",
    name: "bank",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Titular de la cuenta",
    name: "headline",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "NIT/CI",
    name: "citCi",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Correo",
    name: "email",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Contacto",
    name: "contact",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
  {
    label: "Días de arribo",
    name: "day",
    conditionType: "listValidate",
    reference: "type",
    condition: "Aerolínea",
  },
];
