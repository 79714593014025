// import * as Yup from 'yup'
import { Button } from "../Buttons";
import { toast } from "react-toastify";
import GetTable from "../Tables/GetTable";
import FormDefault from "../Forms/FormDefault";
import { useModal } from "../../contexts/ModalContext";
import { requestAuth } from "../services/RequestService";

const TableGeneral = (props) => {
  const {
    urlApi,
    header,
    addBtn = "Adicionar",
    handleReload,
    data = [],
    call,
    restHeight,
    urlPut,
    validationSchema = null,
  } = props;

  const { openModal, onClose } = useModal();
  function handleAdd(reload) {
    openModal(
      <FormAddTable
        {...props}
        reload={reload}
        onClose={onClose}
        validationSchema={validationSchema}
        header={header.filter((head) => !head.hidden)}
      />
    );
  }

  function onClick(item, reload) {
    openModal(
      <FormAddTable
        {...props}
        item={item}
        reload={reload}
        onClose={onClose}
        validationSchema={validationSchema}
        header={header.filter((head) => !head.hidden)}
      />
    );
  }

  async function handleRegister(name, value, item, reload) {
    await requestAuth("put", `${urlPut}${item.id}/vehicle`, {
      ...item,
      [name]: value,
    })
      .then(() => {
        reload();
        onClose();
        toast.success("Se guardo con éxito");
      })
      .catch(() => {
        toast.error("Se ha producido un error al guardar");
      });
  }

  // function handleEdit(item, reload) {
  //   openModal(
  //     <FormAddTable
  //       {...props}
  //       item={item}
  //       reload={reload}
  //       onClose={onClose}
  //       validationSchema={validationSchema}
  //       header={header.filter((head) => !head.hidden)}
  //     />
  //   );
  // }

  return (
    <>
      <GetTable
        header={[
          ...header,
          // {
          //   name: "action",
          //   type: "action",
          //   label: "",
          //   hidden: true,
          //   actions: [
          //     {
          //       label: "Editar",
          //       icon: "fas fa-edit",
          //       action: (item, reload) =>
          //         handleEdit(
          //           item,
          //           reload,
          //           "el proveedor",
          //           `/procedureSheet/provider/${item.id}`
          //         ),
          //       color: "text-blue-500",
          //     },
          //   ],
          // },
        ]}
        urlApi={urlApi}
        reload={handleReload}
        onClick={onClick}
        // handleRegister={handleRegister}
        data={data}
        call={call}
        restHeight={restHeight}
        component={({ reload }) => {
          return (
            <div className="w-full my-1 flex justify-end">
              <Button
                className="text-sm px-2 py-1"
                onClick={() => handleAdd(reload ? reload : call)}
                width="w-fit"
              >
                {addBtn}
              </Button>
            </div>
          );
        }}
      ></GetTable>
    </>
  );
};

const FormAddTable = (props) => {
  const {
    header,
    addBtn = "Adicionar",
    editBtn = "Editar",
    urlPost,
    urlPut,
    reload,
    call,
    preProcessPost,
    item = null,
    onClose,
    validationSchema,
    procedureId,
  } = props;

  return (
    <div>
      <FormDefault
        tittle={item ? editBtn : addBtn}
        fields={header.map((item) => ({
          ...item,
          label: item.label,
          name: item.name,
          placeholder: item.label,
        }))}
        initialValues={
          item
            ? { ...item, procedureId: procedureId }
            : { procedureId: procedureId }
        }
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          let url = urlPost;
          if (preProcessPost) {
            url = preProcessPost(values);
          }
          await requestAuth(
            item ? "put" : "post",
            item && urlPut ? `${urlPut}${item.id}` : url,
            values
          )
            .then(() => {
              reload();
              if (call) {
                call();
              }
              onClose();
              toast.success("Se guardo con éxito");
            })
            .catch(() => {
              toast.error("Se ha producido un error al guardar");
            });
        }}
        buttonName={item ? editBtn : addBtn}
      />
    </div>
  );
};

export default TableGeneral;
