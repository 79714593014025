import { useState } from "react";
import { Button } from "../Buttons";
import ModalFullScreen from "../Modal/ModalFullScreen";

import * as Yup from "yup";
import { Circle } from "../../components/Animation";
import { requestAuth } from "../../components/services/RequestService";
import FormDefault from "../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import { Request } from "../../hooks/Request";
import { useDialog } from "../../contexts/DialogContext";

import { H3, H1, H2, Label, Anchor } from "../Text";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { MessageOnLabel } from "./FormMessages";

const SecondaryForm = (props) => {
  const { field, procedure = { id: 1 }, setValues } = props;
  const { openDialog, dialogClose } = useDialog();
  const { data, loading, call } = Request({
    method: "POST",
    urlApi: `/procedure/${procedure.id}/dataFields`,
    dataRequest: [
      "d-1-1TipoDeDocumentoDeEmbarque",
      "d-1-2N°DeDocumentoDeEmbarque",
      "d-1-3PaísDeEmbarque",
      "d-1-4LugarDeEmbarque",
      "d-1-5FechaDeEmbarque",
      "d-1-6ProvieneDeZonaFranca",
    ],
  });

  function dataIntialValues(list) {
    let aux = {};
    list?.forEach((element) => {
      aux = {
        ...aux,
        [element.name]:
          (element.initial === "false" ? false : element.initial) || "",
      };
    });
    return aux;
  }

  async function onSubmit(values, resetForm, fields) {
    const result = await Promise.all(
      fields.map(async (element) => {
        if (element.type === "file" || element.type === "fileRead") {
          if (
            (values[element.name] === "" || values[element.name] === null) &&
            (element.initial === "" || element.initial === null)
          ) {
            return null;
          } else if (
            (values[element.name] !== "" && !element.initial) ||
            (values[element.name] !== "" &&
              values[element.name] !== element.initial)
          ) {
            let formData = new FormData();
            formData.append("Name", element.name);
            formData.append("File", values[element.name]);
            formData.append("originalName", values[element.name].name);
            formData.append("Status", "verified");
            formData.append("ProcedureId", procedure.id);
            const fileContents = await requestAuth(
              "post",
              "/files",
              formData
            ).then((res) => {
              return {
                Value: res.data.url,
                ProcedureId: procedure.id,
                FieldId: element.id,
                FileId: res.data.id,
              };
            });
            return fileContents;
          } else {
            return {
              Value: element.initial,
              ProcedureId: procedure.id,
              FieldId: element.id,
            };
          }
        } else if (element.type === "selectNewOption") {
          if (values[element.name + "_isNew"]) {
            await handleCreateOption(
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
              element.url
            );
          }
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: procedure.id,
            FieldId: element.id,
          };
        } else {
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: procedure.id,
            FieldId: element.id,
          };
        }
      })
    );
    await submitDataset(
      result.filter((n) => n),
      resetForm,
      values.btnSecondary
    );
  }

  async function handleCreateOption(value, dropdown) {
    await requestAuth("post", "/dropdown/option", {
      Name: value,
      DropdownListLabel: dropdown,
    });
  }
  async function submitDataset(values, resetForm) {
    await requestAuth("post", "/dataset", values)
      .then(() => {
        setValues((a) => ({
          ...a,
          [field.name]: values
            .filter((item) => item.Value !== "")
            .map((item) => item.Value)
            .join(" | "),
        }));
        call();
        // reload();
        dialogClose();
        resetForm();
        toast.success("Envio exitoso");
      })
      .catch(() => {
        toast.error("Error en el envio");
      });
  }

  function handleOpenForm() {
    openDialog(
      <div className="">
        <FormDefault
          fields={data
            .sort((a, b) => a.order - b.order)
            .map((value, index) => ({
              ...value,
              id: procedure.id,
              fieldId: value.id,
              label: value.label,
              labelCustom: (
                <>
                  {/* <span className="text-green-600">{index + 1}.</span>{" "} */}
                  {value.label}
                </>
              ),
              name: value.name,
              validate: value.validate,
              lockEdition: value.lockEdition,
              placeholder: "Complete el campo",
              type: value.type === "fileRead" ? "file" : value.type,
              typeInput: value.type === "fileRead" ? "file" : value.type,
              initial: value.initial ? value.initial : null,
              urlApi: value.url
                ? value.url.includes("/")
                  ? value.url
                  : `/Dropdown/${value.url}/options`
                : "",
              urlInitial: value.url,
              value: "name",
              labelOption: "name",
              // action: () => handleExcelModal(value),
              reload: call,
              // step: step,
            }))}
          initialValues={dataIntialValues(data)}
          validationSchema={Yup.object().shape()}
          onSubmit={async (values, resetForm) =>
            await onSubmit(values, resetForm, data)
          }
          // buttonFixed={true}
          buttonName="Registrar"
        />
        <br />
      </div>
    );
  }

  if (!loading) {
    return <Circle />;
  }

  return (
    <div>
      <div className="flex gap-2">
        <div className="flex flex-col w-full">
          <Label htmlFor={field.name} className={field.className}>
            {field.labelCustom ? field.labelCustom : field.label}
            {field.validate && field.type !== "checkbox" && <MessageOnLabel />}
          </Label>
          <Field
            as={
              field.type === "textArea" || field.type === "textarea"
                ? "textarea"
                : "input"
            }
            autoComplete="current-password"
            className="bg-white border border-gray-300 px-3 py-2 rounded-md outline-none caret-blue-600 focus:border-blue-600 disabled:opacity-100 disabled:bg-gray-300"
            // className="rounded-[7px] placeholder-black placeholder:text-[15px] placeholder:opacity-50 placeholder:font-normal p-4 mt-1 font-light text-base bg-white/50"
            onWheel={(e) => e.target.blur()}
            type={field.type}
            id={field.name}
            name={field.name}
            placeholder={field.placeholder ? field.placeholder : field.label}
            disabled={true}
          />
        </div>
        <Button onClick={handleOpenForm}>
          <i className="fab fa-wpforms"></i>
        </Button>
      </div>
    </div>
  );
};

export default SecondaryForm;
