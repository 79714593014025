import { useState, createContext, useContext } from 'react'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({
    status: false,
    children: null,
  })

  function onClose() {
    setModal({
      status: false,
      children: null,
    })
  }
  function openModal(children) {
    setModal({
      status: true,
      children: children,
    })
  }
  return (
    <ModalContext.Provider value={{ modal, openModal, onClose }}>
      {children}
      <ModalContent onClose={onClose} status={modal.status}>
        {modal.children}
      </ModalContent>
    </ModalContext.Provider>
  )
}

const ModalContent = (props) => {
  const { children, status, onClose } = props
  return (
    <>
      {status && (
        <div
          className="absolute top-0 left-0 h-screen w-screen"
          onClick={onClose}
        ></div>
      )}
      <div
        className={`z-40 h-screen bg-slate-200 fixed 
        ${
          status
            ? 'top-0 right-0 duration-500 left-0 lg:left-[50%] 2xl:left-[60%]'
            : '-right-[100%] bottom-0 duration-500 left-full'
        }`}
      >
        <div className="h-full px-6 py-4 overflow-auto">
          <button className="font-bold text-4xl ml-[95%]" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
          {status ? children : null}
        </div>
      </div>
    </>
  )
}

export default ModalContent

export const useModal = () => useContext(ModalContext)
