import * as Yup from 'yup'
import { toast } from 'react-toastify'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'

const FormAddStep = (props) => {
  const { procedureTypeId, step, reload, onClose } = props

  return (
    <div>
      <FormDefault
        tittle="Agregar un nuevo paso"
        fields={[
          {
            label: 'Nombre del Nuevo Paso',
            name: 'Name',
            placeholder: 'Introduzca el Nombre del Nuevo Paso',
            type: 'text',
            typeInput: 'input',
          },
          {
            label: 'Seleccione los roles',
            name: 'RolIdList',
            placeholder: 'Introduzca su nombre de usuario',
            type: 'checked',
            typeInput: 'checkboxes',
            urlApi: '/rol',
            value: 'id',
          },
        ]}
        initialValues={{
          Name: '',
          ProcedureTypeId: procedureTypeId || '',
          RolIdList: [],
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required('Este campo es obligatorio'),
        })}
        onSubmit={async (values, resetForm) => {
          await requestAuth('post', '/processstep', {
            ...values,
            Step: step.step + 1,
          })
            .then(() => {
              toast.success('Envio exitoso')
              reload()
              onClose()
            })
            .catch((response) => {
              toast.error(response.response.data)
            })
        }}
        buttonName="Agregar"
      />
    </div>
  )
}

export default FormAddStep
