import * as Yup from 'yup'
import { toast } from 'react-toastify'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'

const FormEditStep = (props) => {
  const { procedureTypeId, step, reload, onClose } = props

  return (
    <FormDefault
      tittle="Editar campos del paso"
      fields={[
        {
          label: 'Nombre del paso',
          name: 'Name',
          placeholder: 'Nombre del',
          type: 'text',
          typeInput: 'input',
        },
        {
          label: 'Seleccione los roles',
          name: 'Rol',
          type: 'checkbox',
          typeInput: 'checkboxes',
          value: `id`,
          urlApi: 'rol',
        },
      ]}
      initialValues={{
        Name: step.name,
        Rol: step.rolId.map((rol) => rol.rolId + '') || '',
      }}
      validationSchema={Yup.object().shape({
        Name: Yup.string().required('Este campo es obligatorio'),
      })}
      onSubmit={async (values, resetForm) => {
        await requestAuth('put', `/processstep/${step.id}`, {
          ...values,
          ProcedureTypeId: procedureTypeId,
        })
          .then(() => {
            toast.success('Envio exitoso')
            reload()
            onClose()
          })
          .catch(() => {
            toast.error('Se produjo un error al enviar')
          })
      }}
      buttonName="Enviar"
    />
  )
}

export default FormEditStep
