import React from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useModal } from "../../../contexts/ModalContext";
import { H1, Anchor } from "../../../components/Text";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";
import { UserDetail } from ".";

const AddDocument = (props) => {
  // const { openModal, onClose } = useModal();
  const { userId, call, onReload } = props;
  

  async function onSubmit(values) {
    let formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("File", values.File);
    formData.append("originalName", values.File.name);
    formData.append("Type", "File");
    formData.append("UserId", userId);    
    await requestAuth("post", "/files", formData)
      .then(() => {
        //call();      
        toast.success("Envio exitoso");    
        onReload();
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  
  return (
    <div>
      <H1>Adjuntar documento</H1>
      <FormDefault
        fields={[
          {
            label: "Nombre del archivo",
            type: "text",
            name: "Name",
            typeInput: "input",
          },
          {
            label: "Adjuntar un archivo",
            name: "File",
            type: "file",
            typeInput: "file",
          },
        ]}
        initialValues={{
          Name: "",
          File: "",
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required("Este campo es obligatorio"),
          File: Yup.mixed().required("Campo requerido.").nullable(),
        })}
        onSubmit={onSubmit}
        buttonName="Enviar"
      />
    </div>
  );
};

export default AddDocument;
