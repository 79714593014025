import Circle from "../Animation/Circle";
import { Request } from "../../hooks/Request";
import { validateArray } from "../../utils/validate";
import { H3, Anchor } from "../Text";

const ListDocumentVerify = ({ procedure }) => {
  const { data, loading } = Request({
    urlApi: `/procedure/${procedure.id}/documentVerify`,
  });

  return (
    <div>
      {!loading ? (
        <Circle />
      ) : (
        data && (
          <>
            <h1 className={"text-xl my-[10px] mx-auto font-semibold text-left"}>
              Verificar documentos del trámite{" "}
              <span className="text-green-600">{procedure?.number}</span>
              <br />
            </h1>
            <h1 className={"text-normal my-[10px] mx-auto text-left"}>
              Régimen/modalidad{" "}
              {data.category ? (
                data?.category
              ) : (
                <small className="text-red-600">(sin asignar)</small>
              )}
            </h1>
            <StaticTable data={data} />
          </>
        )
      )}
    </div>
  );
};
const Th = ({ className = "py-3 px-2 bg-[#f1f5f9]", label = "" }) => {
  return (
    <th scope="col" className={className}>
      {label}
    </th>
  );
};
const ThTd = ({ className = "py-3 px-2", label = "" }) => {
  return (
    <th scope="row" className={className}>
      {label}
    </th>
  );
};
const StaticTable = ({ data }) => {
  return (
    <div
      style={
        {
          // maxHeight: height === null ? '' : `${height}px`,
        }
      }
      className="mx-auto overflow-x-scroll"
    >
      <table className="w-full text-sm text-left text-gray-500 border-collapse">
        <thead className="text-base text-[#1d4ed8]">
          <tr>
            <Th
              className={`sticky z-0 left-0 py-3 px-3 bg-gray-100`}
              label="Nombre"
            />
            {[
              "Número",
              "Estado actual",
              "Estado general",
              "Enlace",
              "Extensión",
              "Verificación",
            ].map((label, index) => (
              <Th key={index} label={label} />
            ))}
            <Th
              className={`py-3 px-6 bg-[#f1f5f9]  sticky right-0 px-4 text-center bg-gray-100`}
              label="Cumple"
            />
          </tr>
        </thead>
        <tbody>
          {validateArray(data?.fileStatusList)
            ? data?.fileStatusList.map((item, index) => (
                <tr key={index} className="bg-white border-b">
                  <ThTd
                    className={
                      "sticky left-0 py-4 px-3 text-gray-900 bg-green-50"
                    }
                    label={item.fieldName}
                  />
                  {[item.number, item.currentStatus, item.generalStatus].map(
                    (i, index) => (
                      <ThTd key={index} label={i} />
                    )
                  )}
                  <ThTd
                    className={`py-3 px-2 text-center`}
                    label={
                      item.url ? (
                        <Anchor
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-500"
                        >
                          Ver
                        </Anchor>
                      ) : (
                        <span className="text-gray-400">(vacío)</span>
                      )
                    }
                  />
                  <ThTd
                    className={`py-3 px-2 text-center`}
                    label={
                      item.url ? (
                        <span
                          className={`${
                            item.url
                              .split(".")
                              [item.url.split(".").length - 1].toUpperCase() !==
                            "PDF"
                              ? "text-red-300"
                              : ""
                          }`}
                        >
                          {item.url
                            .split(".")
                            [item.url.split(".").length - 1].toUpperCase()}
                        </span>
                      ) : (
                        ""
                      )
                    }
                  />
                  <ThTd
                    className={`py-3 px-2 text-center`}
                    label={
                      item.validation ? (
                        <span className="text-red-600">se verifica</span>
                      ) : (
                        <span className="text-gray-400">no se verifica</span>
                      )
                    }
                  />
                  <ThTd
                    className="sticky right-0 text-gray-900 px-4 text-center bg-gray-100"
                    label={
                      item.valid ? (
                        <i className="fa-solid fa-check text-green-600 text-lg" />
                      ) : (
                        <i className="fa-solid fa-xmark text-red-600 text-lg" />
                      )
                    }
                  />
                </tr>
              ))
            : null}
        </tbody>
      </table>
      {!validateArray(data?.fileStatusList) && (
        <H3 className="text-center text-gray-500 my-2">La lista esta vacía</H3>
      )}
    </div>
  );
};

export default ListDocumentVerify;
