import config from "../config.json";
const hostname = window.location.hostname;

export const validateStatus = (state) => {
  const status = [200, 201, 202, 203, 204, 204];
  if (status && status.indexOf(state) > -1) {
    return true;
  }
  return false;
};
export const validateArray = (list) => {
  return list && Array.isArray(list) && list.length > 0;
};

export const validateUrlToOpen = (url, institutionParam) => {
  if (url?.startsWith("/")) {
    if (hostname === config.hostname) {
      if (institutionParam?.find((i) => i.code === "mediaRequest")?.value) {
        return (
          institutionParam?.find((i) => i.code === "mediaRequest")?.value + url
        );
      }
      return url;
    } else {
      if (institutionParam?.find((i) => i.code === "mediaRequest2")?.value) {
        return (
          institutionParam?.find((i) => i.code === "mediaRequest2")?.value + url
        );
      }
      return url;
    }
  }

  return url;
};

export function roundOut(num) {
  const redondeado = parseFloat(num.toFixed(2));
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(redondeado);
}
