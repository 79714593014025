import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../../components/Buttons/Button'
import FormDefault from '../../components/Forms/FormDefault'
import SubModal from '../../components/Modal/SubModal'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import * as Yup from 'yup'
import { requestAuth } from '../../components/services/RequestService'
import DeleteProcedure from '../../containers/ModalProcedureTypes/DeleteProcedure'

const ProcedureTypes = () => {
  const [getData, setGetData] = useState()
  const [reload, setreload] = useState(false)
  const [modalCreateProcedure, setmodalCreateProcedure] = useState(false)
  const [modalEditProcedures, setmodalEditProcedures] = useState(false)
  const [modalDeleteProcedure, setModalDeleteProcedure] = useState(false)

  function editProcedure(value) {
    setGetData(value)
    setmodalEditProcedures(true)
  }
  function deleteProcedure(value) {
    setModalDeleteProcedure(true)
    setGetData(value)
  }

  return (
    <>
      <CallServiceTable
        reload={reload}
        onClick={setGetData}
        component={({ reload }) => {
          return (
            <Button
              onClick={() => setmodalCreateProcedure(true)}
              // onClick={() => handleNewUser(reload)}
            >
              Crear procedimiento <i className="fa-solid fa-plus"></i>
            </Button>
          )
        }}
        urlApi="/procedureType"
        header={[
          {
            name: 'name',
            label: 'Procedimiento',
            filter: false,
          },
          {
            name: 'edit',
            type: 'action',
            label: 'Editar',
            actions: [
              {
                label: 'Editar',
                icon: 'fas fa-edit',
                action: editProcedure,
                color: 'text-[#1d4ed8]',
              },
            ],
          },
          {
            name: 'delete',
            type: 'action',
            label: 'Eliminar',
            actions: [
              {
                label: 'Eliminar',
                icon: 'fas fa-trash',
                action: deleteProcedure,
                color: 'text-red-500',
              },
            ],
          },
        ]}
        statusPaination={false}
        showTable={true}
      />
      <SubModal
        setactivateForm={setmodalCreateProcedure}
        activateForm={modalCreateProcedure}
      >
        <FormDefault
          tittle="Crear nuevo procedimiento"
          fields={[
            {
              label: 'Nombre de procedimiento',
              name: 'Name',
              placeholder: 'Introduzca el nombre del menu',
              type: 'text',
              typeInput: 'input',
            },
          ]}
          initialValues={{
            Name: '',
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required('Este campo es obligatorio'),
          })}
          onSubmit={async (values, resetForm) => {
            await requestAuth('post', 'procedureType', values)
              .then(() => {
                toast.success('Envio exitoso')
                setreload(!reload)
                setmodalCreateProcedure(false)
                resetForm()
              })
              .catch((response) => {
                toast.error(
                  response.response.data
                    ? response.response.data
                    : 'Se produjo un error',
                )
              })
          }}
          buttonName="Enviar"
        />
      </SubModal>
      <SubModal
        setactivateForm={setmodalEditProcedures}
        activateForm={modalEditProcedures}
      >
        <FormDefault
          tittle="Crear nuevo procedimiento"
          fields={[
            {
              label: 'Nombre de procedimiento',
              name: 'Name',
              placeholder: 'Introduzca el nombre del menu',
              type: 'text',
              typeInput: 'input',
            },
          ]}
          initialValues={{
            Name: getData?.name || '',
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required('Este campo es obligatorio'),
          })}
          onSubmit={async (values, resetForm) => {
            await requestAuth('PUT', `procedureType/${getData.id}`, values)
              .then(() => {
                toast.success('Envio exitoso')
                setreload(!reload)
                setmodalEditProcedures(false)
                resetForm()
              })
              .catch((response) => {
                toast.error(
                  response.response.data
                    ? response.response.data
                    : 'Se produjo un error',
                )
              })
          }}
          buttonName="Enviar"
        />
      </SubModal>
      <DeleteProcedure
        reload={reload}
        setreload={setreload}
        data={getData}
        modalState={setModalDeleteProcedure}
        modalActive={modalDeleteProcedure}
      />
    </>
  )
}

export default ProcedureTypes
