import * as Yup from "yup";
import * as dayjs from "dayjs";
import { toast } from "react-toastify";
import { H1, H2, H3 } from "../../components/Text";
import Button from "../../components/Buttons/Button";
import { useModal } from "../../contexts/ModalContext";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";

const LendingList = () => {
  const { openModal, onClose } = useModal();

  function handleBuilder(data, head) {
    return data[head.name] ? "Entregado" : "Prestado";
  }

  function handleBuilder(data, head) {
    if (data) {
      return "Entregado";
    }
    return "Prestado";
  }

  function handleOpenModal(reload) {
    openModal(
      <FormLending reload={reload} openModal={openModal} onClose={onClose} />
    );
  }

  function handleReturn(loan, reload) {
    openModal(<FormReturn loan={loan} reload={reload} onClose={onClose} />);
  }

  return (
    <CallServiceTable
      component={({ reload }) => {
        return (
          <Button
            className="text-base py-2"
            onClick={() => handleOpenModal(reload)}
          >
            Prestar carpeta <i className="fa-solid fa-plus"></i>
          </Button>
        );
      }}
      urlApi="/loans"
      header={[
        {
          name: "internCode",
          label: "Tramite",
          filter: true,
        },
        {
          name: "dimNumber",
          label: "DIM/DEX",
          filter: true,
        },
        {
          name: "nameCompany",
          label: "Cliente",
        },
        {
          name: "reason",
          label: "Motivo",
          filter: false,
        },
        {
          name: "fullNameGiverUser",
          label: "Prestamista",
          filter: false,
        },
        {
          name: "fullNameRequestingUser",
          label: "Solicitante",
          filter: false,
        },
        {
          name: "loanDate",
          label: "Fecha de préstamo",
          type: "date",
          filter: false,
        },
        {
          name: "tentativeDate",
          label: "Fecha tentativa de devolución",
          type: "date",
          filter: false,
        },
        {
          name: "returnObservation",
          label: "Estado",
          type: "custom",
          builder: handleBuilder,
          filter: false,
        },
        {
          name: "returnDate",
          label: "Fecha de devolución",
          type: "date",
        },
      ]}
      addUrl={`type=giver&userId=${window.localStorage.getItem("id")}`}
      onClick={handleReturn}
    />
  );
};

const FormLending = (props) => {
  const { openModal } = props;
  function handleProcedure(procedure) {
    openModal(<FormAddLending {...props} procedure={procedure} />);
  }

  return (
    <>
      <H2>Lista de tramites terminados</H2>
      <CallServiceTable
        urlApi="/loans/procedures"
        header={[
          {
            name: "name",
            label: "Tramite",
            filter: true,
          },

          {
            name: "companyName",
            label: "Compañía",
            filter: false,
          },
          {
            name: "currentStep",
            label: "Paso actual",
            filter: false,
          },
        ]}
        onClick={handleProcedure}
      />
    </>
  );
};

const FormAddLending = (props) => {
  const { procedure, onClose, reload } = props;

  return (
    <FormDefault
      tittle={`Prestar el trámite (${procedure.name || procedure.internCode})`}
      fields={[
        {
          label: "Motivo del préstamo",
          name: "Reason",
          placeholder: "Introduzca el motivo del préstamo",
          type: "text",
          typeInput: "input",
        },
        {
          label: "Seleccione el usuario solicitante",
          name: "RequestingUserId",
          typeInput: "select",
          urlApi: "/rol/PrestamoDeCarpetas",
          value: "id",
          labelOption: "name",
        },
        {
          label: "fecha tentativa de devolución",
          name: "TentativeDate",
          placeholder: "fecha tentativa de devolución",
          type: "date",
          typeInput: "input",
        },

        {
          label: "Observaciones",
          name: "Observation",
          placeholder: "Introduzca las observaciones",
          type: "text",
          typeInput: "input",
        },
      ]}
      initialValues={{
        Reason: "",
        RequestingUserId: "",
        TentativeDate: "",
        Observation: "",
      }}
      validationSchema={Yup.object().shape({
        Reason: Yup.string().required("Este campo es obligatorio"),
        RequestingUserId: Yup.string().required("Este campo es obligatorio"),
        TentativeDate: Yup.string().required("Este campo es obligatorio"),
        Observation: null,
      })}
      onSubmit={(values) => {
        requestAuth("post", `/loans/procedures/${procedure.id}`, values)
          .then(() => {
            reload();
            onClose();
            toast.success("Préstamo agregado correctamente!!!");
          })
          .catch(() => {
            toast.error("Se ha producido un error");
          });
      }}
      buttonName="Enviar"
    />
  );
};

const FormReturn = (props) => {
  const { loan, reload, onClose } = props;
  const TextCustom = ({ title, data }) => (
    <H3 className="text-start">
      {title}: <span className="font-medium">{data}</span>
    </H3>
  );

  async function uploadFile(values) {
    let formData = new FormData();
    formData.append("Name", values.file.name);
    formData.append("File", values.file);
    formData.append("originalName", values.file.name);
    formData.append("Status", "verified");
    // formData.append("ProcedureId", field.id);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        onSubmit({ ...values, file: res.data.file });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function onSubmit(values) {
    requestAuth("put", `/loans/${loan.id}`, values)
      .then(() => {
        reload();
        onClose();
        toast.success("Préstamo agregado correctamente!!!");
      })
      .catch(() => {
        toast.error("Se ha producido un error");
      });
  }

  return (
    <div>
      {loan.returnObservation ? (
        <>
          <H1>Detalles del préstamo</H1>
          <TextCustom title="Nro. de trámite" data={loan.nameProcedure} />
          <TextCustom title="Solicitante" data={loan.fullNameRequestingUser} />
          <H3 className="text-start">
            Fecha de devolución:{" "}
            <span className="font-medium">
              {dayjs(loan.returnDate).format("DD/MM/YYYY HH:mm")}
            </span>
          </H3>
          <br />
          <H3 className="text-start">OBSERVACIONES</H3>
          {loan.observation && (
            <H3 className="text-start">
              Observaciones:{" "}
              <span className="font-medium">{loan.observation}</span>
            </H3>
          )}
          {loan.returnObservation && (
            <H3 className="text-start">
              Observaciones de la devolución:{" "}
              <span className="font-medium">{loan.returnObservation}</span>
            </H3>
          )}
        </>
      ) : (
        <FormDefault
          tittle={`Formulario de devolución del trámite (${loan.internCode})`}
          fields={[
            {
              label: "Comprobante de devolución",
              name: "file",
              type: "file",
              typeInput: "file",
            },
            {
              label: "Observaciones de la devolución",
              name: "ReturnObservation",
              placeholder: "Observaciones de la devolución",
              type: "textArea",
              typeInput: "input",
            },
          ]}
          initialValues={{
            ReturnObservation: "",
            file: "",
          }}
          validationSchema={Yup.object().shape({
            ReturnObservation: Yup.string().required(
              "Este campo es obligatorio"
            ),
          })}
          // onSubmit={(values) => {
          //   requestAuth("put", `/loans/${loan.id}`, values)
          //     .then(() => {
          //       reload();
          //       onClose();
          //       toast.success("Préstamo agregado correctamente!!!");
          //     })
          //     .catch(() => {
          //       toast.error("Se ha producido un error");
          //     });
          // }}
          onSubmit={(values) =>
            typeof values.file === "string"
              ? onSubmit(values)
              : uploadFile(values)
          }
          buttonName="Enviar"
        />
      )}
    </div>
  );
};

export default LendingList;
