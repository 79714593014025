import React from 'react'
import * as dayjs from "dayjs";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useModal } from "../../../contexts/ModalContext";
import { H1, Anchor } from "../../../components/Text";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";

const AddDocument = (props) => {

    async function onSubmit(values) {
        const { companyID, onReload } = props;
    
        let formData = new FormData();
        formData.append("Name", values.Name);
        formData.append("File", values.File);
        formData.append("originalName", values.File.name);
        if (values.ExpirationDate) {
          formData.append(
            "ExpirationDate",
            dayjs(values.ExpirationDate).format("DD-MM-YYYY")
          );
        }
        formData.append("File", values.File);
        formData.append("Type", "File");
        formData.append("CompanyId", companyID);
        await requestAuth("post", "/files", formData)
          .then(() => {
            toast.success("Envio exitoso");    
            onReload();            
          })
          .catch(() => {
            toast.error("Se produjo un error al subir el archivo");
          });
      }

  return (
    <div>
        <H1>Adjuntar documento</H1>
        <FormDefault
          fields={[
            {
              label: "Nombre del documento",
              type: "text",
              name: "Name",
              typeInput: "input",
            },
            {
              label: "Adjuntar un archivo",
              name: "File",
              type: "file",
              typeInput: "file",
            },
            {
              label: "Fecha de vencimiento",
              type: "date",
              name: "ExpirationDate",
              typeInput: "input",
            },
          ]}
          initialValues={{
            Name: "",
            File: "",
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required("Este campo es obligatorio"),
            File: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={onSubmit}
          buttonName="Enviar"
        />
    </div>
  )
}

export default AddDocument;