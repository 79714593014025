// import { useAuth } from "../../contexts/AuthContext";

const Anchor = (props) => {
  const { children, href, ...rest } = props;
  // const { user } = useAuth();
  // user?.institutionParam
  return (
    <a
      href={href[0] === "/" ? `${window.location.origin}${href}` : href}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Anchor;
