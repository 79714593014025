import React from "react";
import { headerList } from "../../utils/constant";
import { useParams } from "react-router-dom";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";

import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";

const History = () => {
  const { rolId } = useParams();
  const { openDialog, dialogClose } = useDialog();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  return (
    <CallServiceTable
      urlApi="/procedure/user"
      filters={[
        {
          name: "companyId",
          url: "/company/getallcompanies",
          default: "Todas las compañías",
          valueOption: "id",
          labelOption: "razonSocial",
        },
      ]}
      addFilters={[
        {
          name: "product",
          label: "Mercadería",
          filter: true,
        },
      ]}
      header={headerList}
      // product
      // header={[
      //   {
      //     name: 'procedureId',
      //     label: 'Trámite',
      //     filter: true,
      //   },
      //   {
      //     name: 'internCode',
      //     label: 'Nro. interno',
      //   },
      //   {
      //     name: 'nro138',
      //     label: 'Nro. form 138',
      //   },
      //   {
      //     name: 'adjuntarDimDexFirmada',
      //     label: 'DIM',
      //     type: 'custom',
      //     builder: (item) => {
      //       if (item) {
      //         return (
      //           <a
      //             href={item}
      //             className="text-[#1d4ed8] underline"
      //             target="_blank"
      //             rel="noopener noreferrer"
      //           >
      //             Abrir
      //           </a>
      //         )
      //       } else {
      //         return '-'
      //       }
      //     },
      //   },
      //   {
      //     name: 'companyName',
      //     label: 'Compañía',
      //   },
      //   {
      //     name: 'assignedLiquidator',
      //     label: 'Liquidador asignado',
      //   },
      //   {
      //     name: 'stepName',
      //     label: 'Paso actual',
      //   },
      //   {
      //     name: 'descripcionMercancias',
      //     label: 'Mercaría',
      //   },
      //   {
      //     name: 'Reports',
      //     type: 'action',
      //     label: 'Reportes',
      //     sticky: true,
      //     actions: [
      //       {
      //         label: 'Generar informe de la recepción de documentos',
      //         icon: 'fas fa-tasks',
      //         action: listDocumentPdf,
      //         color: 'text-[#1d4ed8]',
      //       },
      //       {
      //         label: 'Generar liquidación - proforma',
      //         icon: 'fas fa-coins',
      //         action: generateSettlementProforma,
      //         color: 'text-[#1d4ed8]',
      //       },
      //       {
      //         label: 'Generar hoja de ruta',
      //         icon: 'fas fa-route',
      //         action: generateRoadmap,
      //         color: 'text-[#1d4ed8]',
      //       },
      //     ],
      //   },
      //   // {
      //   //   name: 'procedureTypeName',
      //   //   label: 'Tipo de Proceso',
      //   //   filter: false,
      //   // },
      // ]}
      addUrl={`designation=liquidator&rolId=${rolId}`}
      statusPaination={true}
    />
  );
};

export default History;
