import { toast } from "react-toastify";
import * as Yup from "yup";
import FormDefault from "../../../components/Forms/FormDefault";
import { requestAuth } from "../../../components/services/RequestService";

const EditCompany = (props) => {
  const { companyInfo, onReload } = props;

  return (
    <div>
      <FormDefault
        tittle="Editar datos de la compañía"
        fields={[
          {
            label: "Id de compañia",
            name: "num",
            typeInput: "input",
          },
          {
            label: "Sigla",
            name: "sigla",
            typeInput: "input",
          },
          {
            label: "Número de Identificación Tributaria (NIT)",
            name: "nit",
            typeInput: "input",
          },

          {
            label: "Razón social",
            name: "razonSocial",
            typeInput: "input",
          },
          {
            label: "Correo electrónico",
            name: "email",
            typeInput: "input",
          },
          {
            label: "Tipo cliente",
            name: "tipoCliente",
            typeInput: "input",
          },
          {
            label: "Pais",
            name: "pais",
            typeInput: "input",
          },
          {
            label: "Departamento",
            name: "departamento",
            typeInput: "input",
          },
          {
            label: "Ciudad",
            name: "ciudad",
            typeInput: "input",
          },
          {
            label: "Domicilio legal",
            name: "domicilioLegal",
            typeInput: "input",
          },
          {
            label: "Clasificación Cliente",
            name: "clasificacionCliente",
            typeInput: "input",
          },
          {
            label: "Actividad",
            name: "actividad",
            typeInput: "input",
          },
          {
            label: "PagaTributos",
            name: "pagaTributos",
            typeInput: "input",
          },
          {
            label: "Descripción Negocio",
            name: "descripcionNegocio",
            typeInput: "input",
          },
        ]}
        initialValues={{
          id: companyInfo?.id || "",
          nit: companyInfo?.nit || "",
          razonSocial: companyInfo?.razonSocial || "",
          email: companyInfo?.email || "",
          num: companyInfo?.num || "",
          sigla: companyInfo?.sigla || "",
          tipoCliente: companyInfo?.tipoCliente || "",
          pais: companyInfo?.pais || "",
          departamento: companyInfo?.departamento || "",
          ciudad: companyInfo?.ciudad || "",
          domicilioLegal: companyInfo?.domicilioLegal || "",
          clasificacionCliente: companyInfo?.clasificacionCliente || "",
          actividad: companyInfo?.actividad || "",
          pagaTributos: companyInfo?.pagaTributos || "",
          descripcionNegocio: companyInfo?.descripcionNegocio || "",
        }}
        validationSchema={Yup.object().shape({
          nit: Yup.string().required("Este campo es obligatorio"),
          razonSocial: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values, resetForm) => {
          await requestAuth("put", "company", values)
            .then(() => {
              onReload();
              toast.success("Envio exitoso");
            })
            .catch(() => {
              toast.error("Se ha producido un error al enviar");
            });
        }}
        buttonName="Enviar"
      />
    </div>
  );
};

export default EditCompany;
