import React from "react";
import { Bar } from "react-chartjs-2";
import { validateArray } from "../../utils/validate";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SimpleChartVertical = ({ data, cantidad, listY, title, onClick, generalData, setIt }) => {
  const footer = (tooltipItems) => {
    return validateArray(cantidad)
      ? `Cantidad T.: ${cantidad[tooltipItems[0].dataIndex]}`
      : null;
  };

  return (
    <Bar
      options={{
        plugins: {
          title: {
            display: false,
            text: title,
          },
          tooltip: {
            callbacks: {
              footer: footer,
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        onClick: function (_, element) {
          setIt(generalData?.dataCols[element[0].index])
          console.log({ year: generalData.year, fieldname: generalData.fieldname, info: generalData?.dataCols[element[0].index], numMonth: generalData.numMonth })
          if (generalData.onClick) {
            generalData.onClick({ year: generalData.year, fieldname: generalData.fieldname, info: generalData?.dataCols[element[0].index], numMonth: generalData.numMonth })
          }
          if (validateArray(element)) {
            onClick(listY[element[0].index], null);
          }
        },
      }}
      data={{
        labels: listY.map((item) => item.label),
        datasets: [
          {
            label: title,
            data: data,
            backgroundColor: "rgb(75, 192, 192)",
          },
        ],
      }}
    />
  );
};

export default SimpleChartVertical;
