import * as Yup from "yup";
import FormDefault from "../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import { Anchor, H3 } from "../../components/Text";
import { requestAuth } from "../../components/services/RequestService";

export const ImportProcedureExcel = ({ openDialog, companyId }) => {
  async function onSubmit(values) {
    let formData = new FormData();
    formData.append("File", values.file);
    await requestAuth("post", `/Agda/readDetailPictureXlsx`, formData)
      .then((res) => {
        toast.success("Registro exitoso");
        // viewLinkExcel(res.data);
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            download
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }

  return (
    <div>
      <FormDefault
        tittle="Importar datos de excel"
        fields={
          companyId
            ? [
                {
                  name: "FromDate",
                  label: "Fecha de inicio",
                  type: "date",
                },
                {
                  name: "ToDate",
                  label: "Fecha fin",
                  type: "date",
                },
                // {
                //   name: "PartidaArancelaria",
                //   label: "Partida arancelaria",
                // },
              ]
            : [
                {
                  name: "file",
                  label: "Archivo de excel",
                  typeInput: "file",
                  type: "file",
                },
                // {
                //   label: "Compañia",
                //   name: "CompanyId",
                //   typeInput: "select",
                //   urlApi: "/company/getallcompanies",
                //   value: "id",
                //   labelOption: "razonSocial",
                // },
                // {
                //   name: "FromDate",
                //   label: "Fecha de inicio",
                //   type: "date",
                // },
                // {
                //   name: "ToDate",
                //   label: "Fecha fin",
                //   type: "date",
                // },
                // {
                //   name: "PartidaArancelaria",
                //   label: "Partida arancelaria",
                // },
              ]
        }
        initialValues={{
          Management: 2024,
          CompanyId: companyId ?? "",
        }}
        validationSchema={Yup.object().shape({
          Management: Yup.number().required("Campo requerido."),
        })}
        onSubmit={onSubmit}
        buttonName="Generar"
      />
      <br />
    </div>
  );
};
