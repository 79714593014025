import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tippy'
const LinkBoxNavBar = (props) => {
  const {
    children,
    icon,
    active,
    to = '',
    setNavsMenu,
    navsmenu,
    pending = null,
    step,
    ...rest
  } = props

  const LinkNav = ({ portrait = false }) => (
    <Link
      className={
        active
          ? 'duration-300 rounded-l-[30px] text-lg 2xl:text-xl font-medium flex gap-1 items-center py-[10px] ml-1 pl-2 bg-white text-[#000000] overflow-clip translate-x-2 portrait:translate-x-0'
          : 'text-lg 2xl:text-xl font-medium flex gap-1 items-center py-[10px] ml-1 pl-1 bg-inherit overflow-clip'
      }
      to={to}
      {...rest}
      onClick={portrait ? () => setNavsMenu(!navsmenu) : null}
    >
      {pending === null ? (
        <>
          <FontAwesomeIcon
            icon={icon}
            className="text-xl text-black pr-3 pl-4"
          />
          <h4 className="text-base">{children}</h4>
        </>
      ) : (
        <div className="flex items-center justify-between w-full">
          <h4 className="text-base">
            <span className={active ? 'text-blue-700' : 'text-white'}>
              {step}.
            </span>{' '}
            {children}
          </h4>
          <span
            className={`text-base px-2 py-0 ${
              pending === 0 ? 'bg-blue-700' : 'bg-red-500'
            } text-white ml-1 rounded-lg`}
          >
            {pending}
          </span>
        </div>
      )}
    </Link>
  )

  return (
    <>
      <div className="hidden portrait:block">
        <LinkNav portrait={true} />
      </div>
      <div className="block portrait:hidden">
        <Tooltip title={children} position="left" trigger="mouseenter">
          <LinkNav />
        </Tooltip>
      </div>
    </>
  )
}

export default LinkBoxNavBar
