import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import RenderProforma from "../../containers/ManegeProcedure/RenderProforma";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import { validateArray } from "../../utils/validate";
import { Tooltip } from "react-tippy";
import ListDocument from "../../components/common/ListDocument";
import ListDocumentVerify from "../../components/common/ListDocumentVerify";

const TableLisDocument = ({ listButton, procedure }) => {
  const { openDialog, dialogClose } = useDialog();
  const { openModal, onClose } = useModal();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  }

  function generateProforma(procedure) {
    openDialog(<RenderProforma procedure={procedure} />);
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }
  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} />);
  }
  function handleDocumentVerify(procedure) {
    openModal(<ListDocumentVerify procedure={procedure} />);
  }

  let listGeneralButtons = [
    {
      label: "Generar informe de la recepción de documentos",
      icon: "fas fa-tasks",
      Name: "recepcionDeDocumentos",
      action: listDocumentPdf,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar liquidación - proforma",
      icon: "fas fa-coins",
      Name: "proforma",
      action: generateProforma,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar hoja de ruta",
      icon: "fas fa-route",
      Name: "hojaDeRuta",
      action: generateRoadmap,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Ver documentos",
      icon: "fa-solid fa-folder",
      Name: "verDocumentos",
      action: handleDocument,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Verificar documentos",
      icon: "fa-solid fa-list-check",
      Name: "verificarDocumentos",
      action: handleDocumentVerify,
      color: "text-[#1d4ed8]",
    },
  ];

  return (
    <>
      {validateArray(listButton)
        ? listButton.map((btn, index) => {
            const button = listGeneralButtons.find(
              (element) => element.Name === btn.Name
            );
            if (button === undefined) {
              return null;
            }
            return (
              <div
                key={index}
                className={`${button.color} text-xl cursor-pointer`}
                onClick={() => button.action(procedure)}
              >
                <Tooltip
                  title={button.label}
                  position="right"
                  trigger="mouseenter"
                >
                  <i className={button.icon + " p-1"}></i>
                </Tooltip>
              </div>
            );
          })
        : null}
    </>
  );
};

export default TableLisDocument;
