import React from 'react'
import { toast } from 'react-toastify'
import { requestAuth } from '../../components/services/RequestService'
import Texto from '../../components/Text/Texto'
import SubModal from '../../components/Modal/SubModal'

const Delete = ({
  setRemov,
  activateForm,
  setreload,
  reload,
  infoMenus,
  remov,
}) => {
  return (
    <SubModal setactivateForm={setRemov} activateForm={remov}>
      <div className="flex flex-col justify-center py-[30px]">
        <Texto className="font-medium">
          <strong className="text-red-500">Se eliminara :</strong>{' '}
          {infoMenus?.name}
        </Texto>
        <Texto className="font-medium">
          <strong className="text-red-500">Con el Id :</strong> {infoMenus?.id}
        </Texto>
        <button
          type="submit"
          className="my-[10px] md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[20px] leading-6 bg-[red] text-center text-white py-[15px]"
          onClick={() => {
            requestAuth('delete', '/menu', { Id: infoMenus?.id }).then(() => {
              setreload(!reload)
              toast.success('Campo eliminado')
              setRemov(!remov)
            })
          }}
        >
          Eliminar
        </button>
        <button
          type="button"
          onClick={() => setRemov(false)}
          className="my-[10px] md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[20px] leading-6 bg-[#FBCE00] text-center py-[15px]"
        >
          Cancelar
        </button>
      </div>
    </SubModal>
  )
}

export default Delete
