import CallServiceTable from "../../../components/Tables/CallServiceTable";
import Button from "../../../components/Buttons/Button";
import { useModal } from "../../../contexts/ModalContext";
import { CreateUser, UserDetail } from "../../../containers/menu/manegeUsers";
import { useDialog } from "../../../contexts/DialogContext";
import { DialogConfirmation } from "../../../components/Modal";
import { H3 } from "../../../components/Text";

const ManageUsers = () => {
  const { openModal, onClose } = useModal();
  const {openDialog, dialogClose}= useDialog();

  function handleUserDetail(user, reload) {
    openModal(<UserDetail userId={user.id} reload={reload} />);
  }
  function handleNewUser(reload) {
    openModal(<CreateUser onClose={onClose} onReload={reload} />)
  }
  function sendEmail(user) {    
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres reenviar las credenciales de {user.name}?</H3>}
        onClose={dialogClose}
        method="put"
        url={`/auth/user/${user.id}/credentials`}
        texBtn="Reenviar"
      />  
    );  
  }

  return (
    <CallServiceTable
      onClick={handleUserDetail}
      urlApi="/users/ListUsers"
      addFilters={[
        {
          name: "firstName",
          label: "Nombres",
          filter: true,
        },
      ]}
      component={({ reload }) => {
        return (
          <Button
            className="text-lg py-3"
            onClick={() => handleNewUser(reload)}
          >
            Crear usuario <i className="fa-solid fa-plus"></i>
          </Button>
        );
      }}
      header={[
        {
          name: "name",
          label: "Nombres",
          filter: false,
        },
        {
          name: "fatherLastName",
          label: "Apellido Paterno",
          filter: true,
        },
        {
          name: "motherLastName",
          label: "Apellido Materno",
          filter: true,
        },
        {
          name: "email",
          label: "Correo",
          filter: false,
        },
        {
          name: "status",
          label: "Estado",
          filter: false,
        },
        {
          name: "edit",
          type: "action",
          label: "Enviar credenciales",
          actions: [
            {
              label: "Reenviar credenciales",
              icon: "fas fa-mail-bulk",
              action: sendEmail,
              color: "text-[#1d4ed8]",
            },
          ],
        },
      ]}
      addUrl={`userId=${localStorage.getItem("id")}`}
      filters={[
        {
          url: "/rol",
          name: "RolName",
          default: "Todos los roles",
        },
      ]}
    />
  );
};

export default ManageUsers;
