import { H3 } from "../../components/Text";
import { Button } from "../../components/Buttons";
import { DialogConfirmation } from "../../components/Modal";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import { Request } from "../../hooks/Request";
import { headerList } from "../../utils/constant";
import { useState } from "react";
import { validateArray } from "../../utils/validate";
import PdfDocumentRdr from "../../containers/ManegeProcedure/PdfDocumentRdr";
import PdfTemplate from "../../containers/ManegeProcedure/PdfTemplate";
import * as dayjs from "dayjs";
import { Circle } from "../../components/Animation";
import { useAuth } from "../../contexts/AuthContext";

const ManageLots = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  const { user } = useAuth();

  function createLot(reload) {
    openModal(<FormLot reload={reload} onClose={onClose} />);
  }

  function editLot(lot, reload) {
    if (lot.status !== "Creado") {
      toast.warning("No se puede editar el lote por que ya fue entregado");
      return;
    }
    openModal(<FormEditLot lot={lot} reload={reload} onClose={onClose} />);
  }

  function changeState(lot, reload) {
    const formData = {
      ...lot,
      status: "Entregado",
      num: lot.num,
      id: lot.id,
      userId: lot.userId,
    };

    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quiere entregar el lote?</H3>}
        onClose={dialogClose}
        method="put"
        data={formData}
        url={`/lot`}
        texBtn="Confirmar"
        // handleSuccess={reload}
        handleSuccess={() => cambiarEstado(lot, reload)}
      />
    );
  }

  async function cambiarEstado(lot, reload) {
    try {
      const procedures = await requestAuth("get", `/lot/${lot.id}`);

      for (let i = 0; i < procedures?.data?.lotProcedures?.length; i++) {
        const procedure = procedures?.data?.lotProcedures[i];
        const response = await requestAuth(
          "get",
          `/Procedure/${procedure.procedureId}/datasets?fieldnames=asignarGestor&fieldnames=estadoDeLaCarpeta`
        );

        const formDataState = [
          {
            value: "Entregado",
            procedureId: procedure.procedureId,
            fieldId: response.data[1].id,
          },
          {
            value: `${user.user.name} ${user.user.fatherLastName} ${user.user.motherLastName}`,
            procedureId: procedure.procedureId,
            fieldId: response.data[0].id,
          },
        ];
        await requestAuth("POST", `/dataSet`, formDataState)
          .then((res) => {
            // toast.success("Se guardaron los cambios");
            reload();
          })
          .catch(() => {
            toast.error("Se produjo un error al guardar");
          });
      }
    } catch (error) {
      toast.error("Se produjo un error al guardar");
    }
  }

  function deleteLot(lot, reload) {
    // if(lot.status!=="Creado"){
    //   toast.warning("No se puede eliminar el lote por que ya fue entregado");
    //   return;
    // }
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el lote?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Lot/${lot.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }

  function handleDocumentRdrPdf(lot) {
    openDialog(<PdfDocumentRdr lot={lot} />);
  }

  function editLotObs(lot, reload) {
    // if(lot.status!=="Creado"){
    //   toast.warning("No tiene los permisos para ver el lote");
    //   return;
    // }
    openModal(<FormEditObs lot={lot} reload={reload} onClose={onClose} />);
  }

  return (
    <>
      <CallServiceTable
        addUrl="order=Creado-Entregado-Recibido-Concluido&type=Gestor"
        component={({ reload }) => {
          return (
            <Button className="py-2" onClick={() => createLot(reload)}>
              Crear Lote <i className="fa-solid fa-plus"></i>
            </Button>
          );
        }}
        urlApi="/lot/pag"
        filters={[
          {
            name: "Status",
            label: "Todos los estados",
            default: "Todos los estados",
            options: [
              {
                label: "Creado",
                name: "Creado",
              },
              {
                label: "Entregado",
                name: "Entregado",
              },
              {
                label: "Recibido",
                name: "Recibido",
              },
              {
                label: "Concluido",
                name: "Concluido",
              },
            ],
          },
          {
            name: "userId",
            url: "/Dropdown/rol_gestor/options",
            label: "Todos los gestores",
            valueOption: "id",
            labelOption: "name",
          },
          // {
          //   name: "userFullName",
          //   url: "/Users/all",
          //   label: "Todos los gestores",
          //   valueOption: "fullName",
          //   labelOption: "userFullName",
          // },
        ]}
        header={[
          {
            label: "Nro de lote",
            name: "num",
            filter: true,
          },
          {
            label: "Fecha de Creación",
            name: "creationDate",
            type: "date",
          },
          {
            label: "Estado",
            name: "status",
            type: "custom",
            builder: (status) => {
              const baground =
                status === "Creado" ? "bg-blue-800" : "bg-green-600";

              const state = status === "Creado" ? "Creado" : "Entregado";

              return (
                <span
                  className={`block w-fit px-2 py-1 rounded-full text-white ${baground}`}
                >
                  {state}
                </span>
              );
            },
          },
          {
            label: "Gestor",
            name: "userFullName",
            filter: true,
          },
          {
            name: "lotProcedures",
            label: "Carpetas",
            type: "custom",
            builder: (data, sd, lot) => {
              return (
                <div className="">
                  {data && validateArray(data)
                    ? data.map((item) => (
                        <div key={item.id} className="flex flex-wrap">
                          <div className="flex ">
                            <p
                              className={`border-2 border-gray-500 ${
                                item.estadoDeLaCarpeta === "Sub. para Archivo"
                                  ? "bg-teal-600 text-white"
                                  : item.estadoDeLaCarpeta ===
                                    "Obs. por Archivo"
                                  ? "bg-red-600 text-white animate-pulse"
                                  : ""
                              } rounded-md px-1`}
                            >
                              {item.nroInterno || "---"}
                            </p>
                            {item.estadoDeLaCarpeta === "Obs. por Archivo" ? (
                              <span className="text-red-500 whitespace-nowrap ml-1">
                                Hace{" "}
                                {dayjs().diff(
                                  dayjs(item.obsCreationDate),
                                  "day"
                                ) !== 0
                                  ? dayjs().diff(
                                      dayjs(item.obsCreationDate),
                                      "day"
                                    ) + " días"
                                  : dayjs().diff(
                                      dayjs(item.obsCreationDate),
                                      "hour"
                                    ) !== 0
                                  ? dayjs().diff(
                                      dayjs(item.obsCreationDate),
                                      "hour"
                                    ) + " horas"
                                  : dayjs().diff(
                                      dayjs(item.obsCreationDate),
                                      "minute"
                                    ) + " minutos"}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              );
            },
          },
          {
            name: "id",
            label: "Entregar",
            type: "custom",
            builder: (_, __, lot, call) => {
              return lot.status === "Creado" ||
                lot.status === "CorregidoPre" ? (
                <div className="flex justify-center">
                  <button
                    className="block p-2 text-white rounded-lg bg-green-600 hover:bg-green-700"
                    onClick={() => changeState(lot, call)}
                  >
                    Entregar
                  </button>
                </div>
              ) : lot.status === "ObsArchivo" || lot.status === "Retornado" ? (
                " "
              ) : (
                <div className="bg-gray-100 border border-gray-500 p-1 px-2">
                  <p className="text-center">Entregado</p>
                  <p className="text-center">
                    {dayjs(lot.deliveryDate).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
              );
            },
          },
          {
            name: "edit",
            type: "action",
            label: "Acciones",
            actions: [
              {
                label: "Revisar Carpetas",
                icon: "fas fa-eye",
                action: editLotObs,
                color: "text-[#585858]",
              },
              {
                label: "Editar",
                icon: "fas fa-edit",
                action: editLot,
                color: "text-[#1d4ed8]",
              },
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: deleteLot,
                color: "text-red-500",
              },
              {
                label: "Generar RDR-003",
                icon: "fas fa-file-pdf",
                action: handleDocumentRdrPdf,
                color: "text-[#FF8257]",
              },
            ],
          },
        ]}
      />
    </>
  );
};

const FormEditObs = (props) => {
  const { lot, onClose, reload } = props;

  const { data } = Request({
    urlApi: `/lot/${lot.id}/lotProcedures`,
  });

  return (
    <>
      <div className="flex gap-5 justify-center">
        <h2 className="text-2xl font-bold">Lista de Carpetas</h2>
      </div>
      <div className="my-10 mx-5">
        {data?.map((procedure) => (
          <Procedure
            key={procedure.id}
            procedure={procedure}
            lot={lot}
            reload={reload}
          />
        ))}
      </div>

      <Button className="py-2 block mx-auto" onClick={() => onClose()}>
        Cerrar
      </Button>
    </>
  );
};

const Procedure = ({ procedure, lot, reload }) => {
  const { data, loading } = Request({
    urlApi: `/Procedure/${procedure.id}/datasets?fieldnames=observacionesDeLasCarpeta&fieldnames=estadoDeLaCarpeta&fieldnames=asignarGestor`,
  });

  if (!loading) {
    return <Circle />;
  }

  return (
    <div className="bg-white rounded-md px-5 py-2 mb-2">
      <Observations
        fields={data}
        procedure={procedure}
        lot={lot}
        reload={reload}
      />
    </div>
  );
};

const Observations = ({ fields, procedure, reload, lot }) => {
  const { data, call, loading } = Request({
    urlApi: `/dataSet/procedure/${procedure.procedureId}/field/${fields[1].id}/subdata`,
  });

  const { openDialog, dialogClose } = useDialog();

  const [state, setState] = useState(procedure.estadoDeLaCarpeta);

  if (!loading) {
    return <Circle />;
  }

  const obsArchivo = data.filter(
    (item) => item.label === "Observaciones de Archivo"
  );
  const obsGestor = data.filter(
    (item) => item.label === "Observaciones del Gestor"
  );

  if (state === "verificar") {
    changeStateSubsanado();
  }

  async function changeStateSubsanado() {
    if (obsArchivo?.some((item) => item.description4 === "Observado")) {
      const formDataState = [
        {
          value: "Obs. por Archivo",
          procedureId: procedure.procedureId,
          fieldId: fields[2].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    } else if (obsArchivo?.some((item) => item.description4 === "Subsanado")) {
      const formDataState = [
        {
          value: "Sub. para Archivo",
          procedureId: procedure.procedureId,
          fieldId: fields[2].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    } else if (obsGestor?.some((item) => item.description4 === "Observado")) {
      const formDataState = [
        {
          value: "Observado",
          procedureId: procedure.procedureId,
          fieldId: fields[2].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    } else if (obsGestor?.some((item) => item.description4 === "Subsanado")) {
      const formDataState = [
        {
          value: "Subsanado",
          procedureId: procedure.procedureId,
          fieldId: fields[2].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    } else {
      const formDataState = [
        {
          value: null,
          procedureId: procedure.procedureId,
          fieldId: fields[2].id,
        },
      ];
      await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          // toast.success("Se guardaron los cambios");
          reload();
          setState(procedure.estadoDeLaCarpeta);
        })
        .catch(() => {
          toast.error("Se produjo un error al guardar");
        });
    }
  }

  function correctObservation(obs) {
    const formData = {
      ...obs,
      description4: "Subsanado",
    };

    openDialog(
      <DialogConfirmation
        children={<H3>¿Esta seguro que subsano la observación?</H3>}
        onClose={dialogClose}
        method="PUT"
        url={`/dataSet/procedure/subData/${obs.id}`}
        texBtn="Subsanar"
        handleSuccess={refresh}
        data={formData}
      />
    );
  }

  function handleTemplatePdf(obs) {
    openDialog(<PdfTemplate procedure={procedure} obs={obs} lot={lot} />);
  }

  async function refresh() {
    reload();
    call();
    setState("verificar");
    call();
  }

  return (
    <div className="">
      <div className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between items-center">
        <div>
          <p>
            <span className="font-bold">Nro. Interno: </span>
            {procedure.nroInterno}
          </p>
          <p>
            <span className="font-bold">Cliente: </span>
            {procedure.companyRazonSocial || "---"}
          </p>
        </div>
      </div>

      {obsArchivo?.map((obs) =>
        obs.description4 === "Observado" ? (
          <div key={obs.id} className="bg-slate-200 rounded-md px-5 py-2 mb-2">
            <div className="flex gap-2 justify-between items-center">
              <div className="text-xs text-slate-500">
                <p>Observado por: {obs.description3}</p>
                <p>
                  En fecha: {dayjs(obs.creationDate).format("DD/MM/YYYY HH:mm")}
                </p>
              </div>
              <div>
                <p className="text-red-500 text-center font-bold">Observado</p>
                <div className="flex justify-center gap-1">
                  <button
                    onClick={() => correctObservation(obs)}
                    className="block bg-teal-500 hover:bg-teal-600 text-white text-sm rounded-full px-2 py-1"
                  >
                    <i className="fa-solid fa-check text-white mr-2"></i>
                    Subsanar
                  </button>
                  <button
                    onClick={() => handleTemplatePdf(obs)}
                    className="block bg-orange-600 hover:bg-orange-700 text-white text-sm rounded-full px-2 py-1"
                  >
                    <i className="fas fa-file-pdf text-white mr-2"></i>
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <div className="p-5 bg-red-300 border border-red-600 rounded-lg mt-5">
              <p>{obs.description}</p>
            </div>
          </div>
        ) : (
          <div key={obs.id} className="bg-slate-200 rounded-md px-5 py-2 mb-2">
            <div className="flex gap-2 justify-between items-center">
              <div className="text-xs text-slate-500">
                <p>Observado por: {obs.description3}</p>
                <p>
                  En fecha: {dayjs(obs.creationDate).format("DD/MM/YYYY HH:mm")}
                </p>
              </div>
              <div>
                <p className="text-teal-500 text-center font-bold">Subsanado</p>
                <div className="flex justify-center gap-1">
                  <button
                    onClick={() => handleTemplatePdf(obs)}
                    className="block bg-teal-500 hover:bg-teal-600 text-white text-sm rounded-full px-2 py-1"
                  >
                    <i className="fas fa-file-pdf text-white mr-2"></i>
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <div className="px-5 pt-5 pb-2 bg-teal-300 border border-teal-600 rounded-lg mt-5">
              <p>{obs.description}</p>
              <div className="mt-2 flex justify-end">
                <div className="text-xs text-slate-500">
                  <p>Subsanado por: {obs.description2}</p>
                  <p>
                    En fecha: {dayjs(obs.endDate).format("DD/MM/YYYY HH:mm")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

const FormEditLot = (props) => {
  const { lot, onClose, reload } = props;
  const { openDialog, dialogClose } = useDialog();

  const { data, call } = Request({
    urlApi: `/lot/${lot.id}/lotProcedures`,
  });

  const [procedureSelected, setProcedureSelected] = useState([]);

  function deleteItemLot(id) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres quitar de la lista?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Lot/loteProcedure/${id}`}
        texBtn="Eliminar"
        handleSuccess={succesDelete}
      />
    );
  }

  function succesDelete() {
    call();
    reload();
  }

  function openLote() {
    openDialog(
      <SelectProcedureDialogEdit
        data={data}
        procedureSelected={procedureSelected}
        setProcedureSelected={setProcedureSelected}
      />
    );
  }

  function handleDeleteItem(id) {
    setProcedureSelected(
      procedureSelected.filter((procedure) => procedure.id !== id)
    );
  }

  async function addLots() {
    for (let i = 0; i < procedureSelected.length; i++) {
      let formData = {
        // obs: null,
        // status: "created",
        num: lot.num,
        lotId: lot.id,
        procedureId: procedureSelected[i].id,
      };

      await requestAuth("post", "/lot/loteProcedure", formData)
        .then((res) => {
          onClose();
          reload();
          toast.success("Se Añadio con éxito");
        })
        .catch(() => {
          toast.error("Se produjo un error al subir el archivo");
        });
    }
  }

  return (
    <>
      <div className="flex gap-5 justify-between text-center mt-2">
        <h2 className="text-2xl font-bold">Lista de Archivos</h2>
        <Button className="py-2" onClick={() => openLote()}>
          Añadir Archivos <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <div className="mx-10 mt-10 ">
        {data?.map((procedure) => (
          <div
            key={procedure.id}
            className="bg-slate-300 rounded-md px-5 py-2 mb-2 flex gap-2 justify-between"
          >
            <div>
              <p>{procedure.nroInterno}</p>
              <p>{procedure.companyRazonSocial || "---"}</p>
            </div>
            <button onClick={() => deleteItemLot(procedure.id)}>
              <i className="fas fa-trash text-[#ff2d55]"></i>
            </button>
          </div>
        ))}
      </div>
      <div className="mx-10 mb-10 ">
        {procedureSelected.map((procedure) => (
          <div
            key={procedure.id}
            className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between"
          >
            <div>
              <p>{procedure.internCode}</p>
              <p>{procedure.companyName || "---"}</p>
            </div>
            <button onClick={() => handleDeleteItem(procedure.id)}>
              <i className="fas fa-trash text-[#ff2d55]"></i>
            </button>
          </div>
        ))}
      </div>

      <div className="w-full flex gap-2 px-10">
        <Button
          className="block w-full bg-[#fff] text-[#FBCE00] border-2 border-[#FBCE00]"
          onClick={() => onClose()}
        >
          Cerrar
        </Button>
        <Button
          className="py-2 block w-full"
          disabled={procedureSelected.length === 0}
          onClick={() => addLots()}
        >
          Guardar Cambios
        </Button>
      </div>
    </>
  );
};

const FormLot = (props) => {
  const [procedureSelected, setProcedureSelected] = useState([]);
  const { openDialog } = useDialog();

  const { onClose, reload } = props;

  function handleDeleteItem(id) {
    setProcedureSelected(
      procedureSelected.filter((procedure) => procedure.id !== id)
    );
  }

  async function addLots() {
    const formData = {
      procedureIds: procedureSelected.map((p) => p.id),
      type: "Gestor",
    };

    try {
      await requestAuth("post", "/lot", formData);
      onClose();
      reload();
      toast.success("Se creó con éxito");
    } catch (error) {
      toast.error("Se produjo un error al guardar");
    }
  }

  function openLote() {
    openDialog(
      <SelectProcedureDialog
        procedureSelected={procedureSelected}
        setProcedureSelected={setProcedureSelected}
      />
    );
  }

  return (
    <>
      <div className="flex gap-5 justify-between text-center mt-2">
        <h2 className="text-2xl font-bold">Lista de Archivos</h2>
        <Button className="py-2" onClick={() => openLote()}>
          Seleccionar Archivos <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <div className="m-5 ">
        {validateArray(procedureSelected) ? (
          procedureSelected.map((procedure) => (
            <div
              key={procedure.id}
              className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between"
            >
              <div>
                <p>
                  <span className="font-semibold">Nro. de Interno: </span>
                  {procedure.internCode}
                </p>
                <p>
                  <span className="font-semibold">Cliente: </span>
                  {procedure.companyName}
                </p>
              </div>
              <button onClick={() => handleDeleteItem(procedure.id)}>
                <i className="fas fa-trash text-[#ff2d55]"></i>
              </button>
            </div>
          ))
        ) : (
          <p className="text-center font-semibold text-red-400">
            No se selecciono ninguna carpeta
          </p>
        )}
      </div>
      <Button
        className="py-2 w-full"
        disabled={procedureSelected.length === 0}
        onClick={() => addLots()}
      >
        Crear Lote
      </Button>
    </>
  );
};

const SelectProcedureDialog = ({ procedureSelected, setProcedureSelected }) => {
  const [proceduresList, setProceduresList] = useState(procedureSelected);

  function handleAdd(procedure, reload) {
    if (procedure.estadoDeLaCarpeta === "Observado") {
      toast.warning("El archivo tiene observaciones por subsanar");
      return;
    }
    if (
      procedure.estadoDeLaCarpeta === "Obs. por Archivo" ||
      procedure.estadoDeLaCarpeta === "Sub. para Archivo" ||
      procedure.estadoDeLaCarpeta === "Entregado"
    ) {
      toast.warning("La carpeta ya fue entregado");
      return;
    }
    if (proceduresList.find((p) => p.id === procedure.id)) {
      toast.warning("El archivo ya existe en el lote");
      return;
    }
    setProceduresList([...proceduresList, procedure]);
    setProcedureSelected([...proceduresList, procedure]);
  }

  return (
    <div className="w-[70vw]">
      <H3 className="mb-2">
        Carpetas seleccionadas:{" "}
        {proceduresList.map((item) => (
          <span key={item.id} className="bg-red-300 mx-1 px-2 rounded-lg">
            {item.internCode || item.nroInterno}
          </span>
        ))}
      </H3>
      <CallServiceTable
        filters={[
          {
            name: "procedureType",
            url: "/procedureType",
            default: "Todos los tramites",
            valueOption: "id",
            labelOption: "name",
          },
          {
            name: "companyId",
            url: "/company/getallcompanies",
            default: "Todas las compañías",
            valueOption: "id",
            labelOption: "razonSocial",
          },
          {
            name: "Year",
            default: "Todas las gestiones",
            options: [
              {
                label: "2025",
                name: "2025",
                initial: true,
              },
              {
                label: "2024",
                name: "2024",
              },
              {
                label: "2023",
                name: "2023",
              },
              {
                label: "2022",
                name: "2022",
              },
              {
                label: "2021",
                name: "2021",
              },
              {
                label: "2020",
                name: "2020",
              },
              {
                label: "2019",
                name: "2019",
              },
              {
                label: "2018",
                name: "2018",
              },
              {
                label: "2017",
                name: "2017",
              },
              {
                label: "2016",
                name: "2016",
              },
              {
                label: "2015",
                name: "2015",
              },
            ],
          },
          {
            name: "gestor",
            url: "/Dropdown/rol_gestor/options",
            label: "Todos los gestores",
            valueOption: "name",
            labelOption: "name",
          },
          {
            name: "EstadoDeLaCarpeta",
            default: "Todas las carpetas",
            label: "Todas las carpetas",
            options: [
              {
                label: "Observado",
                name: "Observado",
              },
              {
                label: "Subsanado",
                name: "Subsanado",
              },
              {
                label: "Obs. por Archivo",
                name: "Obs. por Archivo",
              },
              {
                label: "Sub. para Archivo",
                name: "Sub. para Archivo",
              },
              {
                label: "Entregado",
                name: "Entregado",
              },
            ],
          },
        ]}
        urlApi="/procedure/openprocedures"
        addFilters={[
          {
            name: "internCode",
            label: "Nro. interno",
            filter: true,
          },
          {
            name: "dimNumber",
            label: "Nro. DIM/DEX",
            filter: true,
          },
          {
            name: "númeroDePedido",
            label: "Nro. de Pedido",
            filter: true,
          },
        ]}
        header={[
          {
            label: "Estado",
            name: "estadoDeLaCarpeta",
            type: "custom",
            builder: (state, a, pro) => {
              if (state === null) {
                return (
                  <div className="relative inline-block">
                    <span className="block w-fit px-2 py-1 rounded-full text-white bg-blue-800">
                      Creado
                    </span>
                  </div>
                );
              }

              const background =
                state === "Observado"
                  ? "bg-orange-600 animate-pulse"
                  : state === "Subsanado"
                  ? "bg-emerald-500"
                  : state === "Obs. por Archivo"
                  ? "bg-red-600 animate-pulse"
                  : state === "Sub. para Archivo"
                  ? "bg-teal-600"
                  : state === "Entregado"
                  ? "bg-green-600"
                  : "";

              return (
                <div className="relative inline-block">
                  <span
                    className={`block w-fit px-2 py-1 whitespace-nowrap rounded-full text-white ${background}`}
                  >
                    {state}
                  </span>
                  {state === "Observado" || state === "Obs. por Archivo" ? (
                    <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 border border-red-600 bg-white text-red-600 text-xs font-bold rounded-full px-1 flex items-center justify-center ">
                      {dayjs().diff(dayjs(pro.obsCreationDate), "day") !== 0
                        ? dayjs().diff(dayjs(pro.obsCreationDate), "day") +
                          " días"
                        : dayjs().diff(dayjs(pro.obsCreationDate), "hour") !== 0
                        ? dayjs().diff(dayjs(pro.obsCreationDate), "hour") +
                          " horas"
                        : dayjs().diff(dayjs(pro.obsCreationDate), "minute") +
                          " minutos"}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
          {
            label: "Gestor Asignado",
            name: "asignGestor",
          },
          ...headerList,

          {
            name: "manage",
            type: "action",
            label: "Gestionar",
            sticky: true,
            actions: [
              {
                label: "Agregar",
                icon: "fas fa-plus",
                action: handleAdd,
                color: "text-[#646464]",
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const SelectProcedureDialogEdit = ({
  data,
  procedureSelected,
  setProcedureSelected,
}) => {
  const [proceduresList, setProceduresList] = useState(procedureSelected);

  function handleAdd(procedure, reload) {
    if (proceduresList.find((p) => p.id === procedure.id)) {
      toast.warning("El archivo ya existe en el lote");
      return;
    }
    if (data.find((p) => p.procedureId === procedure.id)) {
      toast.warning("El archivo ya existe en el lote");
      return;
    }
    setProceduresList([...proceduresList, procedure]);
    setProcedureSelected([...proceduresList, procedure]);
  }

  return (
    <div className="w-[70vw]">
      <H3 className="mb-2">
        Carpetas seleccionadas:{" "}
        {data.map((item) => (
          <span key={item.id} className="bg-red-300 mx-1 px-2 rounded-lg">
            {item.nroInterno}
          </span>
        ))}
        {proceduresList.map((item) => (
          <span key={item.id} className="bg-red-400 mx-1 px-2 rounded-lg">
            {item.internCode}
          </span>
        ))}
      </H3>
      <CallServiceTable
        filters={[
          {
            name: "procedureType",
            url: "/procedureType",
            default: "Todos los tramites",
            valueOption: "id",
            labelOption: "name",
          },
          {
            name: "companyId",
            url: "/company/getallcompanies",
            default: "Todas las compañías",
            valueOption: "id",
            labelOption: "razonSocial",
          },
          {
            name: "Year",
            default: "Todas las gestiones",
            options: [
              {
                label: "2025",
                name: "2025",
                initial: true,
              },
              {
                label: "2024",
                name: "2024",
              },
              {
                label: "2023",
                name: "2023",
              },
              {
                label: "2022",
                name: "2022",
              },
              {
                label: "2021",
                name: "2021",
              },
              {
                label: "2020",
                name: "2020",
              },
              {
                label: "2019",
                name: "2019",
              },
              {
                label: "2018",
                name: "2018",
              },
              {
                label: "2017",
                name: "2017",
              },
              {
                label: "2016",
                name: "2016",
              },
              {
                label: "2015",
                name: "2015",
              },
            ],
          },
        ]}
        urlApi="/procedure/openprocedures"
        addFilters={[
          {
            name: "internCode",
            label: "Nro. interno",
            filter: true,
          },
          {
            name: "dimNumber",
            label: "Nro. DIM/DEX",
            filter: true,
          },
          {
            name: "númeroDePedido",
            label: "Nro. de Pedido",
            filter: true,
          },
        ]}
        header={[
          ...headerList,

          {
            name: "manage",
            type: "action",
            label: "Gestionar",
            sticky: true,
            actions: [
              {
                label: "Agregar",
                icon: "fas fa-plus",
                action: handleAdd,
                color: "text-[#646464]",
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default ManageLots;
