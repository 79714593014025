import { H3 } from '../../components/Text'
import { Button } from '../../components/Buttons'
import { DialogConfirmation } from '../../components/Modal'
import { useModal } from '../../contexts/ModalContext'
import { useDialog } from '../../contexts/DialogContext'
import CallServiceTable from '../../components/Tables/CallServiceTable'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormDefault from '../../components/Forms/FormDefault'
import { airlineField } from '../../utils/fieldForm'
import { requestAuth } from '../../components/services/RequestService'

const ManageButtons = () => {
  const { openModal, onClose } = useModal()
  const { openDialog, dialogClose } = useDialog()

  function createButton(reload) {
    openModal(<FormButton reload={reload} onClose={onClose} />)
  }

  function editDropdown(button, reload) {
    openModal(<FormButton button={button} reload={reload} onClose={onClose} />)
  }

  function deleteDropdown(button, reload) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el botón?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Button/${button.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />,
    )
  }

  return (
    <>
      <CallServiceTable
        //   onClick={viewDropdown}
        component={({ reload }) => {
          return (
            <Button onClick={() => createButton(reload)}>
              Crear botón <i className="fa-solid fa-plus"></i>
            </Button>
          )
        }}
        urlApi="/Button"
        header={[
          {
            name: 'label',
            label: 'Etiqueta',
            filter: true,
          },
          {
            name: 'name',
            label: 'Nombre clave',
            filter: true,
          },
          {
            name: 'description',
            label: 'Descripción',
            filter: false,
          },
          {
            name: 'edit',
            type: 'action',
            label: 'Editar',
            actions: [
              {
                label: 'Editar',
                icon: 'fas fa-edit',
                action: editDropdown,
                color: 'text-[#1d4ed8]',
              },
            ],
          },
          {
            name: 'delete',
            type: 'action',
            label: 'Eliminar',
            actions: [
              {
                label: 'Eliminar',
                icon: 'fas fa-trash',
                action: deleteDropdown,
                color: 'text-red-500',
              },
            ],
          },
        ]}
      />
    </>
  )
}

const FormButton = (props) => {
  const { button, onClose, reload } = props
  return (
    <FormDefault
      tittle={button ? 'Editar botón' : 'Crear nuevo botón'}
      fields={[
        {
          label: 'Etiqueta del botón',
          name: 'label',
          placeholder: 'Introduzca la etiqueta del botón',
        },
        {
          label: 'Nombre clave del botón',
          name: 'name',
          placeholder: 'Introduzca su nombre del botón',
        },
        {
          label: 'Descripción',
          name: 'description',
          placeholder: 'Introduzca la descripción del botón',
        },
      ]}
      initialValues={{
        name: button?.name ?? '',
        label: button?.label ?? '',
        description: button?.description ?? '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Este campo es obligatorio'),
        label: Yup.string().required('Este campo es obligatorio'),
      })}
      onSubmit={async (values) => {
        await requestAuth(
          button ? 'put' : 'post',
          button ? `/Button/${button.id}` : '/Button',
          values,
        )
          .then(() => {
            reload()
            onClose()
            toast.success('Se creo con éxito')
          })
          .catch((response) => {
            toast.error(
              response.response.data
                ? response.response.data
                : 'Se produjo un error al crear',
            )
          })
      }}
      buttonName={button ? 'Editar' : 'Crear'}
    />
  )
}

export default ManageButtons
