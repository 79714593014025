import { useState, useEffect } from 'react'
import { Request } from '../../hooks/Request'
import { validateArray } from '../../utils/validate'
import { H2, H3 } from '../../components/Text'
import { Button } from '../../components/Buttons'
import Circle from '../../components/Animation/Circle'
import SheetCustomInput from '../../components/Sheet/SheetCustomInput'
import { useDialog } from '../../contexts/DialogContext'
import { requestAuth } from '../../components/services/RequestService'

const headerDAV = [
  { name: 'itemNumber', label: '68. Ítem', backgroundColor: '#FABF8F' },
  { name: '', label: 'FOB ME', backgroundColor: '#FABF8F' },
  { name: 'subpartida', label: 'Subpartida', backgroundColor: '#FABF8F' },
  { name: 'nroDeFactura', label: '70. Número de la Factura Comercial' },
  { name: 'fechaDeFactura', label: 'Fecha factura' },
  { name: 'nombreDeMercancia', label: '71. Nombre de la Mercancía' },
  {
    name: 'especificarMercancia',
    label: '71.1 Especifique',
    backgroundColor: '#FABF8F',
  },
  { name: 'estado', label: '76. Estado' },
  { name: 'especificarEstado', label: '77. Especifique' },
  { name: 'gestion', label: '78. Año de fabricación' },
  { name: 'cantidad', label: '79. Cantidad', backgroundColor: '#FABF8F' },
  { name: 'unidadComercial', label: '80. Unidad Comercial' },
  { name: 'especificarUnidad', label: '80.1 Especifique' },
  {
    name: 'precioNetoPorItemSegunFactura',
    label: '82. Precio neto por ítem según factura',
    backgroundColor: '#D9D9D9',
  },
  {
    name: 'deduccionesOadiccionesValorFob',
    label: '83. Deducciones o adiciones Valor FOB',
    backgroundColor: '#D9D9D9',
  },
  {
    name: 'valorFobPorItem',
    label: '84. Valor FOB por ítem',
    backgroundColor: '#D9D9D9',
  },
  {
    name: '',
    label: '85. Valor FOB Unitario por producto',
    backgroundColor: '#D9D9D9',
  },
  { name: 'pagosIndirectos', label: '86. Pagos indirectos' },
  { name: 'descuentosRetroactivos', label: '87. Descuentos retroactivos' },
  { name: 'otrosPagos', label: '88. Otros pagos', backgroundColor: '#D9D9D9' },
  {
    name: '',
    label: '89. Precio total pagado o por pagar por ítem',
    backgroundColor: '#D9D9D9',
  },
  {
    name: 'gastoTotalDeTransportePorItem',
    label: '90. Gasto total de transporte por ítem',
    backgroundColor: '#D9D9D9',
  },
  {
    name: 'costoDeSeguroPorItem',
    label: '91. Costo del seguro por ítem',
    backgroundColor: '#D9D9D9',
  },
  { name: '', label: '92. Código otra adición 1' },
  { name: '', label: '93. Valor ajuste adición 1', backgroundColor: '#D9D9D9' },
  { name: '', label: '94. Código otra adición 2' },
  { name: '', label: '95. Valor ajuste adición 2' },
  { name: '', label: '96. Cód. otra adición 3' },
  { name: '', label: '97. Valor ajuste adición 3' },
  { name: '', label: '98. Cód. otra adición 4' },
  { name: '', label: '99. Valor ajuste adición 4' },
  { name: '', label: '100. Cód. otra adición 5' },
  { name: '', label: '101. Valor ajuste adición 5' },
  { name: '', label: '102. Cód. otra adición 6' },
  { name: '', label: '103. Valor ajuste adición 6' },
  { name: '', label: '104. Cód. otra deducción 1' },
  {
    name: '',
    label: '105. Valor ajuste deducción 1',
    backgroundColor: '#D9D9D9',
  },
  { name: '', label: '106. Cód. otra deducción 2' },
  { name: '', label: '107. Valor ajuste deducción 2' },
  { name: '', label: '108. Cód. otra deducción 3' },
  { name: '', label: '109. Valor ajuste deducción 3' },
  { name: '', label: '110. Cód. otra deducción 4' },
  { name: '', label: '111. Valor ajuste deducción 4' },
  {
    name: '',
    label: '112. Valor en Aduana por ítem',
    backgroundColor: '#D9D9D9',
  },
  {
    name: '',
    label: '113. Valor en Aduana Unitario por producto',
    backgroundColor: '#D9D9D9',
  },
  {
    name: 'valoresEstimadosOProvisionales',
    label:
      '114. Valores estimados o provisionales: Casilla1, Casilla2 (máximo 5 casillas)',
  },
  {
    name: 'monedaDeNegociacionDeLasMerc1',
    label: '115. Moneda de negociación de las merc. 1',
  },
  {
    name: 'tipoDeCambio1',
    label: '116. Tipo de Cambio',
    backgroundColor: '#D9D9D9',
  },
  { name: 'fecha1', label: 'Fecha', backgroundColor: '#D9D9D9' },
  {
    name: 'numeroDeCasilla1',
    label: '117. Número Casilla',
    backgroundColor: '#D9D9D9',
  },
  {
    name: 'monedaDeNegociacionDeLasMerc2',
    label: '118. Moneda de negociación de las merc. 2',
  },
  { name: 'tipoDeCambio2', label: '119. Tipo de Cambio' },
  { name: 'fecha2', label: 'Fecha2' },
  { name: 'numeroDeCasilla2', label: '120. Número Casilla 2' },
  {
    name: 'monedaDeNegociacionDeLasMerc3',
    label: '121. Moneda de negociación de las merc. 3',
  },
  { name: 'tipoDeCambio3', label: '122. Tipo de Cambio' },
  { name: 'fecha3', label: 'Fecha3' },
  { name: 'numeroDeCasilla3', label: '123. Número Casilla' },
  { name: '', label: '124. Cod. País de Origen', backgroundColor: '#FABF8F' },
  { name: '', label: '125. Cod País de Embarque', backgroundColor: '#FABF8F' },
  {
    name: '',
    label: '126. Código País Adquisición',
    backgroundColor: '#FABF8F',
  },
  { name: '', label: '72. Marca Comercial' },
  { name: '', label: '72.1 Especifique' },
  { name: '', label: '73. Tipo' },
  { name: '', label: '73.1 Especifique' },
  { name: '', label: '74. Clase' },
  { name: '', label: '74.1 Especifique' },
  { name: '', label: '75. Modelo' },
  { name: '', label: '75.1 Especifique' },
  { name: '', label: '81.1 Cuantitativo 1' },
  { name: 'especificarUnidad', label: '81.1.1 Especifique' },
  { name: '', label: '81.2 Cuantitativo 2' },
  { name: 'especificarUnidad', label: '81.2.1 Especifique' },
  { name: '', label: '81.3 Forma de Presentación' },
  { name: '', label: '81.3.1 Especifique' },
  { name: '', label: '81.4 Naturaleza del Material' },
  { name: '', label: '81.4.1 Especifique' },
  { name: '', label: '81.5 Uso y Aplicación' },
  { name: '', label: '81.5.1 Especifique' },
  { name: '', label: '81.6 Otras Caracteristicas' },
  { name: '', label: 'PESO BRUTO', backgroundColor: '#FABF8F' },
  { name: '', label: 'PESO NETO', backgroundColor: '#FABF8F' },
  { name: '', label: 'CANT. Bultos', backgroundColor: '#FABF8F' },
  { name: '', label: 'CERTIFICADO DE ORIGEN', backgroundColor: '#FABF8F' },
  { name: '', label: 'CANT. S/ PARTIDA ARANCEL.', backgroundColor: '#FABF8F' },
  { name: '', label: 'Unidad de Media', backgroundColor: '#FABF8F' },
  { name: '', label: 'Criterio Origen', backgroundColor: '#FABF8F' },
  {
    name: '',
    label: 'Casilla Verificación Desp. Frontera',
    backgroundColor: '#FABF8F',
  },
  {
    name: '',
    label: 'Casilla Verificación Cert. Previa y/o A.P.',
    backgroundColor: '#FABF8F',
  },
  {
    name: '',
    label: '',
    backgroundColor: '#f8f9fa',
  },
  {
    name: '',
    label: '',
    backgroundColor: '#f8f9fa',
  },
  {
    name: '',
    label: '',
    backgroundColor: '#f8f9fa',
  },
]

const headDav = [
  [
    'Leyenda Celdas',
    'FOB Total',
    '',
    '',
    'Peso Bruto',
    ...new Array(79),
    'Datos Basico para la DUI',
  ],
  [
    'DESHABILITADOS',
    'Flete Total',
    '',
    '',
    'Peso Neto',
    ...new Array(79),
    'Patrón',
    '',
    '',
    'Estado Mercancia',
    '',
    'Contenedor',
    '0',
  ],
  [
    'Necesarios para Liquidación',
    'Seguro',
    '',
    '',
    'Bultos',
    ...new Array(79),
    'Recinto',
    '',
    '',
    'Doc. Embarque',
    '',
    'ID. Conten 1',
    '0',
  ],
  [
    'Formulas',
    'Otros Gastos',
    '',
    '',
    'Tipo Cambio',
    ...new Array(79),
    'Embalaje',
    '',
    '',
    'NIT Importador',
    '',
    'ID. Conten 2',
    '0',
  ],
  [
    'Necesario Ing. datos',
    'Otras Erogac.',
    '',
    '',
    '67. VALOR DE TRANS. TOTAL DECLARADO',
    ...new Array(7),
    'Diferencia:',
    '0',
    'INCOTERMS',
    '0',
    '',
    '',
    'Diferencia:',
    '0',
    'Diferencia:',
    '0',
    '0',
    'Diferencia:',
    '0',
    ...new Array(11),
    '0',
    ...new Array(43),
    '0',
    '0',
    '0',
    '',
    'Régimen',
    '',
    '',
    'Referencia Int.',
    '',
    'ID. Conten 3',
    '0',
  ],
  [
    '',
    'Valor CIF',
    ...new Array(7),
    'Sum Cant.:',
    '0',
    '',
    'Tot. Según Fact.',
    '0',
    'FCA',
    '0',
    '',
    '',
    'Tot. Otros Gastos:',
    '0',
    '',
    '0',
    '0',
    '',
    '0',
    ...new Array(11),
    '0',
    ...new Array(43),
    '0',
    '0',
    '0',
    '',
    'Mercancia Consolidada?',
    '',
    '',
    'Mercancia Peligrosa',
    '',
  ],
  [...headerDAV.map((item) => item.label)],
  // '1',
  // ...new Array(5).fill('').map((_, i) => ['' + (i + 1)]),
]

const RenderDAV = (props) => {
  const { procedureId, exportExcel, importExcel, typeId, rolId } = props
  const { data: dataDav, loading: loadingDav } = Request({
    urlApi: `/procedure/${procedureId}/dav`,
  })

  if (!loadingDav) {
    return <Circle />
  }
  return (
    <TableDAV
      procedureId={procedureId}
      dataDav={dataDav}
      exportExcel={exportExcel}
      importExcel={importExcel}
      typeId={typeId}
      rolId={rolId}
    />
  )
}
function handleItem(listItem) {
  let listAux = []
  if (validateArray(listItem)) {
    listItem.forEach((element) => {
      listAux.push(headerDAV.map((head) => element[head.name] || ''))
    })
  }
  return listAux
}

const TableDAV = (props) => {
  const {
    procedureId,
    exportExcel,
    importExcel,
    typeId,
    rolId,
    dataDav,
  } = props
  const [data, setData] = useState([...headDav, ...handleItem(dataDav)])
  const { openDialog, dialogClose } = useDialog()

  // useEffect(() => {
  //   function handleItem(listItem) {
  //     let listAux = []
  //     listItem.forEach((element) => {
  //       listAux.push(headerDAV.map((head) => element[head.name] || ''))
  //     })
  //     return listAux
  //   }

  //   if (loadingDav && validateArray(dataDav)) {
  //     console.log(dataDav)
  //     let init = data.slice(0, 7)
  //     // let final = data.slice(7)
  //     // console.log(final)
  //     // if (validateArray(init)) {
  //     // setData([...init, ...handleItem(dataDav)])
  //     // }
  //   }
  // }, [dataDav, loadingDav])

  // const headerStyle = (index) => {
  //   if (index < headerDAV.length) {
  //     return {
  //       backgroundColor: headerDAV[index].backgroundColor || '#CCFFCC',
  //       textAlign: 'left',
  //     }
  //   }
  // }

  useEffect(() => {
    async function saveItems(data) {
      const result = await Promise.all(
        data.map(async (element) => {
          let items = {}
          headerDAV.forEach((head, index) => {
            if (head.name) {
              items = {
                ...items,
                [head.name]: element[index] || '',
              }
            }
            // console.log(dataDav[index], items)
          })
          const fileContents = await requestAuth(
            'post',
            `/procedureSheet/xlsxdata/${procedureId}`,
            items,
          ).then((res) => {
            return res.data
          })
          return fileContents
        }),
      )
    }
    // let init = data.slice(0, 6)
    let final = data.slice(7)
    if (validateArray(final)) {
      saveItems(final)
    }
  }, [data])

  return (
    <div className="h-full pb-2">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center gap-2">
          <H2>Llenado de la DAV</H2>
          {/* <Button
            position="left"
            className="text-base px-1.5 py-0.5 rounded-xl"
            content="Ver información del tramite"
            onClick={handleInformation}
          >
            <i className="fas fa-question-circle"></i>
          </Button> */}
        </div>
        <div className="flex gap-2 items-center">
          <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Generar Excel"
            onClick={exportExcel}
          >
            <i className="fas fa-cloud-download-alt"></i>
          </Button>
          {/* <Button
            position="left"
            className="text-base px-1.5 py-1"
            content="Extraer datos de un excel"
            onClick={() => importExcel(callDav)}
          >
            <i className="fas fa-clone"></i>
          </Button> */}
          <Button
            typeBtn="link"
            position="left"
            href={`/rol/${rolId}/procedure-type/${typeId}/step/1/procedure/${procedureId}/generate/dam`}
            className="text-base p-1.5 text-[#287233] bg-[#2872334f]"
            content="Genere, cargue y exporte un documento DAM"
          >
            DAM
          </Button>
          <Button
            typeBtn="link"
            position="left"
            href={`/rol/${rolId}/procedure-type/${typeId}/step/1/procedure/${procedureId}/generate/dim`}
            className="text-base p-1.5 text-[#287233] bg-[#2872334f]"
            content="Genere, cargue y exporte un documento DIM"
          >
            DIM
          </Button>
        </div>
      </div>
      {/* <div style={{ height: '82px' }} className="">
        <SheetCustomInput
          columnData={[
            { name: 'fob', label: 'FOB Total' },
            { name: '', label: 'Flete Total' },
            { name: '', label: 'Seguro' },
            { name: '', label: 'Otros Gastos' },
            { name: '', label: 'Otras Erogac.' },
            { name: '', label: 'Valor CIF' },
            { name: '', label: 'Peso Bruto' },
            { name: '', label: 'Peso Neto' },
            { name: '', label: 'Bultos' },
            { name: '', label: 'Tipo Cambio' },
            { name: '', label: '67. VALOR DE TRANS. TOTAL DECLARADO' },
          ]}
          data={header}
          setData={setHeader}
        />
      </div> */}
      <div style={{ height: 'calc(100% - 50px)' }}>
        <SheetCustomInput
          // headerStyle={headerStyle}
          // columnData={headerDAV}
          data={data}
          setData={setData}
        />
      </div>
    </div>
  )
}

export default RenderDAV
